<template>
  <div class='desktop-services__content page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)">{{ getPageTitle($router.currentRoute.path) }}</h2>
    <broken-line className="desktop__line" />

    <div class='desktop-services__inner-content m-t_40 t-a_i services_text' v-if="!!menuPage?.content" v-html="menuPage.content"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Developing'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  data () {
    return {
      Images: Images
    }
  },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuPages']),
    menuPage () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', ''))?.[0] || null
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-services {
  &__content {
    flex: 1;
  }

  &__inner-content {
    min-height: 500px;
    margin-top: 2rem;
  }
}
.services_text{
  font-size: 1.3rem;
}
</style>

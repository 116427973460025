<template>
  <div id="app">
    <Header></Header>

    <div class="modal-wrapper" v-if='getModal'>
      <Modal />
    </div>
    <div class='mobile-content-wrapper'>
      <spinner-container :loading="showLoading">
        <router-view class="mobile-content-inner" />
      </spinner-container>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Modal from '@/components/Modal'
import { isSuccess, isLoading } from '@/shared/helpers/menu'
import { mapGetters, mapActions } from 'vuex'
import SpinnerContainer from './components/SpinnerContainer.vue'

export default {
  components: { Header, Modal, Footer, SpinnerContainer },
  computed: {
    ...mapGetters(['getModal', 'getTranslate', 'getMenuStatus', 'getPageId', 'getMenuPages', 'getMenuPagesStatus', 'getMenuChildrenStatus', 'getCurrentLoadingStatus']),
    showLoading () {
      return this.isLoading(this.getMenuStatus) || this.isLoading(this.getMenuPagesStatus) || this.isLoading(this.getMenuChildrenStatus) || this.isLoading(this.getCurrentLoadingStatus)
    }
  },
  methods: {
    ...mapActions(['loadTranslation', 'identifyUserAgent']),
    isSuccess,
    isLoading
  },
  async mounted () {
    await Promise.all([
      this.loadTranslation(),
      this.identifyUserAgent()
    ])
  }
}
</script>

<style lang="scss">
@import 'shared/style/variables.scss';
@import 'shared/style/colors.scss';
@import 'shared/style/media.scss';

#app {
  font-family:'Open sans', "HelveticaNeueCyr-Thin", "HelveticaNeueCyr-ThinItalic", "Din", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background: white;
  width: 90%;
  height: 100%;
  /*overflow: hidden;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .mobile-content-wrapper {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: flex-start;*/
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    margin-top: 141px;
    width: 100%;
    height: 100%;
  }
}

.mobile-content-inner {
  flex: 1;
}

#nav {
  a {
    font-weight: bold;
    color: black;

    &.router-link-exact-active {
      color: rgb(65,114,187);
    }
  }
}
/* заголовок для всех страниц с линией*/
.mobile-page-title {
  align-self: flex-start;
  padding: 0 0.8rem 0 2rem;
  width: 100%;
}

.title {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(60, 92, 139);
  font-size: 1.3rem;
  margin-top: 1rem;
}

.download-catalog-btn {
  background-color: transparent;
  border: 2px solid $primary;
  border-radius: 20px;
  color: $black;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Din", sans-serif;
  line-height: 100%;
  outline: none !important;
  padding: 0.5rem 1.8rem;
  transition: border-color 0.2s, color 0.2s;
  white-space: nowrap;

  &:hover {
    border: 2px solid $primary-2;
    color: rgba($black, 0.7);
  }

  @include mq-m {
//    height: 30px;
//    padding: 0.2rem 1.6rem;
  }
}

.special {
  font-weight: bold;
}

.capture {
  display: block;
  width: 90%;
  margin-top: 1rem;
}

.line {
  width: 80%;
  margin: 0.5rem;
  margin-top: 1rem;

  &-reverse {
    margin: 0.5rem;
    margin-top: 1rem;
    transform: rotate(180deg);
    width: 70%;

    &_remove-offset {
      &.line {
        display: block;
        margin: 0;
      }
    }
  }

  &_remove-offset {
    &.line,
    &.line-reverse {
      display: block;
      margin: 0;
    }
  }
}

.light-title {
  color: $primary;
  font: 400 1.6rem $openSans;
  text-align: left;
  margin: 0;

  &_justify {
    text-align: justify;
  }
}

.faded {
  &-bot,&-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.vertical-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.horizontal-wrapper {
    display: flex;
    flex-direction: row;
}
</style>

import PostApi from '@/api/post-api'

const state = () => ({
  post: {
    data: {},
    loading: false,
    updateLoading: false,
    deletePostLoading: false,
    subscribePostLoading: false
  },
  postList: {
    list: [],
    meta: null,
    loading: false
  }
})

export const PostActions = {
  actionGetPostList: 'actionGetPostList',
  actionGetPost: 'actionGetPost',
  actionClearPostData: 'actionClearPostData',
  actionCreatePost: 'actionCreatePost',
  actionDeletePost: 'actionDeletePost',
  actionPostSubscribe: 'actionPostSubscribe',
  actionUpdatePost: 'actionUpdatePost'
}

const getters = {
  getPostList (state) {
    return state.postList
  },
  getPost (state) {
    return state.post
  }
}

const actions = {
  [PostActions.actionGetPostList] ({ commit }, { topicId, query }) {
    commit('setPostListLoading', true)

    PostApi.listGet(topicId, query)
      .then((response) => {
        commit('setPostList', response)
        commit('setPostListLoading', false)
      })
      .catch(() => {
        commit('setPostListLoading', false)
      })
  },
  [PostActions.actionGetPost] ({ commit }, { postId }) {
    commit('setPostLoading', true)
    PostApi.getPost(postId)
      .then((response) => {
        commit('setPost', response.data)
        commit('setPostLoading', false)
      })
      .catch(() => {
        commit('setPostLoading', false)
      })
  },
  [PostActions.actionClearPostData] ({ commit }) {
    commit('setPost', {})
  },
  [PostActions.actionCreatePost] ({ commit }, { topicId, body }) {
    commit('setPostLoading', true)
    return PostApi.createPost(topicId, body)
      .then((response) => {
        commit('setPost', response)
        commit('setPostLoading', false)
        return response
      })
      .catch(() => {
        commit('setPostLoading', false)
      })
  },
  [PostActions.actionUpdatePost] ({ commit }, { postId, body }) {
    commit('setUpdatePostLoading', true)
    return PostApi.update(postId, body)
      .then((response) => {
        // если не удаётся, то нотификацию можно тут
        return response
      })
      .finally(() => {
        commit('setUpdatePostLoading', false)
      })
  },
  [PostActions.actionDeletePost] ({ commit }, postId) {
    commit('setDeletePostLoading', true)
    return PostApi.deletePost(postId)
      .then((response) => {
        if (typeof response === 'boolean' && response) {
          commit('setPost', {})
          return true
        }
        if ('message' in response) {
          // toast что ошибка c бэка
          throw Error(response)
        }
      })
      .finally(() => {
        commit('setDeletePostLoading', false)
      })
  },
  [PostActions.actionPostSubscribe] ({ commit }, id) {
    commit('setSubscribePostLoading', true)
    PostApi.subscribe(id)
      .then((response) => {
        if (!response.error) {
          commit('setSubscribe', true)
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        commit('setSubscribePostLoading', false)
      })
  }
}

const mutations = {
  setPostList (state, { data, meta }) {
    state.postList.list = data
    state.postList.meta = meta
  },
  setPost (state, data) {
    // TODO закидывать пост при апдейте и при создании
    state.post.data = data
  },
  setPostListLoading (state, loading) {
    state.postList.loading = loading
  },
  setPostLoading (state, loading) {
    state.post.loading = loading
  },
  setUpdatePostLoading (state, loading) {
    state.post.updateLoading = loading
  },
  setDeletePostLoading (state, loading) {
    state.post.deletePostLoading = loading
  },
  setSubscribePostLoading (state, loading) {
    state.post.subscribePostLoading = loading
  },
  setSubscribe (state, flag) {
    state.post.data.isSubscribed = flag
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="prosthesis-detail page-content">
    <h2 class="main-title main-title_left prosthesis-detail__title" v-if="page">{{ page.title }}</h2>
    <broken-line class-name="prosthesis-detail__line desktop__line" v-if="page" />
    <section class="prosthesis-detail__content" v-if="page">
      <div class="prosthesis-detail__top-content">
        <slider :initialSlide="0" :images="page.images" class-name="prosthesis-detail__slider" imageClass="prosthesis-detail__slide" />
        <div class="prosthesis-detail__info-block" v-html="page.content">
        </div>
      </div>
      <div class="prosthesis-detail__table custom-table" v-html="page.table"></div>

      <button class="detail-btn m-t_1" v-if="hasBack" @click="handleBack">{{ getTranslate.instruments?.back }}</button>

    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'
import Slider from '@/components/Slider'

export default {
  name: 'ProsthesisDetail',
  components: { BrokenLine, Slider },
  computed: {
    ...mapGetters(['getTranslate', 'getCurrentPage']),
    page () {
      return this.getCurrentPage(this.$router.currentRoute.params.id) || null
    },
    hasBack () {
      return !!window.history.state
    }
  },
  methods: {
    handleBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.prosthesis-detail {
  padding: 2rem 2rem 0;

  /*@include mq-s {
    padding: 1rem 2rem;
  }

  @include mq-m {
    padding-top: 2rem;
  }

  @include mq-l {
    padding-top: 3rem;
  }*/

  &__title {
    font-size: 1.2rem;
    line-height: 1.5rem;

    @include mq-s {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    @include mq-m {
      font-size: 1.8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__slider {
    flex: 1;

    @include mq-m {
      margin-left: 92px;
      margin-right: 92px;
      max-width: 400px;
    }
  }

  &__line {
    left: 0;
    width: 100%;

    @include mq-m {
      left: -64px;
      width: calc(100% + 64px);
    }
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    @include mq-m {
      flex-direction: row;
      margin-top: 2rem;
    }
  }

  &__info-block {
    margin-top: 1rem;
    max-width: 400px;
    text-align: left;
    flex: 1;
    overflow: hidden;

    @include mq-m {
      margin-top: 0;
      padding-left: 20px;
    }

    @include mq-l {
      padding-left: 4rem;
      max-width: 500px;
    }
  }

  &__table {
    overflow: auto;
    margin-top: 2rem;
  }
}
</style>

<style lang="scss">
.prosthesis-detail {
  flex: 1;

  &__slide {
    width: 100% !important;
  }
}
</style>

var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "broken-line",
    class: (_class = {
      'broken-line_rtl': _vm.rtl
    }, _class[_vm.className] = _vm.className, _class)
  }, [_c('svg', {
    class: 'broken-line__svg',
    attrs: {
      "width": "117",
      "height": "8",
      "viewBox": "0 0 117 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "svg-inline": '',
      "src": '@/shared/Images/Common/line.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M2 6H0M2 6c3.5 0 3.5-5 6-5s2.5 5 6 5M14 6c3.5 0 3.5-5 6-5s2.5 5 6 5M26 6c3.5 0 3.5-5 6-5s2.5 5 6 5M38 6c3.5 0 3.5-5 6-5s2.5 5 6 5M50 6c3.5 0 3.5-5 6-5s2.5 5 6 5M62 6c3.5 0 3.5-5 6-5s2.5 5 6 5M74 6c3.5 0 3.5-5 6-5s2.5 5 6 5M86 6c3.5 0 3.5-5 6-5s2.5 5 6 5M98 6c3.5 0 3.5-5 6-5s2.5 5 6 5M110 6c3 0 3-5 6.5-5",
      "stroke": "#0073BF"
    }
  })]), _c('div', {
    staticClass: "broken-line__bottom"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
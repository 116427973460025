var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('input', {
    staticClass: "input",
    class: _vm.className,
    attrs: {
      "disabled": _vm.disabled,
      "name": _vm.name,
      "placeholder": _vm.placeholder,
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event.target.value);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="create-post">
    <breadcrumbs
      wrapperClass="topic-list__breadcrumbs"
      :itemList="[
        {
          label: getTranslate.forum.forum,
          route: Routes.ForumTopicList
        },
        {
          label: getDiscussion.discussion.title,
          route: {name: RouterName.ForumTopicView, params: { topicId: getDiscussion.discussion.id }}
        },
        {
          label: getTranslate.forum.postCreation,
          route: ''
        }
      ]"
    />
    <div class="create-post__content">
      <form @submit.prevent="handleSubmit">
        <div class="create-post__field">
          <forum-input
            :placeholder="getTranslate.forum.postName"
            @input="value => this.title = value"
          />
        </div>
        <div class="create-post__field">
          <forum-input
            :placeholder="getTranslate.forum.postDescription"
            @input="value => this.description = value"
          />
        </div>
        <editor :content="content" @change="value => this.content = value" :show="true" />
        <button class="forum-button create-post__button" :disabled="isSubmitting" :class="{'forum-button_loading': isSubmitting}" type="submit">{{getTranslate.forum.createPost}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs'
import ForumInput from '@/components/ForumInput'
import Editor from '@/components/Editor'
import { Routes, RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      Routes,
      RouterName,
      isSubmitting: false,
      content: '',
      description: '',
      title: ''
    }
  },
  name: 'CreatePost',
  components: { Editor, Breadcrumbs, ForumInput },
  computed: {
    ...mapGetters(['getTranslate']),
    ...mapGetters('discussion', ['getDiscussion'])
  },
  methods: {
    ...mapActions(['actionCreatePost']),
    handleSubmit () {
      this.isSubmitting = true
      this.actionCreatePost({
        topicId: this.getDiscussion.discussion.id,
        body: {
          title: this.title,
          description: this.description,
          content: this.content
        }
      }).then((response) => {
        if (response.data.id) {
          this.$router.push({ name: this.RouterName.ForumPostView, params: { topicId: this.getDiscussion.discussion.id, postId: response.data.id }})
        }
      }).finally(() => {
        this.isSubmitting = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/media.scss';

.create-post {
  font-family: 'Open sans', sans-serif;
  height: 100%;
  padding: 0 16px;
  margin: 0 auto 40px;
  width: 100%;

  @include mq-m {
    width: calc(846px + 16px + 16px);
  }

  @include mq-l {
    width: calc(970px + 16px + 16px);
  }

  @include mq-xl {
    width: calc(1280px + 16px + 16px);
  }

  @include mq-xxl {
    width: calc(1440px + 16px + 16px);
  }

  &__content {
    margin-top: 32px;
  }

  &__field {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 20px;
  }
}
</style>

<template>
  <div class='researches'>
    <div class="mobile-page-title">
      <h1 class='main-title main-title_left p-l_1'>{{getPageTitle($router.currentRoute.path)}}</h1>
      <broken-line />
    </div>

    <div class="researches__block p-l_1 p-r_1" :key="id" v-for="({id, images, title, content, external_url}) in menuPages">
      <h4 class='light-title light-title_justify'>{{title}}</h4>
      <img v-if="!!images.length" :src='images[0]' class="research_img"/>
      <span class='simple simple_left f-d_c m-t_1' v-html="content"></span>

      <a v-if="external_url" :href="external_url" class="detail-btn m-t_1">
        {{getTranslate.researches.detail}}
      </a>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
@import '@/shared/style/colors.scss';

.researches {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;
    flex-direction: column;
    margin: 1rem auto 0;
  }
}
</style>

<template>
  <div class="news">
    <div class="mobile-page-title">
      <h2 class="main-title main-title_left p-l_standard">
        {{getPageTitle($router.currentRoute.path)}}
      </h2>
      <broken-line v-if="getPageTitle($router.currentRoute.path)" />
    </div>
    <div class="news__content p-l_standard p-r_standard">
      <div v-for="item in menuPages" :key="item.index" class="m-b_25">
        <img :src="item.images?.[0]" class="capture" :alt="item.Title" v-if="item.images?.[0]">
        <span class="main-title main-title_left overflow-ellipse-3">{{item.title}}</span>
        <span class="sub-title sub-title_helvetica-italic sub-title_left overflow-ellipse-3" :id="item.description">{{item.description}}</span>

        <a :href="item.external_url" target="_blank" class="detail-btn m-t_1" v-if="item.external_url" :id="item.external_url">
          {{getTranslate.news.detail}}
        </a>
        <router-link :to="{name: RouterName.CurrentNews, params: {id: item.id}}" class="detail-btn m-t_1" v-else :id="item.external_url">
          {{getTranslate.news.detail}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      RouterName
    }
  },
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', ''))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
.capture{
  max-height: 350px;
  object-fit: contain;
}
.m-b_25{
  margin-bottom:25px;
}
.news {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
  }
}
.arrow {
  width: 1.5rem;
  height: 1.5rem;
}
</style>

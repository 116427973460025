var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-products__content page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _c('div', {
    staticClass: "desktop-products__inner-content"
  }, [_c('div', {
    staticClass: "faded-top"
  }, [_c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Additive
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.hipBoneBlack,
      "topLayer": _vm.Images.hipBone
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Instruments
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.scissorsBlack,
      "topLayer": _vm.Images.scissors
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Prostheses
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.headBlack,
      "topLayer": _vm.Images.head
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Clamps
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.kneeBlack,
      "topLayer": _vm.Images.knee
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Coverings
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.mechanismBlack,
      "topLayer": _vm.Images.mechanism
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$getPost$data,
      _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-post"
  }, [_c('spinner-container', {
    attrs: {
      "loading": _vm.getPost.loading
    }
  }, [!_vm.getPost.loading && !!((_vm$getPost$data = _vm.getPost.data) !== null && _vm$getPost$data !== void 0 && _vm$getPost$data.title) ? _c('div', [_c('breadcrumbs', {
    attrs: {
      "wrapperClass": "topic-list__breadcrumbs",
      "itemList": [{
        label: _vm.getTranslate.forum.forum,
        route: _vm.Routes.ForumTopicList
      }, {
        label: _vm.getPost.data.discussion.title,
        route: {
          name: _vm.RouterName.ForumTopicView,
          params: {
            topicId: _vm.getPost.data.discussion.id
          }
        }
      }, {
        label: _vm.getPost.data.title,
        route: {
          name: _vm.RouterName.ForumPostView,
          params: {
            topicId: _vm.getPost.data.discussion.id,
            postId: _vm.getPost.data.id
          }
        }
      }]
    }
  }), _c('div', {
    staticClass: "edit-post__content"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "edit-post__field"
  }, [_c('forum-input', {
    attrs: {
      "value": _vm.title,
      "placeholder": _vm.getTranslate.forum.postName
    },
    on: {
      "input": function (value) {
        return _this.title = value;
      }
    }
  })], 1), _c('div', {
    staticClass: "edit-post__field"
  }, [_c('forum-input', {
    attrs: {
      "value": _vm.description,
      "placeholder": _vm.getTranslate.forum.postDescription
    },
    on: {
      "input": function (value) {
        return _this.description = value;
      }
    }
  })], 1), _c('editor', {
    attrs: {
      "content": _vm.content,
      "show": true
    },
    on: {
      "change": function (value) {
        return _this.content = value;
      }
    }
  }), _c('button', {
    staticClass: "forum-button edit-post__button",
    class: {
      'forum-button_loading': _vm.getPost.updateLoading
    },
    attrs: {
      "disabled": _vm.getPost.updateLoading,
      "type": "submit"
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.editPost))])], 1)])], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
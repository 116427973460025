var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "additive"
  }, [_vm.getPageTitle(_vm.$router.currentRoute.path) ? _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_left p-l_standard"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1) : _vm._e(), _c('div', {
    staticClass: "additive__content"
  }, [_c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.printer
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.additive.printer
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.printer))]), _c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.whatIsMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.whatIsMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.compModelSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.compModelSubtitle))]), _c('span', {
    staticClass: "simple m-t_1",
    attrs: {
      "id": _vm.getTranslate.additive.process
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.process))]), _c('img', {
    staticClass: "capture d-none",
    attrs: {
      "src": _vm.Images.welding
    }
  }), _c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.planMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.planMainTitle))]), _c('span', {
    staticClass: "simple m-t_1",
    attrs: {
      "id": _vm.getTranslate.additive.materials
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.materials))]), _c('div', {
    staticClass: "special simple_left",
    attrs: {
      "id": _vm.getTranslate.additive.forImplantsBold
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.forImplantsBold))]), _c('div', {
    staticClass: "simple",
    attrs: {
      "id": _vm.getTranslate.additive.titan
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.titan))]), _c('div', {
    staticClass: "special simple_left",
    attrs: {
      "id": _vm.getTranslate.additive.forModelBold
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.forModelBold))]), _c('span', {
    staticClass: "simple",
    attrs: {
      "id": _vm.getTranslate.additive.bio
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.bio))]), _c('div', {
    staticClass: "analize__top"
  }, [_c('div', {
    staticClass: "analize-item"
  }, [_c('img', {
    staticClass: "additive__miniature-image",
    attrs: {
      "alt": "",
      "src": _vm.Images.MRT
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.additive.ktSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.ktSubtitle))])]), _c('div', {
    staticClass: "analize-item"
  }, [_c('img', {
    staticClass: "additive__miniature-image ",
    attrs: {
      "alt": "",
      "src": _vm.Images.modeling
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.additive.modelSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.modelSubtitle))])])]), _c('div', {
    staticClass: "analize-item"
  }, [_c('img', {
    staticClass: "additive__miniature-image",
    attrs: {
      "alt": "",
      "src": _vm.Images.production
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.additive.productionSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.productionSubtitle))])]), _c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.clinickMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.clinickMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.additive.clinickSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.clinickSubtitle))]), _c('img', {
    staticClass: "capture ",
    attrs: {
      "src": _vm.Images.screws
    }
  }), _c('span', {
    staticClass: "simple m-t_1",
    attrs: {
      "id": _vm.getTranslate.additive.deleting
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.deleting))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.compModel
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_left sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.additive.endoSubTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.endoSubTitle))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.shouldersModel
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
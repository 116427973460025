<template>
  <div class="header">
    <div class="header-wrapper page-content">

      <!-- <div v-if="show" class="bottom_line bottom "><span class="m_center">{{PageTitle}}</span></div> -->
      <div v-if="show" class="bottom">
        <div class="search">
          <form class="search-form" @submit.prevent="onSearchRedirect">
            <!-- <button class="search-button prevent-button-styles" type="submit"> -->
              <!-- <img
                class='search-icon'
                :alt="getTranslate.common.placeholder"
                :src='Images.search'
              /> -->
            <!-- </button> -->
            <input
              v-if="getTranslate.common"
              class="search-input"
              :placeholder="getTranslate.common.placeholder"
              v-model="inputValue"
            />
          </form>
<!--          <div class='search-result' v-if="searchResult.length && !closeSearch"  >-->
<!--            <img class='close' :src="Images.cross" v-on:click="closeSearch = true"/>-->
<!--            <div class='result-wrapper' v-for="item in searchResult" :key="`${item.text} ${item.to}`" v-on:click="search(inputValue)">-->
<!--              <router-link :to="item.route">{{item.text}}</router-link>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <button class="header__profile-btn" @click="handleLogin">
          <profile-svg class="header__profile-svg" />
          <div class="header__profile-name">
            {{getSelf ? getSelf.name : getTranslate.forum.signIn}}
          </div>
        </button>
        <div class="languages">
          <img v-for='flag in langs' :key='flag' :src="Images.languages[flag]" v-on:click='changeLanguage(flag)' class='languages-flag' :class="{'languages-flag-active': getLang === flag}" />
        </div>
      </div>
      <div class="top">
        <router-link class="logo-link" to="/#home">
          <img alt="logo" :src='Images.logo' class="logo-img"/>
        </router-link>
        <nav id="nav" class="nav" v-bind:class="{ show: !show}">
          <div v-for="item in desktopMenuItems" :key="item.name" class='nav-link'>
            <router-link :to="item.url" :exact="true" class="nav-main-link link" exact-active-class="active-link">
              <span>{{item.name}}</span>
            </router-link>
            <div v-if="item.children" class="nav-link-sub">
              <div class="nav-link-sub-inner">
                <div v-for="link in item.children" :key="link.name" class="sub-link-wrapper">
                  <router-link :to="link.url" class="sub-link" exact-active-class="sub-link-active">
                    <span>{{link.name}}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import commonImages from '@/shared/Images/Common'
import ProfileSvg from '@/shared/Images/Common/profile.svg'
import languages from '@/shared/constants/i18n'
import { Routes, RouterName } from '@/shared/constants/routes'

export default {
  data: () => ({
    Routes,
    RouterName,
    show: true,
    Images: commonImages,
    langs: languages,
    inputValue: '',
    closeSearch: false
  }),
  components: { ProfileSvg },
  computed: {
    ...mapGetters('auth', ['getSelf']),
    ...mapGetters(['getTranslate', 'getSearch', 'desktopMenuItems', 'getLang'])
  },
  methods: {
    ...mapActions(['changeLang', 'search']),
    ...mapActions('auth', ['actionGetSelf', 'actionGetACL']),
    handleLogin () {
      if (this.getSelf) {
        this.$router.push({ name: this.RouterName.ForumCurrentParticipant, params: { userId: this.getSelf.id }})
        return
      }
      this.$router.push(this.Routes.ForumSignIn)
    },
    onSearchRedirect () {
      this.$router.push('/search?q=' + this.inputValue)
      this.inputValue = ''
    },
    async changeLanguage (language) {
      await this.changeLang(language)
      window.location.reload()
    }
  },
  beforeMount () {

  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';
@import '@/shared/style/variables.scss';

.m_center{
  margin: 0 auto;
}
.close {
  width: 1rem;
  height: 1rem;
}
.search-result {
  min-width: 10rem;
  position: absolute;
  top: 100%;
  left: 0.5rem;
  z-index: 5000;
  height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.result-wrapper {
  border: 1px solid black;
  width: 10rem;
  height: 3rem;
  overflow: hidden;
  background: white;
  &:hover {
    border: 2px solid $primary;
  }
}
.header {
  background: $white;
  display: flex;
  justify-content: center;
  height: 10rem;
  left: 0;
  position: fixed;
  width: 99%;
  z-index: 1000;

  @include mq-l {
    height: 12rem;
  }

  @include mq-xxl {
    height: 10rem;
  }

  &__profile-btn {
    align-items: center;
    background-color: transparent;
    border-width: 0;
    color: $primary;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    outline: none;
    padding-left: 20px;
    padding-right: 8px;
    max-width: 210px;
    min-width: 150px;
    overflow: hidden;
  }

  &__profile-name {
    padding-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__profile-svg {
    height: 24px;
    //margin-right: 16px;
    min-width: 24px;
    width: 24px;
  }
}

.header-wrapper {
  /*margin-left: -54px;*/
  /*max-width: 600px;*/
  width: 100%;

  @include mq-m {
    max-width: 846px;
  }

  @include mq-l {
    max-width: 970px;
  }

  @include mq-xl {
    max-width: 970px;
  }

  @include mq-xxl {
    max-width: 1110px;
  }
}

.logo-img {
  width: 100px;

  @include mq-m {
    width: 140px;
  }

  @include mq-l {
    width: 180px;
  }
}

.logo-link {
  @include mq-l {
    margin-left: 10px;
  }
}

.burger {
  position: relative;
  left: 10%;
  top: 1rem;
}
.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.bottom_line{
  margin: 0;
  padding: 0.2rem 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.253);
}
.bottom {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: flex-end;
}
.search {
  position: relative;
  width: 25%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;

  &-icon {
    height: 1.9rem;
    padding: 0 4px;
    width: 2rem;
    z-index: 2;
    object-fit: contain;
  }

  &-form {
    display: inline;
    position: relative;
  }

  &-button {
    height: 1.9rem;
    left: 5px;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 20;

  }

  &-input {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 1.9rem;
    width: 100%;
    min-width: 10rem;
    margin-right: 0.5rem;
    outline: none;
    padding-left: 0.5rem;
    position: relative;
    z-index: 1;
  }
}
.languages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;

  &-flag {
    border-radius: 50%;
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    &-active {
      border: 2px solid $gray;
    }
  }
}
.nav{
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba($black, 0.4);
  display: flex;
  margin-bottom: 8px;
  padding: 0;

  &-main-link {
    align-items: center;
    background: transparent;
    border-right: 2px solid $white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 34px;
    overflow: hidden;
    width: 100%;

    @include mq-l {
      min-height: 42px;
    }
  }

  &-link {
    cursor: pointer;
    min-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}
.nav-link {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  position: relative;
  white-space: nowrap;
  z-index: 3;

  &:hover {
    height: auto;
    border-right: none;

    .nav-link-sub {
      display: flex;
    }
  }

  &:first-child {
    .nav-main-link {
      border-radius: 10px 0 0 10px
    }
  }

  &:last-child{
    .nav-main-link {
      border: none;
      border-radius: 0 10px 10px 0;
    }
  }

  .link {
    background: linear-gradient(180deg, $white 10%, #d9d9d9);
    /*background: linear-gradient(180deg, #fff 50%, #f1f1f1 50%);*/
    text-transform: uppercase;
    font-family: $lucidaSansUnicode, sans-serif;
    font-size: 8px;
    padding: 0 5px;
    letter-spacing: 1px;
    margin: 0 0.5rem;

    @include mq-m {
      font-size: 11px;
    }

    @include mq-l {
      font-size: 11px;
    }

    @include mq-xl {
      font-size: 0.9rem;
    }

    @include mq-xxl {
      max-width: 1100px;
    }
  }

  .active-link {
    background: linear-gradient(180deg, $white 10%, #5a5a5a);
    border-bottom-color: #5a5a5a;
    /*background: linear-gradient(180deg, #fff 50%, #f1f1f1 50%);*/
    color: $primary;
  }
}

.nav-link-sub {
  background: $white;
  display: none;
  flex-direction: column;
  border: none;
  border-radius: 6px;
  background: $white;
  position: absolute;
  min-width: 102px;
  box-shadow: 0px 1px 4px 0px rgba($black, 0.6);
  top: calc(100% + 11px);
}

.nav-link-sub-inner {
  position: relative;
  height: 100%;
  width: 100%;

  // хак чтобы меню показывалось при абсолютном sub-menu и position: absolute;
  &:before {
    bottom: 0;
    content: '';
    display: block;
    height: calc(100% + 20px + 2px);
    left: 0;
    position: absolute;
    top: -20px;
    width: 100%;
    z-index: -1;
  }
}

.sub-link {
  display: block;
  font-family: $lucidaSansUnicode, sans-serif;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  width: 100%;
  white-space: nowrap;

  &-active {}

  &-wrapper {
    width: 100%;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-active {
    color: $primary;
  }
}

.show {
  bottom: 5rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-coverings page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path),
      "title": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "class-name": "desktop__line"
    }
  }), !!_vm.getTranslate.coverings ? _c('div', {
    staticClass: "desktop-coverings__content"
  }, [_c('div', {
    staticClass: "desktop-coverings__column desktop-coverings__column_left"
  }, [_c('div', {
    staticClass: "mx-w_80"
  }, [_c('span', {
    staticClass: "simple simple_remove-offset",
    attrs: {
      "id": _vm.getTranslate.coverings.creation
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.creation))]), _c('span', {
    staticClass: "simple m-t_0",
    attrs: {
      "id": _vm.getTranslate.coverings.creation2
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.creation2))])])]), _c('div', {
    staticClass: "desktop-coverings__column desktop-coverings__column_right"
  }, [_c('span', {
    staticClass: "main-title main-title_remove-offset main-title_left max-w_70",
    attrs: {
      "id": _vm.getTranslate.coverings.bioMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.bioMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left max-w_80",
    attrs: {
      "id": _vm.getTranslate.coverings.bioSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.bioSubtitle))])])]) : _vm._e(), _c('div', {
    staticClass: "desktop-coverings__row"
  }, [_c('div', {
    staticClass: "desktop-coverings__column desktop-coverings__column_left"
  }, [_c('div', {
    staticClass: "desktop-coverings__img-wrapper"
  }, [_c('img', {
    staticClass: "desktop-coverings__img",
    attrs: {
      "alt": "",
      "src": _vm.Images.nano
    }
  }), _c('span', {
    staticClass: "sub-title",
    attrs: {
      "id": _vm.getTranslate.coverings.nanoSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.nanoSub))])])]), _c('div', {
    staticClass: "desktop-coverings__column desktop-coverings__column_right"
  }, [_c('div', {
    staticClass: "desktop-coverings__img-wrapper"
  }, [_c('img', {
    staticClass: "desktop-coverings__img",
    attrs: {
      "alt": "",
      "src": _vm.Images.micro
    }
  }), _c('span', {
    staticClass: "sub-title",
    attrs: {
      "id": _vm.getTranslate.coverings.microSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.microSub))])])])]), _c('div', {
    staticClass: "desktop-coverings__row"
  }, [_c('div', {
    staticClass: "desktop-coverings__column desktop-coverings__column_left"
  }, [_c('div', {
    staticClass: "desktop-coverings__img-wrapper"
  }, [_c('img', {
    staticClass: "desktop-coverings__img",
    attrs: {
      "alt": "",
      "src": _vm.Images.chese
    }
  }), _c('span', {
    staticClass: "sub-title",
    attrs: {
      "id": _vm.getTranslate.coverings.cheseSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.cheseSub))])])]), _c('div', {
    staticClass: "desktop-coverings__column desktop-coverings__column_right"
  }, [_c('div', {
    staticClass: "desktop-coverings__img-wrapper"
  }, [_c('img', {
    staticClass: "desktop-coverings__img",
    attrs: {
      "alt": "",
      "src": _vm.Images.clouds
    }
  }), _c('span', {
    staticClass: "sub-title",
    attrs: {
      "id": _vm.getTranslate.coverings.cloudSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.cloudSub))])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class='desktop-developing__content page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)" >{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line className="desktop__line" />

    <div class='desktop-developing__inner-content'>
      <div class='desktop-developing__left-side'>
        <img alt="" :src='Images.bake' class='capture desktop-developing__left'/>
        <img alt="" :src='Images.room' class='capture desktop-developing__left-bottom-image'/>
      </div>
      <div class='desktop-developing__right-side'>
        <h2 class='desktop-developing__sub-title' :id="getTranslate.products.prodmainTitle">{{getTranslate.products.prodmainTitle}}</h2>
        <p class='simple m-b_1' :id="getTranslate.products.prod">{{getTranslate.products.prod}}</p>
        <span class='simple simple_left special simple_top-offset-md' :id="getTranslate.products.typesSpecial">{{getTranslate.products.typesSpecial}}</span>
        <div class='simple simple_left simple_top-offset-sm' v-for="item in getTranslate.products.items" :key='item' :id="item" >
          {{item}}
        </div>
        <div class='right-img-wrapper'>
          <img alt="" :src='Images.micro' class='capture desktop-developing__right'/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Developing'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  data () {
    return {
      Images: Images
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle'])
  }
}
</script>

<style lang="scss" scoped >
@import '@/shared/style/colors.scss';

.desktop-developing {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__inner-content {
    margin-top: 2rem;
    display: flex;
  }

  &__left {
    margin-top: 1rem;
    width: 35rem;
  }

  &__left-bottom-image {
    margin-top: 2rem;
  }

  &__right{
    width: 14.2rem;
    margin-top: 2.2rem;
  }

  &__left-side {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }

  &__sub-title {
    color: $primary;
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
    text-align: left;
  }
}

.simple {
  &_top-offset {
    &-sm {
      margin-top: 0.3rem;
    }

    &-md {
      margin-top: 2rem;
    }
  }
}

.right-img-wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

</style>

export const langKey = 'lang'

const languages = {
  english: 'en',
  russian: 'ru',
  dutch: 'de'
}

export const LangMapa = {
  [languages.english]: 'en-GB',
  [languages.russian]: 'ru-RU',
  [languages.dutch]: 'de-DE'
}

export default languages

<template>
  <div class='desktop-documentation page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)" >{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line class-name="desktop__line" />
    <div class="desktop-documentation__inner-content">
      <div :key="id" v-for="({id, title, images}) in menuPages">
        <h4 v-if="title && !!images.length" class='main-title main-title_left block-title' :id="title" >{{title}}</h4>
        <div v-if="!!images.length" class='desktop-documentation__img-block'>
          <div class="desktop-documentation__img-wrapper" v-for="(image , index) in images" :key="image">
            <img :src="image" class='desktop-documentation__img' v-on:click="handleDocumentClick(title, index)"  alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="desktop-documentation__full-document-wrapper" v-show="openedCarouselTitle">
      <div class="desktop-documentation__carousel-wrapper">
        <button @click="handleReset" class="desktop-documentation__carousel-close-btn">
          <svg height="512" viewBox="0 0 386.667 386.667" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/cross.svg'" v-bind:class="'desktop-documentation__carousel-close-icon'"><path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" fill="currentColor"/></svg>
        </button>
        <slider :initialSlide="startIndex" :images="getImages()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'
import Slider from '@/components/Slider'

export default {
  components: { Slider, BrokenLine },
  data () {
    return {
      openedCarouselTitle: null,
      startIndex: null
    }
  },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  },
  methods: {
    handleDocumentClick (title, index) {
      this.openedCarouselTitle = title
      this.startIndex = index
    },
    handleReset () {
      this.handleDocumentClick(null, 0)
    },
    handleKeyUp (evt) {
      if (evt.keyCode === 27) {
        this.handleReset()
      }
    },
    getImages () {
      const page = this.menuPages?.find(({ title }) => title === this.openedCarouselTitle)

      if (page?.images?.length) {
        return page.images
      }

      return []
    }
  },
  watch: {
    openedCarouselTitle: function (value) {
      if (value) {
        document.querySelector('#app').style.overflow = 'hidden'
        return
      }

      document.querySelector('#app').style.overflow = 'auto'
    }
  },
  async mounted () {
    document.addEventListener('keyup', this.handleKeyUp)
  },
  destroyed () {
    document.removeEventListener('keyup', this.handleKeyUp)
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.desktop-documentation {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__full-document-wrapper {
    align-items: center;
    position: fixed;
    background: rgba($black, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 1000;
  }

  &__carousel-wrapper {
    position: relative;
    width: 700px;
  }

  &__carousel-close-btn {
    align-items: center;
    background-color: transparent;
    border-width: 0;
    color: $primary;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 40px;
    outline: none;
    position: absolute;
    right: -50px;
    top: -50px;
    transition: color 0.2s;
    width: 40px;

    &:hover {
      background-color: rgba($black, 0.1);
    }
  }

  &__carousel-close-icon {
    height: 24px;
    width: 24px;
 }

  &__inner-content {
    padding-top: 3rem;
  }

  &__img-wrapper {
    margin-right: 16px;
    overflow: hidden;
    width: 27%;
  }

  &__img-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &__img {
    cursor: pointer;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.block-title {
  font-family: Open sans, sans-serif;
  margin: 2rem 0;
}
</style>

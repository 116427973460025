<template>
  <div class="spinner-modal" :class="{'spinner-modal_loading': loading}">
    <spinner-loader :loading="loading" className="spinner-modal__loader"></spinner-loader>
    <div class="spinner-modal__content"></div>
  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader'

export default {
  components: { SpinnerLoader },
  name: 'spinner-modal',
  props: ['loading']
}
</script>

<style lang="scss">
@import '@/shared/style/variables.scss';
@import '@/shared/style/colors.scss';

.spinner-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $maxZIndex;

  &_loading {
    .spinner-modal {

      &__loader {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
      }
      &__content {
        pointer-events: none;
        background-color: rgba($white, 0.95);
        width: 100%;
        height: 100%;
      }
    }
  }

  &__loader {
    z-index: 10;
  }
}
</style>

import CommentApi from '@/api/comment-api'

const initialState = () => ({
  list: [],
  meta: {}
})

const getters = {
  getCommentList (state) {
    return state.list
  }
}

const actions = {
  actionGetCommentList ({ commit }, { postId, perPage = 20, page = 1 } = {}) {
    CommentApi
      .listGet(postId, { per_page: perPage, page })
      .then((response) => {
        commit('setCommentList', response.data)
      })
      .catch(console.error)
  },
  actionCreateComment ({ commit }, { postId, body }) {
    return CommentApi.createComment(postId, body)
      .then((response) => {
        commit('setComment', response)
        return response
      })
      .catch((e) => console.error(e))
  },
  // update не будем реализовывать
  actionUpdateComment ({ commit }, { postId, body }) {
    CommentApi.update(postId, body)
      .then((response) => {
        // commit('setPost', response.data)
      })
      .catch((e) => console.error(e))
  },
  actionDeleteComment ({ commit }, id) {
    CommentApi.deleteComment(id)
      .then((response) => {
        if (response) {
          commit('deleteComment', id)
        }
      })
      .catch((e) => console.error(e))
  },
  actionClearComments ({ commit }) {
    commit('clearComments')
  }
}

const mutations = {
  setCommentList (state, data) {
    state.list = data
  },
  setComment (state, comment) {
    state.list.push(comment)
  },
  deleteComment (state, id) {
    state.list = state.list.filter(comment => comment.id !== id)
  },
  clearComments (state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}

<template>
  <div class='instruments'>
    <div class="mobile-page-title" v-if="getPageTitle($router.currentRoute.path)">
      <h2 class='main-title main-title_left p-l_standard' :id="getPageTitle($router.currentRoute.path)">{{getPageTitle($router.currentRoute.path)}}</h2>
      <broken-line />
    </div>
    <div class="instruments__content">
      <div class='instruments__preview' v-for="item in menuPages" :key='item.id'>
        <instrument :instrument="item" :link="{ name: RouterName.CurrentInstrument, params: { id: item.id } }" />
      </div>
    </div>
  </div>
</template>

<script>
import Instrument from '@/components/Instrument'
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      RouterName
    }
  },
  components: { BrokenLine, Instrument },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
.instruments {
  display: flex;
  flex-direction: column;

  &__content {
    padding: 1rem 0.8rem 0;
  }

  &__preview {
    margin-top: 0.5rem;
  }
}
</style>

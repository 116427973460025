<template>
  <div class='about'>
    <div class="mobile-wrapper">
      <div class="mobile-page-title" v-if="getPageTitle($router.currentRoute.path) && page">
        <h2 class='main-title main-title_light main-title_left p-l_standard' :id="getPageTitle($router.currentRoute.path)">{{getPageTitle($router.currentRoute.path)}}</h2>
        <broken-line />
      </div>

      <div class="about__content" v-if="!!page && getTranslate">
        <div class='light-title m-t_1'>{{page.title}}</div>
        <img v-if="!!page.images?.[0]" :src='page.images?.[0]' class='capture'/>
        <div class='t-a_i m-t_1' v-html="page.content"></div>

        <div class='resident'>
          <img :src='Images.eco' class='ros' alt="РОСОЭЗ"/>
          <div class='text'>
            <span class='special' :id="getTranslate.about.tvt" >{{getTranslate.about.tvt}} {{getTranslate.about.tvtBold}}</span>
          </div>
        </div>
      </div>
      <div class="mobile-page-title">
          <h3 class='main-title main-title_left' :id="getTranslate.about.contacts" >{{getTranslate.about.contacts}}</h3>
          <broken-line />
      </div>
      <div class="about__content">
        <div class='light-title m-t_1 m-b_1' :id="getTranslate.about.salesDepartment" >{{getTranslate.about.salesDepartment}}</div>
        <a class="simple" :href="`tel:${getTranslate.about.salesPhone}`">{{getTranslate.about.salesPhone}}</a>
        <a class='simple' :href="`mailto:${getTranslate.about.salesMail}`">{{getTranslate.about.salesMail}}</a>
<!--        <div class='light-title m-t_1 m-b_1' :id="getTranslate.about.marketingDepartment" >{{getTranslate.about.marketingDepartment}}</div> -->
<!--        <span class='simple' :id="getTranslate.about.marketiongPhone" >{{getTranslate.about.marketiongPhone}}</span> -->
<!--        <span class='simple' :id="getTranslate.about.marketingMail" >{{getTranslate.about.marketingMail}}</span> -->
        <div class='light-title m-t_1 m-b_1' :id="getTranslate.about.productionDepartment" >{{getTranslate.about.productionDepartment}}</div>
        <a class="simple" :href="`tel:${getTranslate.about.productionPhone}`">{{getTranslate.about.productionPhone}}</a>
        <a class='simple' :href="`mailto:${getTranslate.about.productionMail}`">{{getTranslate.about.productionMail}}</a>
      </div>
    </div>
    </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'
import { Images } from '@/shared/Images/About'

export default {
  data () {
    return {
      Images
    }
  },
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    page () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', ''))?.[0] || null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.capture {
  padding: 0;
}

.about {
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;

  &__content {
    padding: 0 0.8rem;
  }
}
.resident {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin: 1rem auto;
}
.ros {
  width: 40%;
  height: auto;
}
.text {
  display: flex;
  flex-direction: row;
}
</style>

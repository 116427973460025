var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fake-content"
  }, [_c('discussion-form', {
    attrs: {
      "breadcrumbs": _vm.breadcrumbs,
      "title": _vm.title,
      "loading": _vm.loading,
      "description": _vm.description,
      "button-text": _vm.getTranslate.forum.editSection,
      "permissions": _vm.permissions.updateDiscussions
    },
    on: {
      "change-title": _vm.changeTitle,
      "change-description": _vm.changeDescription,
      "submit": _vm.handleSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="footer page-content" v-if="getTranslate.common">
    <address class="top-wrapper">
      <a target="_blank" :href="`mailto:${getTranslate.common.info}`" class="main-title footer__info-text">{{getTranslate.common.info}}</a>
      <span class="main-title footer__info-text right">{{getTranslate.common.adress}}</span>
    </address>
    <broken-line rtl className="desktop__line" />
    <address class="bot-wrapper">
      <div class="footer__info-text">
        <span class="main-title footer__info-text">{{getTranslate.common.faxLabel}}{{': '}}</span>
        <a :href="`tel:${getTranslate.common.fax}`" class="main-title footer__info-text">{{getTranslate.common.fax}}</a>
      </div>
    </address>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import commonImages from '@/shared/Images/Common'
import BrokenLine from '@/components/BrokenLine'
export default {
  components: { BrokenLine },
  data: () => ({
    Images: commonImages
  }),
  computed: mapGetters(['getTranslate']),
  methods: mapActions(['changeLang']),
  async mounted () {}
}
</script>

<style scoped lang="scss">
.footer {
  margin-bottom: 40px;
  margin-top: 60px;
  position: relative;
  width: 100%;
  height: auto;

  &__info-text {
    display: inline;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.3rem;
    white-space: nowrap;
  }
}
.top-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
}
.bot-wrapper {
  position: relative;
  left: 25%;
  font-style: normal;
}
.right {
  position: relative;
  right: 7%;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import DesktopApp from './desktopApp.vue'
import './registerServiceWorker'
import router from './router'
import desktopRouter from './desktop/router'
import store from './store'
import { isMobileDevice } from '@/shared/helpers'
import '@/shared/style/main.scss'

Vue.config.productionTip = false

const application = () => {
  if (isMobileDevice(navigator.userAgent)) {
    return App
  }
  return DesktopApp
}
const getRouter = () => {
  if (isMobileDevice(navigator.userAgent)) {
    return router
  }
  return desktopRouter
}

new Vue({
  router: getRouter(),
  store,
  render: h => h(application())
}).$mount('#app')

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('spinner-container', {
    attrs: {
      "loading": _vm.getVisibleUserLoading
    }
  }, [_vm.getTranslate.forum ? _c('div', {
    staticClass: "profile"
  }, [_c('Breadcrumbs', {
    attrs: {
      "itemList": [{
        label: _vm.getTranslate.forum.forum,
        route: _vm.Routes.ForumTopicList
      }, {
        label: _vm.getTranslate.forum.anketa,
        route: ''
      }]
    }
  }), _c('form', {
    staticClass: "profile__content"
  }, [_c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "surname",
      "placeholder": _vm.getTranslate.forum.lastName,
      "value": _vm.user.surname || ''
    },
    on: {
      "input": function (value) {
        return _vm.user.surname = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "name",
      "placeholder": _vm.getTranslate.forum.firstName,
      "value": _vm.user.name || ''
    },
    on: {
      "input": function (value) {
        return _vm.user.name = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "middlename",
      "placeholder": _vm.getTranslate.forum.middleName,
      "value": _vm.user.middlename
    },
    on: {
      "input": function (value) {
        return _vm.user.middlename = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": true,
      "className": "profile__input",
      "name": "email",
      "placeholder": _vm.getTranslate.forum.emailPlaceholder,
      "value": _vm.user.email
    },
    on: {
      "input": function (value) {
        return _vm.user.email = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "phone",
      "placeholder": _vm.getTranslate.forum.phonePlaceholder,
      "value": _vm.user.phone
    },
    on: {
      "input": function (value) {
        return _vm.user.phone = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "country",
      "placeholder": _vm.getTranslate.forum.countryPlaceholder,
      "value": _vm.user.country
    },
    on: {
      "input": function (value) {
        return _vm.user.country = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "city",
      "placeholder": _vm.getTranslate.forum.cityPlaceholder,
      "value": _vm.user.city
    },
    on: {
      "input": function (value) {
        return _vm.user.city = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "interests",
      "placeholder": _vm.getTranslate.forum.interestPlaceholder,
      "value": _vm.user.interests
    },
    on: {
      "input": function (value) {
        return _vm.user.interests = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "company",
      "placeholder": _vm.getTranslate.forum.appointmentPlaceholder,
      "value": _vm.user.company
    },
    on: {
      "input": function (value) {
        return _vm.user.company = value;
      }
    }
  }), _c('forum-input', {
    attrs: {
      "disabled": _vm.disabledForm,
      "className": "profile__input",
      "name": "job",
      "placeholder": _vm.getTranslate.forum.jobPlaceholder,
      "value": _vm.user.job
    },
    on: {
      "input": function (value) {
        return _vm.user.job = value;
      }
    }
  }), _c('button', {
    staticClass: "forum-button profile__logout-btn m-b_1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handleLogout
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.exitFromAccount))]), _vm.permissions.deleteUsers ? _c('button', {
    staticClass: "profile__delete-btn warning-button m-b_1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleModal
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.deleteUser))]) : _vm._e(), _vm.canSaveForm ? _c('button', {
    staticClass: "forum-button profile__submit-btn m-b_1",
    class: {
      'forum-button_loading': _vm.getUpdateLoading
    },
    attrs: {
      "disabled": _vm.getUpdateLoading,
      "type": "submit"
    },
    on: {
      "click": _vm.handleSaveInfo
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.actionSave))]) : _vm._e()], 1), _c('modal', [_c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup__title"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.warningDeleteText) + " ")]), _c('div', {
    staticClass: "popup__row"
  }, [_c('button', {
    staticClass: "warning-button",
    class: {
      'warning-button_loading': _vm.getDeleteLoading
    },
    on: {
      "click": _vm.onDeleteUser
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.deleteUser))]), _c('button', {
    staticClass: "white-button popup__cancel",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.toggleModal
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.cancel))])])])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Additive from '../views/Additive.vue'
import Clamps from '../views/Clamps.vue'
import Coverings from '../views/Coverings.vue'
import Developing from '../views/Developing.vue'
import Documentation from '../views/Documentation.vue'
import Instruments from '../views/Instruments.vue'
import News from '../views/News.vue'
import Participants from '../../views/Participants.vue'
import Products from '../views/Products.vue'
import Vacancies from '../views/Vacancies.vue'
import Prostheses from '../views/Prostheses.vue'
import Researches from '../views/Researches.vue'
import Services from '../views/Services.vue'
import Smi from '../views/Smi.vue'
import CurrentNews from '../../views/CurrentNews.vue'
import Profile from '../views/Profile.vue'
import TopicList from '../views/TopicList'
import ViewTopic from '../views/ViewTopic'
import CreatePost from '../views/CreatePost'
import CreateTopic from '../views/CreateTopic'
import ViewPost from '../views/ViewPost'
import ProsthesisList from '../views/ProsthesisList.vue'
import ForumForgotPassword from '../views/Auth/ForumForgotPassword'
import ForumSignUp from '../views/Auth/ForumSignUp'
import ForumSignIn from '../views/Auth/ForumSignIn'
import ForumNewPassword from '../views/Auth/ForumNewPassword'
import Forum from '../views/Forum'
import ProsthesisDetail from '../views/ProsthesisDetail'
import EditTopic from '../..//views/EditTopic.vue'
import EditPost from '../views/EditPost.vue'
import ErrorComponent from '../..//views/ErrorComponent.vue'
import SearchPage from '../../views/SearchPage.vue'
import store from '../../store'
import { MenuAction } from '../../store/menu'
import { PagesActions } from '../../store/pages'
import { RouterName, Routes } from '../../shared/constants/routes'
import { PostActions } from '@/store/post'

Vue.use(VueRouter)

// ВАЖНО! Чтобы name совпадали с мобильным роутером
const routes = [
  {
    path: Routes.Home,
    name: RouterName.Home,
    component: Home
  },
  {
    path: Routes.About,
    name: RouterName.About,
    component: About
  },
  {
    path: Routes.Additive,
    name: RouterName.Additive,
    component: Additive
  },
  {
    path: Routes.Clamps,
    name: RouterName.Clamps,
    component: Clamps
  },
  {
    path: Routes.Coverings,
    name: RouterName.Coverings,
    component: Coverings
  },
  {
    path: Routes.Developing,
    name: RouterName.Developing,
    component: Developing
  },
  {
    path: Routes.Documentation,
    name: RouterName.Documentation,
    component: Documentation
  },
  {
    path: Routes.Vacancies,
    name: RouterName.Vacancies,
    component: Vacancies
  },
  {
    path: Routes.Instruments,
    name: RouterName.Instruments,
    component: Instruments
  },
  {
    path: Routes.CurrentInstrument,
    name: RouterName.CurrentInstrument,
    component: ProsthesisDetail
  },
  {
    path: Routes.News,
    name: RouterName.News,
    component: News
  },
  {
    path: Routes.CurrentNews,
    name: RouterName.CurrentNews,
    component: CurrentNews
  },
  {
    path: Routes.Products,
    name: RouterName.Products,
    component: Products
  },
  {
    path: Routes.Prostheses,
    name: RouterName.Prostheses,
    component: Prostheses
  },
  {
    path: Routes.ProsthesisList,
    name: RouterName.ProsthesisList,
    component: ProsthesisList
  },
  {
    path: Routes.ProsthesisDetail,
    name: RouterName.ProsthesisDetail,
    component: ProsthesisDetail
  },
  {
    path: Routes.Researches,
    name: RouterName.Researches,
    component: Researches
  },
  {
    path: Routes.Services,
    name: RouterName.Services,
    component: Services
  },
  {
    path: Routes.Smi,
    name: RouterName.Smi,
    component: Smi
  },
  {
    path: Routes.Search,
    name: RouterName.Search,
    component: SearchPage
  },
  {
    path: Routes.ForumHome,
    name: RouterName.ForumHome,
    component: Forum,
    children: [
      {
        path: Routes.ForumSignIn,
        name: RouterName.ForumSignIn,
        component: ForumSignIn
      },
      {
        path: Routes.ForumSignUp,
        name: RouterName.ForumSignUp,
        component: ForumSignUp
      },
      {
        path: Routes.ForumForgotPassword,
        name: RouterName.ForumForgotPassword,
        component: ForumForgotPassword
      },
      {
        path: Routes.ForumNewPassword,
        name: RouterName.ForumNewPassword,
        component: ForumNewPassword
      },
      {
        path: '',
        redirect: Routes.ForumTopicList
      }
    ]
  },
  {
    path: Routes.ForumProfile,
    name: RouterName.ForumProfile,
    component: Profile
  },
  {
    path: Routes.ForumTopicList,
    name: RouterName.ForumTopicList,
    component: TopicList
  },
  {
    path: Routes.ForumCreateTopic,
    name: RouterName.ForumCreateTopic,
    component: CreateTopic
  },
  {
    path: Routes.ForumTopicView,
    name: RouterName.ForumTopicView,
    component: ViewTopic
  },
  {
    path: Routes.ForumTopicEdit,
    name: RouterName.ForumTopicEdit,
    component: EditTopic
  },
  {
    path: Routes.ForumCreatePost,
    name: RouterName.ForumCreatePost,
    component: CreatePost
  },
  {
    path: Routes.ForumPostView,
    name: RouterName.ForumPostView,
    component: ViewPost
  },
  {
    path: Routes.ForumPostEdit,
    name: RouterName.ForumPostEdit,
    component: EditPost
  },
  {
    path: Routes.ForumParticipants,
    name: RouterName.ForumParticipants,
    component: Participants
  },
  {
    path: Routes.ForumCurrentParticipant,
    name: RouterName.ForumCurrentParticipant,
    component: Profile
  },
  {
    path: Routes.NotFound,
    name: RouterName.NotFound,
    component: ErrorComponent
  }
]

const desktopRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const routerDispatchMapa = {
  [RouterName.Home]: null,
  [RouterName.About]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.News]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.CurrentNews]: ({ id, slug }) => {
    store.dispatch(PagesActions.actionGetCurrentPage, { id })
  },
  [RouterName.Smi]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.Researches]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.Developing]: null,
  [RouterName.Documentation]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.Services]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.Products]: null,
  [RouterName.Additive]: null,
  [RouterName.Instruments]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.CurrentInstrument]: ({ id, slug }) => {
    store.dispatch(PagesActions.actionGetCurrentPage, { id })
  },
  [RouterName.Prostheses]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetChildrenByMenuId, slug)
  },
  [RouterName.ProsthesisList]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, id)
  },
  [RouterName.ProsthesisDetail]: ({ id, slug }) => {
    store.dispatch(PagesActions.actionGetCurrentPage, { id })
  },
  [RouterName.Clamps]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetChildrenByMenuId, slug).then((data) => {
      if (Array.isArray(data)) {
        data.forEach((menuItem) => {
          store.dispatch(MenuAction.actionGetPagesByMenuId, menuItem.id)
        })
      }
    })
  },
  [RouterName.Coverings]: null,
  [RouterName.Vacancies]: ({ id, slug }) => {
    store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.Search]: null,

  [RouterName.ForumProfile]: ({ id, slug }) => {
    // store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },

  [RouterName.ForumTopicList]: ({ id, slug }) => {
    // store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },

  [RouterName.ForumCreateTopic]: ({ id, slug }) => {
    // store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.ForumTopicView]: ({ topicId }) => {
    store.dispatch('discussion/actionGetDiscussion', { topicId })
    store.dispatch(PostActions.actionGetPostList, { topicId })
  },
  [RouterName.ForumTopicEdit]: ({ id, slug }) => {
    // store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.ForumCreatePost]: ({ topicId }) => {
    store.dispatch('discussion/actionGetDiscussion', { topicId })
  },
  [RouterName.ForumPostView]: ({ postId }) => {
    // store.dispatch(PostActions.actionGetPost, { postId: +postId })
  },
  [RouterName.ForumPostEdit]: ({ postId }) => {
    store.dispatch(PostActions.actionGetPost, { postId: +postId })
  },
  [RouterName.ForumParticipants]: ({ id, slug }) => {
    // store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  },
  [RouterName.ForumCurrentParticipant]: ({ id, slug }) => {
    // store.dispatch(MenuAction.actionGetPagesByMenuId, slug)
  }
}

desktopRouter.onReady(() => {
  store.dispatch(MenuAction.actionGetMenuItems)
  store.dispatch('auth/actionGetSelf')
  store.dispatch('auth/actionGetACL')
})

desktopRouter.beforeEach(({ name, path, params, ...rest }, _, next) => {
  // без первого слайса. Получить
  const pathSlug = path.replace(/[/]/ig, '')
  const action = routerDispatchMapa[name]

  action?.({ ...(params || {}), slug: pathSlug })

  return next()
})

export default desktopRouter

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "forum-modal"
  }, [_c('div', {
    staticClass: "forum-modal__container"
  }, [_c('div', {
    staticClass: "forum-modal__close-link",
    attrs: {
      "role": "button"
    },
    on: {
      "click": _vm.handleCloseModal
    }
  }, [_c('svg', {
    class: 'forum-modal__close-icon',
    attrs: {
      "height": "512",
      "viewBox": "0 0 386.667 386.667",
      "width": "512",
      "xmlns": "http://www.w3.org/2000/svg",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/cross.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z",
      "fill": "currentColor"
    }
  })])]), _c('router-view')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
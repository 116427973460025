import bake from './bake.jpg'
import room from './room.jpg'
import micro from './micro.jpg'
import strip from '../stripLeft.jpg'

const Images = {
  bake: bake,
  room: room,
  micro: micro,
  strip: strip
}
export default Images

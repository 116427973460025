<template>
  <footer class='footer'>
    <address class="footer__content">
      <a :href="'mailto:'+getTranslate.common?.info" class='main-title'>{{getTranslate.common?.info}}</a>
      <span class='main-title'>{{getTranslate.common?.adress}}</span>
      <broken-line rtl />
      <a :href="'tel:' + getTranslate.common?.fax" class='footer__fax main-title'>{{getTranslate.common?.fax}}</a>
    </address>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  computed: mapGetters(['getTranslate'])
}
</script>

<style scoped lang="scss">
.footer {
  padding-bottom: 2rem;
  position: relative;
  width: 100%;

  &__content {
    padding: 0 2.8rem 0 0.8rem;
    font-style: normal;
  }

  &__fax {
    margin-top: 0;
  }

  .main-title {
    align-items: start;
    font-style: normal;
    font-size: 1.5rem;
    padding: 4px 0;
  }
}
</style>

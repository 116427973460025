<template>
  <div class='fixators'>
    <div class="fixators__content page-content fixators__content_center">
<!--      <img alt="" class='capture' :src="Images.mainBack"/> -->
<iframe class="capture" width="560" height="515" src="https://www.youtube.com/embed/mY20Oz80zho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <h3 class="main-title">{{getTranslate.clamps.mainTitle}}</h3>
      <div class="sub-title">{{getTranslate.clamps.mainSubTitle}}</div>
      <img alt="" class="capture fixators__skelet-img" :src="Images.skelet"/>
      <div class="trio">
        <div class="trio__img-wrapper">
          <img alt="" class='capture' :src="Images.firstRent"/>
          <span class="sub-title">{{getTranslate.clamps.imageSubTitle1}}</span>
        </div>
        <div class="trio__img-wrapper">
          <img alt="" class='capture' :src="Images.secondRent"/>
          <span class="sub-title">{{getTranslate.clamps.imageSubTitle2}}</span>
        </div>
        <div class="trio__img-wrapper">
          <img alt="" class='capture' :src="Images.thirdRent"/>
          <span class="sub-title">{{getTranslate.clamps.imageSubTitle3}}</span>
        </div>
      </div>
      <img class='capture middle reverse' :src="Images.fixGold"/>
    </div>
    <div class='blue-block'>
      <div class="blue-block__column">
        <div class="white-text" v-html="getTranslate.clamps.mini"></div>
        <br>
        <div class="white-text" v-html="getTranslate.clamps.constructive"></div>
      </div>
      <div class="blue-block__column">
        <div class="white-text" v-html="getTranslate.clamps.bio"></div>
        <br>
        <div class="white-text" v-html="getTranslate.clamps.osteo"></div>
      </div>
    </div>

    <div class="fixators__content page-content" v-for="menu in menuChildren" :key="menu.id">
      <div class="fixators__row">
        <h4 class='main-title main-title_left main-title_remove-offset' :id="menu.name" >{{menu.name}}</h4>
        <!-- Пока открываю в отдельной вкладке -->
        <button v-if="!!menu.catalog_link" @click="buttonClick(menu.catalog_link)" class="download-catalog-btn">{{getTranslate.clamps.downloadCatalog}}</button>
      </div>
      <broken-line class-name="desktop__line" />

      <div class="fixators__inner-content">
        <div class='fixators__preview' v-for="item in getMenuPages(menu.id)" :key='item.id'>
            <Instrument :link="{ name: RouterName.CurrentInstrument, params: { id: item.id } }" :instrument="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Instrument from '@/desktop/components/Instrument'
import { mapGetters } from 'vuex'
import { Images } from '@/shared/Images/Clamps'
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'

export default {
  components: { BrokenLine, Instrument },
  data () {
    return {
      Images: Images,
      RouterName
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuChildren', 'getMenuPages']),
    menuChildren () {
      return this.getMenuChildren(this.$router.currentRoute.path.replace('/', '')) || null
    }
  },
  methods: {
    buttonClick (link) {
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.fixators {
  display: flex;
  flex-direction: column;
  padding: 3rem 0 0;

  &__row {
    align-items: center;
    display: flex;
  }

  &__inner-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &_center {
      justify-content: center;
    }
  }

  &__skelet-img {
    max-width: 55%;
    align-self: center;
    margin-top: 2rem;
  }

  &__preview {
    margin: 0.5rem 0;
/*    flex: 1 1 100%;*/
    max-width: 260px;

/*    &:nth-child(2n+1) {
      margin-left: 0;
      margin-right: 10px;
    }

    &:nth-child(2n) {
      margin-left: 10px;
      margin-right: 0;
    }
*/
    @include mq-m {
      max-width: 360px;
    }
/*
    @include mq-l {
      max-width: 310px;

      &:nth-child(3n+1) {
        margin-left: 0;
        margin-right: 10px;
      }

      &:nth-child(3n+2) {
        margin-left: 10px;
        margin-right: 10px;
      }

      &:nth-child(3n) {
        margin-left: 10px;
        margin-right: 0;
      }
    };
*/
    @include mq-xl {
      max-width: 335px;
    };
  }
}

.trio {
  align-self: center;
  display:flex;
  flex-direction:row;
  justify-content: space-around;
  margin-top: 3rem;
  width: 80%;

  &__img-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    max-width: 205px;
  }
}

.middle {
  align-self: center;
  width: 50rem;
}

.blue-block {
  background: $primary;
  display:flex;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;

  &__column {
    border-left: 3px solid $white;
    flex: 1 1 100%;
    max-width: 360px;
    padding: 2rem 3rem 2rem 2rem;

    @include mq-m {
      max-width: 360px;
    }

    @include mq-l {
      max-width: 363px;
    };

    @include mq-xl {
      max-width: 540px;
    };
  }
}

.white-text {
  color: $white;
  font-size: 1.3rem;
  text-align: justify;
  white-space: normal;
  word-break: break-all;
}

.reverse {
  transform: rotate(180deg);
}
.instrument-name{
  font-size: 0.2rem;
}
</style>

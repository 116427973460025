var render = function () {
  var _vm$getTranslate;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$getTranslate = _vm.getTranslate) !== null && _vm$getTranslate !== void 0 && _vm$getTranslate.additive ? _c('div', {
    staticClass: "additive-desktop__wrapper page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "class-name": "desktop__line"
    }
  }), _c('div', {
    staticClass: "additive-desktop__content"
  }, [_c('div', {
    staticClass: "additive-desktop__row"
  }, [_c('div', {
    staticClass: "additive-desktop__left-side"
  }, [_c('img', {
    staticClass: "w_full",
    attrs: {
      "src": _vm.Images.printer
    }
  }), _c('span', {
    staticClass: "sub-title",
    attrs: {
      "id": _vm.getTranslate.additive.printer
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.printer))]), _c('span', {
    staticClass: "simple",
    attrs: {
      "id": _vm.getTranslate.additive.process
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.process))]), _c('span', {
    staticClass: "main-title max-w_90 main-title_left additive-desktop__empty-block",
    attrs: {
      "id": _vm.getTranslate.additive.planMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.planMainTitle))]), _c('div', {
    staticClass: "analize__top"
  }, [_c('div', {
    staticClass: "analize__item"
  }, [_c('img', {
    staticClass: "additive-desktop__rounded-image",
    attrs: {
      "alt": "",
      "src": _vm.Images.MRT
    }
  }), _c('span', {
    staticClass: "sub-title max-w_40",
    attrs: {
      "id": _vm.getTranslate.additive.ktSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.ktSubtitle))])]), _c('div', {
    staticClass: "analize__item analize__item_last"
  }, [_c('img', {
    staticClass: "additive-desktop__rounded-image",
    attrs: {
      "alt": "",
      "src": _vm.Images.modeling
    }
  }), _c('span', {
    staticClass: "sub-title max-w_60",
    attrs: {
      "id": _vm.getTranslate.additive.modelSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.modelSubtitle))])])]), _c('img', {
    staticClass: "additive-desktop__clinic-img",
    attrs: {
      "alt": "",
      "src": _vm.Images.screws
    }
  }), _c('span', {
    staticClass: "simple"
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.deleting))]), _c('span', {
    staticClass: "sub-title sub-title_left additive-desktop__prosthesis-sub-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.endoSubTitle))])]), _c('div', {
    staticClass: "additive-desktop__right-side"
  }, [_c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.whatIsMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.whatIsMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.compModelSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.compModelSubtitle))]), _c('img', {
    staticClass: "w_full m-b_1 additive-desktop__welding-img v-hidden",
    attrs: {
      "alt": "",
      "src": _vm.Images.welding
    }
  }), _c('span', {
    staticClass: "simple additive-desktop__empty-block simple_left",
    attrs: {
      "id": _vm.getTranslate.additive.materials
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.materials))]), _c('span', {
    staticClass: "simple m-t_0 simple_left special",
    attrs: {
      "id": _vm.getTranslate.additive.forImplantsBold
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.forImplantsBold))]), _c('span', {
    staticClass: "simple m-t_0 simple_left",
    attrs: {
      "id": _vm.getTranslate.additive.titan
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.titan))]), _c('span', {
    staticClass: "simple m-t_0 simple_left special",
    attrs: {
      "id": _vm.getTranslate.additive.forModelBold
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.forModelBold))]), _c('span', {
    staticClass: "simple m-t_0 simple_left",
    attrs: {
      "id": _vm.getTranslate.additive.bio
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.bio))]), _c('div', {
    staticClass: "analize__item analize__item_last analize__item_left m-l_1"
  }, [_c('img', {
    staticClass: "additive-desktop__rounded-image",
    attrs: {
      "alt": "",
      "src": _vm.Images.production
    }
  }), _c('span', {
    staticClass: "sub-title max-w_70",
    attrs: {
      "id": _vm.getTranslate.additive.productionSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.productionSubtitle))])]), _c('span', {
    staticClass: "main-title main-title_left additive-desktop__clinic-title",
    attrs: {
      "id": _vm.getTranslate.additive.clinickMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.clinickMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.additive.clinickSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.additive.clinickSubtitle))]), _c('img', {
    staticClass: "additive-desktop__comp-img ",
    attrs: {
      "src": _vm.Images.compModel
    }
  })])]), _c('div', {
    staticClass: "additive-desktop__row m-t_1"
  }, [_c('img', {
    staticClass: "capture max-w_45 h_full",
    attrs: {
      "src": _vm.Images.shouldersModel
    }
  })])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
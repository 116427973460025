var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "sign-up-modal__title"
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.signUp))]), !_vm.isSuccess ? _c('div', [_c('ValidationObserver', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('form', {
          staticClass: "sign-up-modal__form",
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return _vm.handleSubmitSignUp.apply(null, arguments);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }],
                staticClass: "sign-up-modal__input",
                class: {
                  'sign-up-modal__input_error': errors[0]
                },
                attrs: {
                  "disabled": _vm.submitting,
                  "name": "email",
                  "type": "text",
                  "placeholder": _vm.getTranslate.forum.email
                },
                domProps: {
                  "value": _vm.email
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.email = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }],
                staticClass: "sign-up-modal__input",
                class: {
                  'sign-up-modal__input_error': errors[0]
                },
                attrs: {
                  "disabled": _vm.submitting,
                  "name": "name",
                  "type": "text",
                  "placeholder": _vm.getTranslate.forum.name
                },
                domProps: {
                  "value": _vm.name
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.name = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": "password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var errors = _ref4.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }],
                staticClass: "sign-up-modal__input",
                class: {
                  'sign-up-modal__input_error': errors[0]
                },
                attrs: {
                  "disabled": _vm.submitting,
                  "type": "password",
                  "placeholder": _vm.getTranslate.forum.pass
                },
                domProps: {
                  "value": _vm.password
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.password = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "name": "passwordConfirmation",
            "rules": "required|password:@password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var errors = _ref5.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordConfirmation,
                  expression: "passwordConfirmation"
                }],
                staticClass: "sign-up-modal__input",
                class: {
                  'sign-up-modal__input_error': errors[0]
                },
                attrs: {
                  "disabled": _vm.submitting,
                  "type": "password",
                  "placeholder": _vm.getTranslate.forum.passwordConfirmation
                },
                domProps: {
                  "value": _vm.passwordConfirmation
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.passwordConfirmation = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('Error', {
          attrs: {
            "size": "small",
            "error": _vm.commonError
          }
        }), _c('button', {
          staticClass: "forum-button sign-up-modal__submit-btn",
          class: {
            'forum-button_loading': _vm.submitting
          },
          attrs: {
            "type": "submit",
            "disabled": invalid || _vm.submitting
          }
        }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.signUp) + " ")])], 1)];
      }
    }], null, false, 1060219441)
  }), _c('div', {
    staticClass: "sign-up-modal__helper-text"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.signUpHaveAccount) + " "), _c('router-link', {
    staticClass: "sign-up-modal__redirect-link",
    attrs: {
      "to": _vm.Routes.ForumSignIn
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.signIn))])], 1)], 1) : _vm._e(), _vm.isSuccess ? _c('div', {
    staticClass: "sign-up-modal__success-modal"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.getTranslate.forum.signUpSuccessSentOnEmail) + " "), _c('b', [_vm._v(_vm._s(_vm.email))]), _vm._v(". " + _vm._s(_vm.getTranslate.forum.signUpSuccessCheckEmail) + " ")]), _c('p', {
    staticClass: "m-t_1"
  }, [_c('router-link', {
    staticClass: "forum-button p-l_1 p-r_1",
    attrs: {
      "to": _vm.Routes.ForumSignIn
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.signIn))])], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class='additive'>
    <div class="mobile-page-title" v-if="getPageTitle($router.currentRoute.path)">
      <h2 class='main-title main-title_left p-l_standard'>{{getPageTitle($router.currentRoute.path)}}</h2>
      <broken-line />
    </div>
    <div class="additive__content">
      <img :src='Images.printer' class='capture'/>
      <span class='sub-title sub-title_helvetica-italic'  :id="getTranslate.additive.printer" >{{getTranslate.additive.printer}}</span>
      <span class='main-title main-title_left'  :id="getTranslate.additive.whatIsMainTitle" >{{getTranslate.additive.whatIsMainTitle}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left'  :id="getTranslate.additive.compModelSubtitle" >{{getTranslate.additive.compModelSubtitle}}</span>
      <span class='simple m-t_1'  :id="getTranslate.additive.process">{{getTranslate.additive.process}}</span>
      <img :src='Images.welding' class='capture d-none'/>
      <span class='main-title main-title_left'  :id="getTranslate.additive.planMainTitle" >{{getTranslate.additive.planMainTitle}}</span>
      <span class='simple m-t_1'  :id="getTranslate.additive.materials"  >{{getTranslate.additive.materials}}</span>
      <div class='special simple_left'  :id="getTranslate.additive.forImplantsBold"  >{{getTranslate.additive.forImplantsBold}}</div>
      <div class='simple'  :id="getTranslate.additive.titan" >{{getTranslate.additive.titan}}</div>
      <div class='special simple_left' :id="getTranslate.additive.forModelBold"  >{{getTranslate.additive.forModelBold}}</div>
      <span class='simple'  :id="getTranslate.additive.bio" >{{getTranslate.additive.bio}}</span>
      <div class='analize__top'>
        <div class='analize-item'>
          <img alt="" class="additive__miniature-image" :src='Images.MRT' />
          <span class='sub-title sub-title_helvetica-italic'  :id="getTranslate.additive.ktSubtitle" >{{getTranslate.additive.ktSubtitle}}</span>
        </div>
        <div class='analize-item'>
          <img alt="" class="additive__miniature-image " :src='Images.modeling' />
          <span class='sub-title sub-title_helvetica-italic' :id="getTranslate.additive.modelSubtitle" >{{getTranslate.additive.modelSubtitle}}</span>
        </div>
      </div>
      <div class='analize-item'>
        <img alt="" class="additive__miniature-image" :src='Images.production' />
        <span class='sub-title sub-title_helvetica-italic'  :id="getTranslate.additive.productionSubtitle" >{{getTranslate.additive.productionSubtitle}}</span>
      </div>
      <span class='main-title main-title_left'  :id="getTranslate.additive.clinickMainTitle" >{{getTranslate.additive.clinickMainTitle}}</span>
      <span class='sub-title sub-title_left sub-title_helvetica-italic'  :id="getTranslate.additive.clinickSubtitle" >{{getTranslate.additive.clinickSubtitle}}</span>
      <img :src='Images.screws' class='capture '/>
      <span class='simple m-t_1'  :id="getTranslate.additive.deleting" >{{getTranslate.additive.deleting}}</span>
      <img :src='Images.compModel' class='capture'/>
      <span class='sub-title sub-title_left sub-title_helvetica-italic'  :id="getTranslate.additive.endoSubTitle" >{{getTranslate.additive.endoSubTitle}}</span>
<!--      <img :src='Images.shoulders' class='capture'/>-->
      <img :src='Images.shouldersModel' class='capture'/>
      <!-- <span class='sub-title sub-title_left sub-title_helvetica-italic'  :id="getTranslate.additive.models" >{{getTranslate.additive.models}}</span>
      <img :src='Images.shuriken' class='capture'/>
      <img :src='Images.washbasin' class='capture'/>
      <img :src='Images.sweater' class='capture'/>
      <img :src='Images.bigBone' class='capture'/> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Images } from '@/shared/Images/Additive'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  data () {
    return {
      Images
    }
  },
  computed: mapGetters(['getTranslate', 'getPageTitle'])
}
</script>

<style lang="scss">
.d-none{
  display: none;
}
.additive {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  &__content {
    padding: 0 0.8rem;
    margin: 0 auto;
    max-width: 500px;
  }

  &__miniature-image {
    object-fit: contain;
    height: 150px;
    margin: 0 auto;
    width: 150px;
  }
}
.analize {
  &-item {
    max-width: 180px;
    margin: 0 auto;
  }

  &__top {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "w1240"
  }, [_vm.isSuccess(_vm.getMenuStatus) ? _c('Header') : _vm._e(), _c('div', {
    staticClass: "content",
    class: {
      'content_disable-scroll': _vm.disableScroll
    }
  }, [_vm.isError(_vm.getMenuPagesStatus) || _vm.isError(_vm.getCurrentLoadingStatus) || _vm.isError(_vm.getMenuChildrenStatus) ? _c('div', {
    staticClass: "fake-content align-block-content"
  }, [!!_vm.getTranslate.common ? _c('h2', {
    staticClass: "main-title"
  }, [_vm._v(_vm._s(_vm.isError(_vm.getMenuPagesStatus) ? _vm.getTranslate.common.menuNotExist : _vm.getTranslate.common.commonError))]) : _vm._e()]) : _c('router-view'), _c('Footer')], 1)], 1), _vm.isLoading(_vm.getMenuStatus) || _vm.isLoading(_vm.getMenuPagesStatus) || _vm.isLoading(_vm.getCurrentLoadingStatus) || _vm.isLoading(_vm.getMenuChildrenStatus) ? _c('spinner-modal', {
    attrs: {
      "loading": true
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
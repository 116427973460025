import { BaseApi } from '@/api/base-api'

class PostApi extends BaseApi {
  /**
   * Получить список постов
   * @param id Number айди обсуждения
   * @param query Object {per_page: Number, page: Number}
   * @returns {Promise<any|undefined>}
   */
  listGet (id, query) {
    return this.get(`/api/discussions/${id}/posts`, { query })
  }

  /**
   * Создание поста
   * @param id Number айди обсуждения
   * @param body Object {title: String, description: String}
   * @returns {Promise<any|undefined>}
   */
  createPost (id, body) {
    return this.post(`/api/discussions/${id}/posts`, { body })
  }

  /**
   * Получение поста по айди
   * @param id Number айди поста
   * @returns {Promise<any|undefined>}
   */
  getPost (id) {
    return this.get(`/api/posts/${id}`)
  }

  /**
   * Удаление поста
   * @param id Number айди поста
   * @returns {Promise<any|undefined>}
   */
  deletePost (id) {
    return this.delete(`/api/posts/${id}`)
  }

  /**
   * Обновление поста
   * @param id айди поста
   * @param body Object {title: String, description: String, content: String}
   * @returns {Promise<any|undefined>}
   */
  update (id, body) {
    return this.put(`/api/posts/${id}`, { body })
  }

  /**
   * Подписаться на пост
   * @param id Number айди поста
   * @returns {Promise<any|undefined>}
   */
  subscribe (id) {
    return this.post('/api/subscribe/post', { body: { post_id: id }})
  }
}

export default new PostApi()

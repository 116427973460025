<template>
  <div class='home' id="home">
    <div class='faded-top'>
      <a href="#additive-technologies" data-to="#additive-technologies" @click="handleClick">
        <FadedCap :bottomLayer='homeImages.hipBoneBlack' :topLayer='homeImages.hipBone'/>
      </a>
      <a href="#scissors" data-to="#scissors" @click="handleClick">
        <FadedCap :bottomLayer='homeImages.scissorsBlack' :topLayer='homeImages.scissors'/>
      </a>
      <a href="#endoprosthesis" data-to="#endoprosthesis" @click="handleClick">
        <FadedCap :bottomLayer='homeImages.headBlack' :topLayer='homeImages.head'/>
      </a>
      <a href="#hip-bone" data-to="#hip-bone" @click="handleClick">
        <FadedCap :bottomLayer='homeImages.kneeBlack' :topLayer='homeImages.knee'/>
      </a>
      <a href="#mechanism" data-to="#mechanism" @click="handleClick">
        <FadedCap :bottomLayer='homeImages.mechanismBlack' :topLayer='homeImages.mechanism'/>
      </a>
    </div>
    <div class="page-content">
      <h2 class='main-title mb70' :id="getTranslate.home.techno" >{{getTranslate.home.techno}}</h2>
      <div class="row" id="mechanism">
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.plecho' />
        </div>
        <div class="part part_left">
          <span class='main-title main-title_left m-l_1' :id="getTranslate.home.fiksators" >
            <router-link :to="Routes.Clamps">
              {{getTranslate.home.fiksators}}
            </router-link>
          </span>
          <span class='sub-title sub-title_left m-l_1' :id="getTranslate.home.miniInvazive">{{getTranslate.home.miniInvazive}}</span>
        </div>
      </div>

      <span class='main-title main-title_left main-title_smaller max-w_35 home__fracture-title' :id="getTranslate.home.fracture">{{getTranslate.home.fracture}}</span>
      <div class="row m-t_1">
        <div class="part">
<!--          <span class='sub-title sub-title_left main-title_smaller m-b_1 m-t_1' :id="getTranslate.home.operationTitle">{{getTranslate.home.operationTitle}}</span> -->
          <div class="home__ul">
            <span class="sub-title sub-title_left">
              {{getTranslate.home.fractureOperation1}}
            </span>
            <span class="sub-title sub-title_left">
              {{getTranslate.home.fractureOperation2}}
            </span>
<!--            <li class="home__li">  -->
<!--              {{getTranslate.home.fractureOperation3}} -->
<!--            </li> -->
<!--            <li class="home__li"> -->
<!--              {{getTranslate.home.fractureOperation4}} -->
<!--            </li> -->
          </div>
        </div>
        <div class="part">
          <span class='sub-title sub-title_left max-w_50 m-l_3' :id="getTranslate.home.operation">{{getTranslate.home.operation}}</span>
          <RoundCap imageClass="w_full" :img='homeImages.fracture' />
        </div>
      </div>
      <broken-line className="desktop__line" rtl />
      <div class="row" id="scissors">
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.wireCutters' />
        </div>
        <div class="part">
          <span class='main-title main-title_left m-l_1' :id="getTranslate.home.surgeryInstrumentHead">
            <router-link :to="Routes.Instruments">
              {{getTranslate.home.surgeryInstrumentHead}}
            </router-link>
          </span>
          <span class='sub-title sub-title_left m-l_1'>{{getTranslate.home.surgeryInstrumentHeadDesc}}</span>
        </div>
      </div>
      <div class="row">
        <div class="part">
          <h3 class='main-title main-title_left main-title_smaller m-b_1 max-w_90' :id="getTranslate.home.surgeryInstrumentTitle">{{getTranslate.home.surgeryInstrumentTitle}}</h3>
<!--          <span class='sub-title sub-title_left m-t_1 m-b_1' :id="getTranslate.home.surgeryInstrumentSubtitle">{{getTranslate.home.surgeryInstrumentSubtitle}}</span> -->
          <span class='sub-title sub-title_left'>{{getTranslate.home.surgeryInstrumentTitleDesc}}</span>
          <span class='sub-title sub-title_left'>{{getTranslate.home.surgeryInstrumentTitleMadeOf}}</span>
        </div>
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.whistle' />
        </div>
      </div>
      <broken-line className="desktop__line" />
      <div class="row" id="additive-technologies">
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.additive' />
        </div>
        <div class="part">
          <span class='main-title main-title_left' :id="getTranslate.home.additiveHead">
            <router-link :to="Routes.Additive">
              {{getTranslate.home.additiveHead}}
            </router-link>
          </span>
          <span class='sub-title sub-title_left max-w_65' :id="getTranslate.home.additiveSubtitle">{{getTranslate.home.additiveSubtitle}}</span>
        </div>
      </div>
      <div class="row">
        <div class="part">
          <span class='main-title main-title_left main-title_smaller m-b_1 max-w_75' :id="getTranslate.home.additiveTitle">{{getTranslate.home.additiveTitle}}</span>
 <!--         <span class='sub-title sub-title_left' :id="getTranslate.home.additiveDir">{{getTranslate.home.additiveDir}}</span> -->
          <span class='sub-title sub-title_left'>{{getTranslate.home.directions}}</span>
        </div>
        <div class="part">
           <RoundCap imageClass="w_full" :img='homeImages.tail' />
<!--          <RoundCap imageClass="w_full" :img='homeImages.additive' />-->
        </div>
      </div>
      <broken-line className="desktop__line" rtl />
      <div class="row m-b_1" id="endoprosthesis">
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.whiteHead' />
        </div>
        <div class="part">
          <span class='main-title main-title_left m-b_1' :id="getTranslate.home.endoProtesisMainTitle">
            <router-link :to="{ name: RouterName.ProsthesisList, params: { id: 'stm' } }">
              {{getTranslate.home.endoProtesisMainTitle}}
            </router-link>
          </span>
          <span class='sub-title sub-title_left' :id="getTranslate.home.endoProtesisMainSubtitle">{{getTranslate.home.endoProtesisMainSubtitle}}</span>
        </div>
      </div>
      <div class="row">
        <div class="part">
          <span class='main-title main-title_left main-title_smaller m-b_1' :id="getTranslate.home.stmMainTitle">{{getTranslate.home.stmMainTitle}}</span>
          <span class='sub-title sub-title_left' :id="getTranslate.home.stmSubtitle">{{getTranslate.home.stmSubtitle}}</span>
          <span class="sub-title sub-title_left m-t_0">{{getTranslate.home.stmSubtitle1}}</span>
          <span class="sub-title sub-title_left m-t_0">{{getTranslate.home.stmSubtitle2}}</span>
          <span class="sub-title sub-title_left m-t_0">{{getTranslate.home.stmSubtitle3}}</span>
        </div>
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.balls' />
        </div>
      </div>
      <broken-line className="desktop__line" />
      <div class="row" id="hip-bone">
        <div class="part">
          <RoundCap imageClass="w_full" :img='homeImages.glitter' />
        </div>
        <div class="part">
          <span class='main-title main-title_left max-w_80' :id="getTranslate.home.pharaonMainTitle">
            <router-link :to="Routes.Prostheses">
              {{getTranslate.home.pharaonMainTitle}}
            </router-link>
          </span>
          <span class='sub-title sub-title_left' :id="getTranslate.home.pharaonSubTitle">{{getTranslate.home.pharaonSubTitle}}</span>
        </div>
      </div>
      <div class="row">
        <div class="part">
          <span class='main-title main-title_left main-title_smaller' :id="getTranslate.home.pharaonTitle">{{getTranslate.home.pharaonTitle}}</span>
          <span class='sub-title sub-title_left' :id="getTranslate.home.pharaonSubtitle">{{getTranslate.home.pharaonSubtitle}}</span>
          <span class='sub-title sub-title_left'  :id="getTranslate.home.pharaon">{{getTranslate.home.pharaon}}</span>
        </div>
        <div class="part"></div>
      </div>
    </div>
  </div>
</template>

<script>
import FadedCap from '@/components/FadedCap'
import RoundCap from '@/components/RoundCap'
import { mapGetters, mapActions } from 'vuex'
import { homeImages } from '@/shared/Images/Home'
import { Routes, RouterName } from '@/shared/constants/routes'
import BrokenLine from '@/components/BrokenLine'
import zenscroll from 'zenscroll'

const HEADER_HEIGHT = 140
const DURATION = 500
export default {
  components: { BrokenLine, FadedCap, RoundCap },
  data () {
    return {
      Routes,
      RouterName,
      homeImages
    }
  },
  computed: mapGetters(['getTranslate']),
  methods: {
    ...mapActions(['changeLang']),
    // плавный скролл
    scrollTo (anchorId) {
      const anchor = document.querySelector(anchorId)

      if (anchor) {
        this.$myScroller.to(anchor)
      }
    },
    handleClick (e) {
      const { dataset: { to }} = e.currentTarget
      this.scrollTo(to)
    }
  },
  mounted () {
    const appContainer = document.getElementById('app')
    this.$myScroller = zenscroll.createScroller(appContainer, DURATION, HEADER_HEIGHT)

    if (this.$router.currentRoute.hash) {
      setTimeout(() => {
        this.scrollTo(this.$router.currentRoute.hash)
      }, 300)
    }
  }
}
</script>

<style lang="scss" >
@import '@/shared/style/media.scss';

.home-wrapper {
  width: 100%;
}
.home {
  width: 100%;
  padding: 80px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;

  &__ul {
    margin-inline-start: 20px;
    list-style: decimal;
  }

  &__li {
    font-family: 'Open sans', sans-serif;
    text-align: left;
    font-size: 1.5rem;
//    list-style: decimal;
  }

  &__fracture-title {
    padding-left: 20px;
    margin-bottom: 2rem;
  }
}

.mb70{
  margin-bottom: 40px;
}
.m-l_3{
  margin-left: 3rem;
}
.m-t_0{
  margin-top: 0;
}
.row {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
}

.part {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  justify-content: center;
  padding: 0 0px;

  &:last-child {
    padding-left: 4rem;
    align-items: flex-start;
    flex: 1 1 40%;
  }

  &_left {
    align-items: flex-start;
  }
}
/*
.image-wrapper {
  height: 300px;
  width: 300px;
}
*/
.homePageTitle {
  width: 100%;
  font-size: 3rem;

  .left-column {
    width: 100%;
  }
}

</style>


var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-component"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.content || _vm.getTranslate.common.pageNotFound) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
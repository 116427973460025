var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getPageTitle(_vm.$router.currentRoute.path) ? _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path),
      "title": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1) : _vm._e(), _vm._l(_vm.menuPages, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "vacancy_item"
    }, [_c('h2', {
      staticClass: "main-title main-title_left m-b_1"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "vacancy_text t-a_i"
    }, [_vm._v(" " + _vm._s(item.description) + " ")]), _c('div', {
      staticClass: "vacancy_text t-a_i m-t_1",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$getTranslate, _vm$menuPage$images, _vm$menuPage$images2, _vm$getTranslate2, _vm$getTranslate3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-about__content page-content"
  }, [!!_vm.menuPage ? _c('h2', {
    staticClass: "main-title main-title_left"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]) : _vm._e(), !!_vm.menuPage ? _c('broken-line', {
    attrs: {
      "className": "desktop-about__line"
    }
  }) : _vm._e(), !!_vm.menuPage && (_vm$getTranslate = _vm.getTranslate) !== null && _vm$getTranslate !== void 0 && _vm$getTranslate.about ? _c('div', {
    staticClass: "desktop-about__block"
  }, [_c('div', {
    staticClass: "desktop-about__image-block"
  }, [!!((_vm$menuPage$images = _vm.menuPage.images) !== null && _vm$menuPage$images !== void 0 && _vm$menuPage$images[0]) ? _c('img', {
    staticClass: "desktop-about__building-image",
    attrs: {
      "src": (_vm$menuPage$images2 = _vm.menuPage.images) === null || _vm$menuPage$images2 === void 0 ? void 0 : _vm$menuPage$images2[0],
      "alt": "building"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "desktop-about__resident-wrapper"
  }, [_c('img', {
    staticClass: "desktop-about__resident-image",
    attrs: {
      "src": _vm.Images.eco,
      "alt": "ROCOES"
    }
  }), _c('div', {
    staticClass: "desktop-about__resident-name"
  }, [_vm._v(_vm._s(_vm.getTranslate.about.tvt) + " "), _c('b', [_vm._v(_vm._s(_vm.getTranslate.about.tvtBold))])])])]), _c('div', {
    staticClass: "desktop-about__text"
  }, [_c('h2', {
    staticClass: "desktop-about__sub-title"
  }, [_vm._v(_vm._s(_vm.menuPage.title))]), _c('div', {
    staticClass: "simple simple_column"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.menuPage.content)
    }
  })])])]) : _vm._e(), (_vm$getTranslate2 = _vm.getTranslate) !== null && _vm$getTranslate2 !== void 0 && _vm$getTranslate2.about ? _c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.about.contacts
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.contacts))]) : _vm._e(), _c('broken-line', {
    attrs: {
      "className": "desktop-about__line"
    }
  }), (_vm$getTranslate3 = _vm.getTranslate) !== null && _vm$getTranslate3 !== void 0 && _vm$getTranslate3.about ? _c('section', {
    staticClass: "desktop-about__contact-info"
  }, [_c('div', {
    staticClass: "desktop-about__contact-wrapper"
  }, [_c('h4', {
    staticClass: "desktop-about__sub-title m-b_1"
  }, [_vm._v(_vm._s(_vm.getTranslate.about.salesDepartment))]), _c('span', {
    staticClass: "desktop-about__contact-row"
  }, [_c('span', [_vm._v(_vm._s(_vm.getTranslate.about.phoneLabel))]), _vm._v(_vm._s(': ') + " "), _c('a', {
    attrs: {
      "href": ("tel:" + (_vm.getTranslate.about.salesPhone))
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.salesPhone))])]), _c('span', {
    staticClass: "desktop-about__contact-row"
  }, [_c('span', [_vm._v(_vm._s(_vm.getTranslate.about.emailLabel))]), _vm._v(_vm._s(': ') + " "), _c('a', {
    attrs: {
      "href": ("mailto:" + (_vm.getTranslate.about.salesMail))
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.salesMail))])])]), _c('div', {
    staticClass: "desktop-about__contact-wrapper"
  }, [_c('h4', {
    staticClass: "desktop-about__sub-title m-b_1"
  }, [_vm._v(_vm._s(_vm.getTranslate.about.productionDepartment))]), _c('span', {
    staticClass: "desktop-about__contact-row"
  }, [_c('span', [_vm._v(_vm._s(_vm.getTranslate.about.phoneLabel))]), _vm._v(_vm._s(': ') + " "), _c('a', {
    attrs: {
      "href": ("tel:" + (_vm.getTranslate.about.productionPhone))
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.productionPhone))])]), _c('span', {
    staticClass: "desktop-about__contact-row"
  }, [_c('span', [_vm._v(_vm._s(_vm.getTranslate.about.emailLabel))]), _vm._v(_vm._s(': ') + " "), _c('a', {
    attrs: {
      "href": ("mailto:" + (_vm.getTranslate.about.productionMail))
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.about.productionMail))])])])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
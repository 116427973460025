var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news"
  }, [_c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_left p-l_standard"
  }, [_vm._v(" " + _vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)) + " ")]), _vm.getPageTitle(_vm.$router.currentRoute.path) ? _c('broken-line') : _vm._e()], 1), _c('div', {
    staticClass: "news__content p-l_standard p-r_standard"
  }, _vm._l(_vm.menuPages, function (item) {
    var _item$images, _item$images2;

    return _c('div', {
      key: item.index,
      staticClass: "m-b_25"
    }, [(_item$images = item.images) !== null && _item$images !== void 0 && _item$images[0] ? _c('img', {
      staticClass: "capture",
      attrs: {
        "src": (_item$images2 = item.images) === null || _item$images2 === void 0 ? void 0 : _item$images2[0],
        "alt": item.Title
      }
    }) : _vm._e(), _c('span', {
      staticClass: "main-title main-title_left overflow-ellipse-3"
    }, [_vm._v(_vm._s(item.title))]), _c('span', {
      staticClass: "sub-title sub-title_helvetica-italic sub-title_left overflow-ellipse-3",
      attrs: {
        "id": item.description
      }
    }, [_vm._v(_vm._s(item.description))]), item.external_url ? _c('a', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "href": item.external_url,
        "target": "_blank",
        "id": item.external_url
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslate.news.detail) + " ")]) : _c('router-link', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "to": {
          name: _vm.RouterName.CurrentNews,
          params: {
            id: item.id
          }
        },
        "id": item.external_url
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslate.news.detail) + " ")])], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class='desktop-instruments page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)">{{ getPageTitle($router.currentRoute.path) }}</h2>
    <broken-line class-name="desktop__line" />

    <div class="desktop-instruments__content">
      <div class='preview' v-for="item in menuPages" :key='item.id'>
        <instrument :link="{ name: RouterName.CurrentInstrument, params: { id: item.id } }" :instrument="item" />
      </div>
    </div>
  </div>
</template>

<script>
import Instrument from '@/desktop/components/Instrument'
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      RouterName
    }
  },
  components: { BrokenLine, Instrument },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
@import '@/shared/style/media.scss';

.desktop-instruments {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }
}
.preview {
  margin: 0.2rem 0;
  max-width: 296px;
  width: 100%;

  @include mq-m {
    max-width: 364px;
  }

  @include mq-l {
    max-width: 318px;
  };

  @include mq-xl {
    max-width: 310px;
    margin-bottom: 25px;
  };
}

.line-reverse {
  margin-bottom: 3rem;
}
</style>

<template>
  <div class="page-content new">
    <div class="news-title-wrapper">
      <span class='main-title main-title_left' :id="getPageTitle(Routes.News)">{{getPageTitle(Routes.News)}}</span>
      <broken-line />
    </div>

    <div class="d_f m-t_1 p-t_1 p-b_1">
      <router-link class="d_f news-button-back" :to="Routes.News">{{ getTranslate.common.back }}</router-link>
    </div>

    <div v-if="currentNews">
      <h2 class="main-title main-title_left">{{ currentNews.title }}</h2>
      <span class="sub-title sub-title_left" v-html="currentNews.content"></span>
      <div class="image-gallery" v-if="currentNews.images.length">
        <div class="image-wrapper" v-for="src in currentNews.images" :key="src">
          <img alt="" class="image-src" :src="src" />
        </div>
      </div>
    </div>

    <div v-else-if="isError(getCurrentLoadingStatus)">
      <error-component title="404" :content="getTranslate.common.pageNotFound" />
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { isError } from '@/shared/helpers/menu'
import { mapGetters, mapActions } from 'vuex'
import ErrorComponent from './ErrorComponent.vue'
import { Routes } from '@/shared/constants/routes'

export default {
  components: { BrokenLine, ErrorComponent },
  data () {
    return {
      Routes,
      id: this.$route.params.id
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getCurrentPage', 'getCurrentLoadingStatus', 'getPageTitle']),
    currentNews () {
      if (!this.id) {
        return null
      }
      return this.getCurrentPage(this.id)
    }
  },
  methods: {
    ...mapActions(['changeLang']),
    isError
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/media.scss';
@import '@/shared/style/colors.scss';

.new {
  padding: 0 0.8rem;
  flex: 1;
}

.news-title-wrapper {
  padding: 0 0 0 1rem;

  @include mq-m {
    padding: 0;

    .broken-line {
      left: -120px;
      width: calc(100% + 120px);
    }
  }
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-wrapper {
  padding: 0.5rem;
  margin-top: 1rem;
  width: 100%;

  @include mq-s {
    width: 50%;
  }
}

.image-src {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.news-button-back {
  color: $primary;
  transition: color 0.2s;

  &:hover {
    color: rgba($primary, 0.6);
  }
}
</style>

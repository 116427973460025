<template>
  <div class="view-topic" v-if="!!getTranslate.common">
    <!-- Поправить размер -->
    <!-- <div v-if="!getDiscussion.discussion.loading && !getDiscussion.discussion?.id">
      <error-component title="404" :content="getTranslate.common.pageNotFound" />
    </div> -->

    <div v-if="!!getDiscussion.discussion?.id">
      <Breadcrumbs :itemList="[{label: getTranslate.forum.forum, route: Routes.ForumTopicList}, {label: getDiscussion.discussion.title}]" wrapperClass="topic-list__breadcrumbs" />
      <h1 class="main-title m-t_1 view-topic__titles">{{getDiscussion.discussion.title}}</h1>
      <h4 class="sub-title m-t_1 view-topic__titles">{{getDiscussion.discussion.description}}</h4>
      <spinner-container v-if="!!getDiscussion.discussion?.id" :loading="getPostList.loading || getPost.deletePostLoading">
        <div class="view-topic__content">
          <div class="view-topic__row">
            <div v-if="getPostList && getPostList.list && getPostList.list.length" class="simple">{{ getTranslate.forum.title }}</div>
            <div class="simple" v-else></div>
            <button v-if="permissions.createPosts" class="forum-button view-topic__create-button" @click="onCreatePost">{{ getTranslate.forum.createPost }}</button>
          </div>
          <div v-if="getPostList && getPostList.list && getPostList.list.length">
            <div class="view-topic__post" v-for="post in getPostList.list" v-bind:key="post.id">
              <div class="view-topic__post-row">
                <div class="view-topic__td">
                  <router-link class="view-topic__link" :to="{name: RouterName.ForumPostView, params: {topicId: post.discussion.id, postId: post.id}}">
                    {{post.title}}
                  </router-link>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/comment.svg'" v-bind:class="'view-topic__post-icon view-topic__td'"><path d="M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z" fill="currentColor"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/heart.svg'" v-bind:class="'view-topic__post-icon view-topic__td'"><path d="M12 21.593C6.37 16.054 1 11.296 1 7.191 1 3.4 4.068 2 6.281 2c1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447C20.266 2.01 23 3.631 23 7.191c0 4.069-5.136 8.625-11 14.402M17.726 1.01c-2.203 0-4.446 1.042-5.726 3.238C10.715 2.042 8.478 1 6.281 1 3.098 1 0 3.187 0 7.191 0 11.852 5.571 16.62 12 23c6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181" fill="currentColor"/></svg>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 511.999 511.999" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/eye.svg'" v-bind:class="'view-topic__post-icon view-topic__td'"><path d="M508.129 245.942c-1.155-1.278-28.809-31.661-73.431-62.307C375.034 142.659 313.241 121 255.999 121c-57.241 0-119.034 21.659-178.698 62.635-44.622 30.646-72.275 61.029-73.43 62.307-5.162 5.712-5.162 14.404 0 20.115 1.155 1.278 28.809 31.661 73.431 62.307C136.965 369.341 198.759 391 255.999 391c57.241 0 119.034-21.659 178.698-62.635 44.622-30.646 72.275-61.029 73.431-62.307 5.161-5.712 5.161-14.404.001-20.116zM145.484 333.44c-40.404-19.392-78.937-47.81-109.449-77.434 17.675-17.131 57.684-52.53 109.471-77.478C130.074 200.472 121 227.195 121 256c-.001 28.791 9.064 55.502 24.484 77.44zM255.999 361c-57.848 0-105-47.06-105-105 0-57.897 47.103-105 105-105 57.854 0 105 47.066 105 105 .001 57.897-47.102 105-105 105zm110.495-27.528C381.926 311.528 391 284.805 391 256c0-28.791-9.065-55.502-24.484-77.44 40.404 19.392 78.937 47.81 109.449 77.434-17.674 17.131-57.684 52.53-109.471 77.478z" fill="currentColor"/><path d="M255.999 181c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75c.001-41.355-33.644-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.186 45-45 45z" fill="currentColor"/></svg>
                <div class="view-topic__activity view-topic__td">{{getTranslate.forum.activity}}</div>
                <div v-if="showPostOptions" class="view-topic__td">
                  <dropdown>
                    <p>
                      <router-link v-if="canEdit" class="post-dropdown__item m-b_1" :to="{name: RouterName.ForumPostEdit, params: {topicId: post.discussion.id, postId: post.id}}">
                        <svg height="484pt" viewBox="-15 -15 484 484" width="484pt" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/pen.svg'" v-bind:class="'post-dropdown__icon'"><path d="M401.648 18.234c-24.394-24.351-63.898-24.351-88.293 0l-22.101 22.223-235.27 235.145-.5.503c-.12.122-.12.25-.25.25-.25.375-.625.747-.87 1.122 0 .125-.13.125-.13.25-.25.375-.37.625-.625 1-.12.125-.12.246-.246.375-.125.375-.25.625-.379 1 0 .12-.12.12-.12.25L.663 437.32a12.288 12.288 0 002.996 12.735 12.564 12.564 0 008.867 3.625c1.356-.024 2.7-.235 3.996-.625l156.848-52.325c.121 0 .121 0 .25-.12a4.523 4.523 0 001.121-.505.443.443 0 00.254-.12c.371-.25.871-.505 1.246-.755.371-.246.75-.62 1.125-.87.125-.13.246-.13.246-.25.13-.126.38-.247.504-.5l257.371-257.372c24.352-24.394 24.352-63.898 0-88.289zM169.375 371.383l-86.914-86.91L299.996 66.938l86.914 86.91zm-99.156-63.809l75.93 75.926-114.016 37.96zm347.664-184.82l-13.238 13.363L317.727 49.2l13.367-13.36c14.62-14.609 38.32-14.609 52.945 0l33.965 33.966c14.512 14.687 14.457 38.332-.121 52.949zm0 0" fill="currentColor"/></svg>
                        {{ getTranslate.forum.editPost }}
                      </router-link>
                    </p>
                    <p>
  <!--                    модалку для удаления сделать -->
                      <button v-if="canDelete" class="post-dropdown__item prevent-button-styles" @click="handleDeletePost(post.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.4 486.4" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/basket.svg'" v-bind:class="'post-dropdown__icon'"><g fill="currentColor"><path d="M446 70H344.8V53.5c0-29.5-24-53.5-53.5-53.5h-96.2c-29.5 0-53.5 24-53.5 53.5V70H40.4c-7.5 0-13.5 6-13.5 13.5S32.9 97 40.4 97h24.4v317.2c0 39.8 32.4 72.2 72.2 72.2h212.4c39.8 0 72.2-32.4 72.2-72.2V97H446c7.5 0 13.5-6 13.5-13.5S453.5 70 446 70zM168.6 53.5c0-14.6 11.9-26.5 26.5-26.5h96.2c14.6 0 26.5 11.9 26.5 26.5V70H168.6V53.5zm226 360.7c0 24.9-20.3 45.2-45.2 45.2H137c-24.9 0-45.2-20.3-45.2-45.2V97h302.9v317.2h-.1z"/><path d="M243.2 411c7.5 0 13.5-6 13.5-13.5V158.9c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v238.5c0 7.5 6 13.6 13.5 13.6zM155.1 396.1c7.5 0 13.5-6 13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v208.9c0 7.5 6.1 13.5 13.5 13.5zM331.3 396.1c7.5 0 13.5-6 13.5-13.5V173.7c0-7.5-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v208.9c0 7.5 6 13.5 13.5 13.5z"/></g></svg>
                        {{ getTranslate.forum.deletePost }}
                      </button>
                    </p>
                  </dropdown>
                </div>
              </div>
              <div class="view-topic__post-row">
                <div class="view-topic__td view-topic__author">{{getTranslate.forum.author }}: {{post.authorName || getTranslate.forum.unknownAuthor}}</div>
                <div class="view-topic__td">{{post.comments}}</div>
                <div class="view-topic__td">{{post.likes}}</div>
                <div class="view-topic__td">{{post.views}}</div>
                <div class="view-topic__td">{{dayjs(post.updated_at).format('MMMM')}}</div>
                <div v-if="showPostOptions" class="view-topic__td"></div>
              </div>
            </div>
            <pagination v-model="paginationModel" :per-page="20" :records="paginationRecords" @paginate="handlePaginate"/>
          </div>
        </div>
      </spinner-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import Breadcrumbs from '@/components/Breadcrumbs'
import { Pagination } from 'vue-pagination-2'
import SpinnerContainer from '@/components/SpinnerContainer'
import Dropdown from '@/components/Dropdown'
import { getPermissions } from '@/shared/helpers'
import { Routes, RouterName } from '@/shared/constants/routes'
import ErrorComponent from '@/views/ErrorComponent.vue'

export default {
  name: 'view-topic',
  data: () => ({
    Routes,
    RouterName,
    topicId: 0,
    dayjs,
    page: 1
  }),
  components: { Dropdown, Breadcrumbs, Pagination, SpinnerContainer, ErrorComponent },
  computed: {
    ...mapGetters(['getTranslate', 'getLang', 'getPost', 'getPostList']),
    ...mapGetters('discussion', ['getDiscussion']),
    ...mapGetters('auth', ['getSelf', 'getACL']),
    paginationModel () {
      return this.getPostList?.meta?.current_page
    },
    paginationRecords () {
      return this.getPostList?.meta?.total || 0
    },
    permissions () {
      return this.getPermissions(this.getACL, this.getSelf, ['createPosts', 'updatePosts', 'deletePosts'])
    },
    canEdit () {
      return this.getSelf && (this.permissions.updatePosts || this.post?.creatorId === this.getSelf?.id)
    },
    canDelete () {
      return this.getSelf && (this.permissions?.deletePosts || this.post?.creatorId === this.getSelf?.id)
    },
    showPostOptions () {
      return this.canDelete || this.canEdit
    }
  },
  methods: {
    ...mapActions('discussion', ['actionGetDiscussion']),
    ...mapActions(['actionGetPostList', 'actionDeletePost']),
    getPermissions,
    onCreatePost () {
      this.$router.push({ name: this.RouterName.ForumCreatePost, params: { topicId: this.topicId || this.getDiscussion.discussion.id }})
    },
    handleDeletePost (postId) {
      this.actionDeletePost(postId).then((response) => {
        if (response) {
          this.actionGetPostList({ topicId: this.topicId || this.getDiscussion.discussion.id, query: { page: this.page }})
        }
      })
    },
    handlePaginate (page) {
      this.actionGetPostList({ topicId: this.topicId || this.getDiscussion.discussion.id, offset: page * 20 })
    }
  },
  watch: {
    getLang (value) {
      dayjs.locale(value)
    }
  },
  beforeMount () {
    if (this.$route.params.topicId && typeof +this.$route.params.topicId === 'number') {
      this.topicId = +this.$route.params.topicId
    }
    dayjs.locale(this.getLang)
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/variables.scss';
@import '@/shared/style/media.scss';

.view-topic {
  font-family: 'Open sans', sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;

  @include mq-m {
    padding: 0;
    width: 846px;
  }

  @include mq-l {
    width: 970px;
  }

  @include mq-xl {
    width: 1280px;
  }

  @include mq-xxl {
    width: 1440px;
  }

  &__titles {
    align-items: flex-start;

    @include mq-m {
      align-items: center;
    }
  }

  &__content {
    margin-top: 20px;

    @include mq-m {
      margin-top: 56px;
    }
  }

  &__title {
    color: $primary;
    font-family: 'Din', Arial, sans-serif;
    font-size: 5rem;
    margin-top: 24px;
    text-align: center;
  }

  &__description {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2rem;
    margin: 24px auto 0;
    max-width: 500px;
  }

  &__post {
    border: 1px solid $black;
    margin-bottom: 16px;
    padding: 16px 24px;
  }

  &__row {
    display: flex;
    margin-bottom: 10px;

    @include mq-m {
      padding-left: 16px;
    }
  }

  &__create-button {
    width: auto;
    flex-shrink: 0;
    padding: 0 2rem;
    height: 38px;
    margin-left: auto;
  }

  &__link {
    color: $primary;
    font-size: 1rem;

    @include mq-m {
      font-size: 1.2rem;
    }
  }

  &__post-row {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__td {
    &:nth-child(1) {
      flex: 1 1 75%;
      text-align: left;
    }

    &:nth-child(2) {
      flex: 1 1 50px;
    }

    &:nth-child(3) {
      flex: 1 1 50px;
    }

    &:nth-child(4) {
      flex: 1 1 50px;

    }

    &:nth-child(5) {
      flex: 1 1 15%;
      text-align: left;
      padding-left: 16px;
    }

    &:nth-child(6) {
      flex: 1 1 5%;
      text-align: left;
      padding-left: 0;
    }
  }

  &__post-icon {
    color: $gray-dark;
    height: 16px;
    margin: 0 14px;
    max-width: 16px;
    min-width: 16px;
    width: 16px;
  }

  &__author {
    font-size: 0.9rem;

    @include mq-m {
      font-size: 1.2rem;
    }
  }

  &__activity {
    font-size: 0.9rem;

    @include mq-m {
      font-size: 1.2rem;
    }
  }
}

.post-dropdown {
  &__item {
    color: $black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    font: 1.1rem/100% $openSans;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  &__icon {
    height: 16px;
    min-width: 16px;
    width: 16px;
    margin-right: 8px;
  }
}
</style>

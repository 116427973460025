<template>
  <div class='documentation'>
    <div class="mobile-page-title">
      <h1 class='main-title main-title_left p-l_standard'>{{getPageTitle($router.currentRoute.path)}}</h1>
      <broken-line />
    </div>

    <div class="documentation__content">
      <div :key="id" v-for="({id, title, images}) in menuPages">
        <h4 class='light-title documentation__sub-title'>{{title}}</h4>
        <img :key="image" v-for="image in images" :src="image" class='capture' :alt="title">
      </div>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
.documentation {
  &__content {
    padding: 0 0.8rem;
    margin: 0 auto;
    max-width: 500px;
  }

  &__sub-title {
    margin: 1rem 0 0.5rem;
  }
}
</style>

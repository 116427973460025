var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.handleClickAway,
      expression: "handleClickAway"
    }],
    ref: "dropdownRef",
    staticClass: "dropdown",
    class: _vm.wrapperClass
  }, [_c('button', {
    ref: "moreIconRef",
    staticClass: "dropdown__button prevent-button-styles",
    class: {
      'dropdown__button--active': _vm.isOpened
    },
    on: {
      "click": _vm.handleClickMore
    }
  }, [_c('svg', {
    class: 'dropdown__more-icon',
    attrs: {
      "height": "512",
      "viewBox": "0 0 515.555 515.555",
      "width": "512",
      "xmlns": "http://www.w3.org/2000/svg",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/more.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0M303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0M303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0",
      "fill": "currentColor"
    }
  })])]), _c('div', {
    staticClass: "dropdown__content",
    class: {
      'dropdown__content--opened': _vm.isOpened
    }
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class='forum-modal'>
    <div class="forum-modal__container">
      <div class='forum-modal__close-link' role="button" @click="handleCloseModal">
        <svg height="512" viewBox="0 0 386.667 386.667" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/cross.svg'" v-bind:class="'forum-modal__close-icon'"><path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" fill="currentColor"/></svg>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('email', email)
extend('required', required)

export default {
  methods: {
    handleCloseModal () {
      this.$router.push('/')
    }
  },
  async mounted () {
    // проверка. если токен есть и авторизован, то на список. если нет, то на форму входа.
    // если роут полный то пропускаем
  },
  updated () {
    // console.log(this.signInForm.errors().all());
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.forum-modal {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  overflow: hidden auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__close-link {
    cursor: pointer;
    height: 24px;
    padding: 4px;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
  }

  &__close-icon {
    color: $primary;
    display: block;
    position: relative;
    height: 100%;
    transition: opacity 0.2s;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }

  &__container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    height: 100%;
    padding: 16px;
    position: relative;
    width: 100%;
  }

  @include mq-m {
    &__container {
      border: 2px solid $gray-dark;
      padding: 54px 36px;
      width: 396px;
      height: auto;
    }

    &__close-link {
      right: 8px;
      top: 8px;
    }
  }
}
</style>

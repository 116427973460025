import PagesApi from '@/api/pages-api'
import { Status } from '@/shared/constants/load-status'

export const PagesActions = {
  actionGetCurrentPage: 'actionGetCurrentPage'
}

const state = () => ({
  // пока не используется
  pagesList: [],
  // пока не используется
  pagesData: {},
  currentPage: {},
  currentPageStatus: Status.initial
})

const getters = {
  getCurrentPage (state) {
    return (id) => state.currentPage[id] || null
  },
  getCurrentLoadingStatus (state) {
    return state.currentPageStatus
  },
  getPagesList (state) {
    return state.pagesList
  },
  getPagesData (state) {
    return state.pagesData
  }
}

const actions = {
  [PagesActions.actionGetCurrentPage] ({ commit }, { id }) {
    commit('setCurrentPageStatus', Status.loading)

    PagesApi
      .getPage(id)
      .then((response) => {
        if (!('data' in response)) {
          throw response
        }

        commit('setCurrentPage', response.data)
        commit('setCurrentPageStatus', Status.success)
      })
      .catch((e) => {
        commit('setCurrentPageStatus', Status.error)
        throw e
      })
  }
}

const mutations = {
  setCurrentPage (state, item) {
    state.currentPage = { ...state.currentPage, [item.id]: item }
  },
  setCurrentPageStatus (state, status) {
    state.currentPageStatus = status
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="fake-content">
    <discussion-form
      :breadcrumbs="[
        {
          label: getTranslate.forum.forum,
          route: Routes.ForumTopicList
        }, {
          label: getTranslate.forum.createTopic,
          route: ''
        }
      ]"
      :loading="loading"
      :description="description"
      :title="title"
      @change-title="value => this.title = value"
      @change-description="value => this.description = value"
      @submit="handleSubmit"
      :button-text="getTranslate.forum.createTopic"
      :permissions="permissions.createDiscussions"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import discussionApi from '@/api/discussion-api'
import DiscussionForm from '@/components/DiscussionForm'
import { getPermissions } from '@/shared/helpers'
import { Routes, RouterName } from '@/shared/constants/routes'

export default {
  data: () => ({
    Routes,
    RouterName,
    title: '',
    description: '',
    // перенести логику в vuex
    loading: false
  }),
  name: 'CreateTopic',
  components: { DiscussionForm },
  computed: {
    ...mapGetters(['getTranslate']),
    ...mapGetters('auth', ['getSelf', 'getACL']),
    permissions () {
      return this.getPermissions(this.getACL, this.getSelf, ['createDiscussions'])
    }
  },
  methods: {
    getPermissions,
    handleSubmit () {
      this.loading = true
      discussionApi.createDiscussion({ title: this.title, description: this.description })
        .then((response) => {
          this.loading = false
          if (response && response.id) {
            this.$router.push({ name: this.RouterName.ForumTopicView, params: { topicId: response.id }})
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

var render = function () {
  var _vm$getTranslate;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$getTranslate = _vm.getTranslate) !== null && _vm$getTranslate !== void 0 && _vm$getTranslate.forum ? _c('div', {
    staticClass: "discussion-form"
  }, [_c('breadcrumbs', {
    attrs: {
      "itemList": _vm.breadcrumbs,
      "wrapperClass": "topic-list__breadcrumbs"
    }
  }), _c('div', {
    staticClass: "discussion-form__content"
  }, [_c('form', {
    staticClass: "discussion-form__form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('forum-input', {
    staticClass: "discussion-form__input",
    attrs: {
      "placeholder": _vm.getTranslate.forum.topicName,
      "value": _vm.title
    },
    on: {
      "input": _vm.changeTitle
    }
  }), _c('forum-input', {
    staticClass: "discussion-form__input",
    attrs: {
      "placeholder": _vm.getTranslate.forum.topicDescription,
      "value": _vm.description
    },
    on: {
      "input": _vm.changeDescription
    }
  }), _c('button', {
    staticClass: "forum-button discussion-form__button",
    class: {
      'forum-button_loading': _vm.loading
    },
    attrs: {
      "disabled": !_vm.title || !_vm.description || _vm.loading || !_vm.permissions,
      "type": "submit"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])], 1)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
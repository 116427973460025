var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "editor-wrapper"
  }, [_c('quill-editor', {
    ref: "myTextEditor",
    staticClass: "editor",
    class: _vm.isComment ? 'comment' : null,
    attrs: {
      "value": _vm.content,
      "options": _vm.editorOption
    },
    on: {
      "change": _vm.onEditorChange
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
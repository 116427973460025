<template>
  <div class="search-page">
    <!--  SKELETON было бы здорово  -->
    <spinner-container :loading="getSearch.loading">
      <div class="search-page__section">
        <p style="text-align: left;">
          {{ getTranslate.common.searchPhrase }} <b>{{value}}</b>
        </p>

        <div v-if="getSearch.result.length">
          <section >
            <a
              :key="item.url"
              :href="item.url"
              class="search-item"
              v-for="item in getSearch.result"
            >
              <span :title="item.title" class="search-item__title">
                {{item.title}}
              </span>
              <div v-html="item.snippet" class="search-item__description" />
            </a>
          </section>
          <pagination v-model="getSearch.meta.current_page" :per-page="20" :records="getSearch.meta.total || 0" @paginate="handlePaginate"/>
        </div>
        <h1 v-else class="search-page__empty-search">
          {{getTranslate.search.emptyResult}}
        </h1>
      </div>
    </spinner-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SpinnerContainer from '@/components/SpinnerContainer'
import { Pagination } from 'vue-pagination-2'

export default {
  components: { SpinnerContainer, Pagination },
  data: () => ({
    value: ''
  }),
  name: 'SearchPage',
  methods: {
    ...mapActions(['search', 'clearSearch']),
    handlePaginate () {
      this.search({ page: this.getSearch.result.meta.current_page + 1, value: this.value })
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getSearch']),
    ...mapGetters(['menuData'])
  },
  watch: {
    '$route.query.q': function (data) {
      this.value = data
      this.search({ page: 1, value: data })
    }
  },
  async beforeMount () {
    const { q } = this.$router.currentRoute.query
    this.value = q

    // remove defaultStore and uncomment this line
    // this.clearSearch()
    this.search({ page: 1, value: q })
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';
@import "@/shared/style/media.scss";
@import '@/shared/style/variables.scss';

.search-page {
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include mq-m {
    margin-top: 60px;
  }

  &__section {
    margin: 2rem 1rem 1rem;

    @include mq-m {
      max-width: 846px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq-l {
      max-width: 970px;
    }

    @include mq-xl {
      max-width: 970px;
    }

    @include mq-xxl {
      max-width: 1440px;
    }
  }

  &__empty-search {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
  }
}

.search-item {
  background-color: rgba($primary-2, 0.08);
  border-radius: 5px;
  display: block;
  font-family: $lucidaSansUnicode;
  margin: 15px 0;
  padding: 10px 15px;
  text-align: left;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba($primary-2, 0.3);
  }

  &__title {
    font-size: 1.5rem;
    color: $black;
    text-overflow: ellipsis;
  }

  &__description {
    color: $gray-dark;
    font-size: 1.1rem;
    margin-top: 10px;
  }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fixators"
  }, [_c('iframe', {
    staticClass: "capture",
    attrs: {
      "width": "360",
      "height": "220",
      "src": "https://www.youtube.com/embed/mY20Oz80zho",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  }), _c('h2', {
    staticClass: "main-title"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.mainTitle))]), _c('div', {
    staticClass: "sub-title sub-title_helvetica-italic"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.mainSubTitle))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.skelet
    }
  }), _c('div', {
    staticClass: "fixators__vertical-block"
  }, [_c('img', {
    staticClass: "capture middle",
    attrs: {
      "src": _vm.Images.fixGoldVertical
    }
  }), _c('div', {
    staticClass: "trio"
  }, [_c('div', [_c('img', {
    staticClass: "capture small",
    attrs: {
      "src": _vm.Images.firstRent
    }
  }), _c('div', {
    staticClass: "small-image-title sub-title_helvetica-italic"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.imageSubTitle1))])]), _c('div', [_c('img', {
    staticClass: "capture small",
    attrs: {
      "src": _vm.Images.secondRent
    }
  }), _c('div', {
    staticClass: "small-image-title sub-title_helvetica-italic"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.imageSubTitle2))])]), _c('div', [_c('img', {
    staticClass: "capture small",
    attrs: {
      "src": _vm.Images.thirdRent
    }
  }), _c('div', {
    staticClass: "small-image-title sub-title_helvetica-italic"
  }, [_vm._v(_vm._s(_vm.getTranslate.clamps.imageSubTitle3))])])])]), _c('div', {
    staticClass: "blue-canvas"
  }, [_c('div', {
    staticClass: "blue-block"
  }, [_c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.mini)
    }
  }), _c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.constructive)
    }
  })]), _c('div', {
    staticClass: "blue-block"
  }, [_c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.bio)
    }
  }), _c('div', {
    staticClass: "white-text",
    domProps: {
      "innerHTML": _vm._s(_vm.getTranslate.clamps.osteo)
    }
  })])]), _vm._l(_vm.menuChildren, function (menu) {
    return _c('div', {
      key: menu.id,
      staticClass: "w_full"
    }, [_c('div', {
      staticClass: "mobile-page-title"
    }, [_c('h4', {
      staticClass: "main-title main-title_left p-l_standard",
      attrs: {
        "id": menu.name,
        "title": menu.name
      }
    }, [_vm._v(_vm._s(menu.name))]), _c('broken-line')], 1), _c('div', {
      staticClass: "fixators__content-wrapper"
    }, [!!menu.catalog_link ? _c('button', {
      staticClass: "download-catalog-btn m-t_1 m-b_1",
      on: {
        "click": function ($event) {
          return _vm.buttonClick(menu.catalog_link);
        }
      }
    }, [_vm._v(_vm._s(_vm.getTranslate.clamps.downloadCatalog))]) : _vm._e(), _vm._l(_vm.getMenuPages(menu.id), function (item) {
      return _c('Instrument', {
        key: item.id,
        staticClass: "preview",
        attrs: {
          "instrument": item,
          "link": {
            name: _vm.RouterName.CurrentInstrument,
            params: {
              id: item.id
            }
          }
        }
      });
    })], 2)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-developing__content page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _c('div', {
    staticClass: "desktop-developing__inner-content"
  }, [_c('div', {
    staticClass: "desktop-developing__left-side"
  }, [_c('img', {
    staticClass: "capture desktop-developing__left",
    attrs: {
      "alt": "",
      "src": _vm.Images.bake
    }
  }), _c('img', {
    staticClass: "capture desktop-developing__left-bottom-image",
    attrs: {
      "alt": "",
      "src": _vm.Images.room
    }
  })]), _c('div', {
    staticClass: "desktop-developing__right-side"
  }, [_c('h2', {
    staticClass: "desktop-developing__sub-title",
    attrs: {
      "id": _vm.getTranslate.products.prodmainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.products.prodmainTitle))]), _c('p', {
    staticClass: "simple m-b_1",
    attrs: {
      "id": _vm.getTranslate.products.prod
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.products.prod))]), _c('span', {
    staticClass: "simple simple_left special simple_top-offset-md",
    attrs: {
      "id": _vm.getTranslate.products.typesSpecial
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.products.typesSpecial))]), _vm._l(_vm.getTranslate.products.items, function (item) {
    return _c('div', {
      key: item,
      staticClass: "simple simple_left simple_top-offset-sm",
      attrs: {
        "id": item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _c('div', {
    staticClass: "right-img-wrapper"
  }, [_c('img', {
    staticClass: "capture desktop-developing__right",
    attrs: {
      "alt": "",
      "src": _vm.Images.micro
    }
  })])], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class='products'>
    <div class="mobile-page-title">
      <h2 class="p-l_standard main-title main-title_left">{{getPageTitle($router.currentRoute.path)}}</h2>
      <broken-line />
    </div>
    <div class='faded-bot m-t_2'>
      <RouterLink :to="Routes.Additive">
        <FadedCap :bottomLayer='Images.hipBoneBlack' :topLayer='Images.hipBone'/>
      </RouterLink>
      <RouterLink :to="Routes.Instruments">
        <FadedCap :bottomLayer='Images.scissorsBlack' :topLayer='Images.scissors'/>
      </RouterLink>
      <RouterLink :to="Routes.Prostheses">
        <FadedCap :bottomLayer='Images.headBlack' :topLayer='Images.head'/>
      </RouterLink>
    </div>
    <div class='faded-bot'>
      <RouterLink :to="Routes.Clamps">
        <FadedCap :bottomLayer='Images.mechanismBlack' :topLayer='Images.mechanism'/>
      </RouterLink>
      <RouterLink :to="Routes.Coverings">
      <FadedCap :bottomLayer='Images.kneeBlack' :topLayer='Images.knee'/>
    </RouterLink>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import FadedCap from '@/components/FadedCap'
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Products'
import { Routes } from '@/shared/constants/routes'

export default {
  components: { BrokenLine, FadedCap },
  data () {
    return {
      Images,
      Routes
    }
  },
  computed: {
    ...mapGetters(['getPageTitle'])
  }
}
</script>

<style lang="scss" scoped>
.m-t_2 {
  margin-top: 2rem;
}

.products {
  align-items: center;
  display: flex;
  flex-direction: column;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-researches__content page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(" " + _vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)) + " ")]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _c('div', {
    staticClass: "desktop-researches__content-inner"
  }, _vm._l(_vm.menuPages, function (_ref) {
    var id = _ref.id,
        images = _ref.images,
        title = _ref.title,
        content = _ref.content,
        external_url = _ref.external_url;
    return _c('div', {
      key: id,
      staticClass: "desktop-researches__post"
    }, [!!images.length ? _c('img', {
      staticClass: "research_img",
      attrs: {
        "src": images[0]
      }
    }) : _vm._e(), _c('h4', {
      staticClass: "desktop-researches__post-title"
    }, [_vm._v(" " + _vm._s(title) + " ")]), _c('span', {
      staticClass: "simple simple_left f-d_c",
      domProps: {
        "innerHTML": _vm._s(content)
      }
    }), external_url ? _c('a', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "href": external_url
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslate.researches.detail) + " ")]) : _vm._e()]);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
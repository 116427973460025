import Vue from 'vue'
import Vuex from 'vuex'
import discussion from '@/store/discussion'
import post from '@/store/post'
import comment from '@/store/comment'
import menu from '@/store/menu'
import pages from '@/store/pages'
import auth from '@/store/auth'
import {isMobileDevice } from '@/shared/helpers'
import {localize} from "vee-validate";
import CommonApi from "@/api/common-api";
import languages, { LangMapa, langKey } from '@/shared/constants/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
    updateLocale (state, translations) {
      state.locale = translations
    },
    updateLang (state, lang) {
      state.lang = lang
    },
    updateUserAgent (state, userAgent) {
      state.userAgent = userAgent
    },
    updateOpenModal (state, isOpen) {
      state.isOpenModal = isOpen
    },
    updateModalChild (state, child) {
      state.modalChild = child
    },
    setSearchResult(state, result) {
      state.search.result = result
    },
    setSearchLoading(state, loading) {
      state.search.loading = loading
    },
    clearSearch(state) {
      state.search.result = []
    },
    updateItem (state, item) {
      state.currentItem = item
    }
  },
  actions: {
    async loadTranslation ({ dispatch, commit }) {
      const localLang = localStorage.getItem(langKey) && LangMapa[localStorage.getItem(langKey)] ? localStorage.getItem(langKey): languages.russian

      await dispatch('changeLang', localLang)
    },
    async changeLang ({ commit, state }, lang) {
      const translations = await import(`@/shared/locale/${lang}/index`);
      const formLocale = await import(`vee-validate/dist/locale/${lang}.json`);
      localize(lang, formLocale)
      localStorage.setItem(langKey, lang)
      commit('updateLang', lang)
      commit('updateLocale', translations)
    },

    async identifyUserAgent ({ commit }) {
        commit('updateUserAgent', isMobileDevice(navigator.userAgent) ? 'mobile': 'desktop')
    },

    clearSearch({commit}) {
      commit('clearSearch')
    },
    async search ({commit}, {value, page} = {offset: 0}) {
      commit('setSearchLoading', true)

      return CommonApi.search({q: value, page}).then(res => {
        if (res && res.data) {
          commit('setSearchResult', res.data)
        }
      })
        .finally(() => {
          commit('setSearchLoading', false)
        })
    },

    changOpenModal (context, child) {
      if (child) {
        context.commit('updateOpenModal', true)
        context.commit('updateModalChild', child)
      } else {
        context.commit('updateOpenModal', false)
      }
    },

    changeCurrentItem (context, item) {
      context.commit('updateItem', item)
    },

    actionSearch({commit}, value) {

    }
  },
  state: {
    locale: [],
    lang: localStorage.getItem(langKey),
    userAgent: 'desktop',
    isOpenModal: false,
    modalChild: null,
    search: {
      result: [],
      loading: false,
      meta: {
        current_page: 1,
        total: 0,
        per_page: 20
      }
    },
    currentItem: {
      images: [],
      description: ''
    }
  },
  getters: {
    getTranslate (state) {
      return state.locale
    },
    getModal (state) {
      return state.isOpenModal
    },
    getModalChild (state) {
      return state.modalChild
    },
    getSearch (state) {
      return state.search
    },

    getAgent (state) {
      return state.userAgent
    },
    getItem (state) {
      return state.currentItem
    },
    getLang(state) {
      return state.lang
    }
  },
  modules: {
    auth,
    discussion,
    post,
    comment,
    menu,
    pages
  }
})

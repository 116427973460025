<template>
  <div class="participants" v-if="getTranslate.forum">
    <Breadcrumbs :itemList="[{label: getTranslate.forum.forum, route: Routes.ForumTopicList}, {label: getTranslate.forum.participants, route: ''}]" />
    <spinner-container :loading="getUserListLoading">
      <div class="participants__content">
        <div class="participants__item" v-for="({surname, middlename, job, appointment, id, name}, index) in getUserList.data" :key="id">
            <router-link :to="{ name: RouterName.ForumCurrentParticipant, params: { userId: id }}" class="participants__content-item" v-if="!name && !middlename && !surname && !job && !appointment">{{++index}}. {{getTranslate.forum.emptyProfile}}</router-link>
            <div class="participants__content-item" v-else>
              <router-link :to="{ name: RouterName.ForumCurrentParticipant, params: { userId: id }}">{{index + 1}}. {{name || middlename || surname ? getUserName(surname, name, middlename): getTranslate.forum.emptyProfile}}</router-link>
              <div v-if="job || appointment" class="participants__job-info">{{job && appointment ? ` ${job}   ●   ` : job}} {{appointment}}</div>
            </div>
        </div>
      </div>
      <pagination v-model="getUserList.meta.current_page" :per-page="10" :records="getUserList.meta?.total || 0" @paginate="handlePaginate"/>
    </spinner-container>
  </div>
</template>

<script>
import { Pagination } from 'vue-pagination-2'
import Breadcrumbs from '@/components/Breadcrumbs'
import { getUserName } from '@/shared/helpers'
import { mapActions, mapGetters } from 'vuex'
import SpinnerContainer from '@/components/SpinnerContainer'
import { Routes, RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return { Routes, RouterName }
  },
  name: 'Participants',
  components: { Breadcrumbs, Pagination, SpinnerContainer },
  methods: {
    ...mapActions('auth', ['actionGetUserList']),
    getUserName,
    handlePaginate (page) {
      this.actionGetUserList({ offset: page * 20 })
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserList', 'getUserListLoading']),
    ...mapGetters(['getTranslate'])
  },
  beforeMount () {
    this.actionGetUserList()
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.participants {
  margin: 24px auto 0;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    margin-top: 1rem;
  }

  &__item {
    border: 2px solid $gray-dark;
    margin-bottom: 18px;
  }

  &__content-item {
    align-items: flex-start;
    color: $black;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 32px;
  }

  &__job-info {
    color: $gray-dark;
    padding-left: 18px;
    font-weight: normal;
    font-size: 14px;
    margin-top: 8px;
  }

  @include mq-l {
    max-width: 972px;

    &__content {
      margin-top: 40px;
    }
  }

}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "services"
  }, [_c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_left p-l_standard",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(" " + _vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)) + " ")]), _c('broken-line'), _c('div', {
    staticClass: "m-t_1 t-a_i",
    domProps: {
      "innerHTML": _vm._s(_vm.menuPage.content)
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
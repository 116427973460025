import { BaseApi } from '@/api/base-api'

class CommonApi extends BaseApi {
  // eslint-disable-next-line camelcase
  search ({ q, per_page = 15, page = 1 }) {
    return this.get('/api/search', { query: { q, per_page, page }})
  }
}

export default new CommonApi()

<template>
  <div>
    <h3 class="new-password__title">{{getTranslate.forum.newPasswordTitle}}</h3>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <form class="new-password__form" @submit.prevent="handleSubmit">
        <ValidationProvider name="password" rules="required" v-slot="{ errors }">
          <input
            class="new-password__input"
            :class="{'new-password__input_error': errors[0]}"
            :disabled="submitting"
            v-model="password"
            type="password"
            :placeholder="getTranslate.forum.pass"
          />
          <Error size="small" :error="errors[0]" />
        </ValidationProvider>
        <ValidationProvider name="passwordConfirmation" rules="required|password:@password" v-slot="{ errors }">
          <input
            class="new-password__input"
            :class="{'new-password__input_error': errors[0]}"
            :disabled="submitting"
            v-model="passwordConfirmation"
            type="password"
            :placeholder="getTranslate.forum.passwordConfirmation"
          />
          <Error size="small" :error="errors[0]" />
        </ValidationProvider>
        <Error size="small" :error="commonError" />
        <button :title="getTranslate.forum.newPasswordSubmitBtn" :class="{'forum-button_loading': submitting}" class="forum-button new-password__submit-btn" type="submit" :disabled="invalid || submitting">
          {{getTranslate.forum.newPasswordSubmitBtn}}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Error from '@/views/Error'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { Routes } from '@/shared/constants/routes'

export default {
  data () {
    return {
      Routes,
      commonError: '',
      submitting: false,
      password: '',
      passwordConfirmation: ''
    }
  },
  components: { Error, ValidationObserver, ValidationProvider },
  computed: mapGetters(['getTranslate']),
  methods: {
    ...mapActions('auth', ['actionNewPassword', 'actionGetSelf', 'actionGetACL']),
    handleSubmit () {
      const { email, token } = this.$router.currentRoute.query
      this.submitting = true
      this.commonError = ''

      this.actionNewPassword({
        token,
        email,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      }).then(async (response) => {
        const { message, errors } = response

        if (message) {
          this.commonError = message
          this.$refs.form.setErrors(errors)
        } else {
          await this.actionGetSelf()
          await this.actionGetACL()
          this.$router.push(this.Routes.ForumTopicList)
        }
        this.submitting = false
      })
    }
  },
  watch: {
    'getTranslate.forum': (forum) => {
      extend('required', {
        ...required,
        message: forum.formRequired
      })
      extend('password', {
        params: ['target'],
        validate (value, { target }) {
          return value === target
        },
        message: forum.formPasswordMatch
      })
    }
  },
  name: 'ForumNewPassword'
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.new-password {
  &__input {
    border-width: 0;
    border-bottom: 2px solid $primary;
    font-weight: bold;
    height: 40px;
    margin: 10px auto;
    max-width: 150px;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: $black;
    }

    &:focus {
      outline: 0;
      border-width: 0;
      border-bottom: 2px solid $primary;
    }

    &_error {
      border-bottom-color: $red;
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 150px;
    margin: 0 auto;
    text-align: left;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__submit-btn {
    max-width: 150px;
    margin: 25px 0;
  }

  @include mq-m {
    &__title {
      font-size: 2rem;
    }
  }
}
</style>

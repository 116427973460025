import { BaseApi } from '@/api/base-api'

class CommentApi extends BaseApi {
  /**
   * Получить список комментариев
   * @param id Number айди поста
   * @param query Object {per_page: Number, page: Number}
   * @returns {Promise<any|undefined>}
   */
  listGet (id, query) {
    return this.get(`/api/posts/${id}/comments`, { query })
  }

  /**
   * Создание комментария
   * @param id Number айди поста
   * @param body Object {title: String, content: String}
   * @returns {Promise<any|undefined>}
   */
  createComment (id, body) {
    return this.post(`/api/posts/${id}/comments`, { body })
  }

  /**
   * Получение комментария по айди
   * @param id Number айди комментария
   * @returns {Promise<any|undefined>}
   */
  getComment (id) {
    return this.get(`/api/comments/${id}`)
  }

  /**
   * Удаление комментария
   * @param id Number айди комментария
   * @returns {Promise<any|undefined>}
   */
  deleteComment (id) {
    return this.delete(`/api/comments/${id}`)
  }

  /**
   * Обновление комментария
   * @param id айди комментария
   * @param body Object {title: String, content: String}
   * @returns {Promise<any|undefined>}
   */
  update (id, body) {
    return this.put(`/api/comments/${id}`, { body })
  }
}

export default new CommentApi()

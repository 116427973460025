var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home",
    attrs: {
      "id": "home"
    }
  }, [_c('div', {
    staticClass: "faded-top"
  }, [_c('a', {
    attrs: {
      "href": "#additive-technologies",
      "data-to": "#additive-technologies"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.hipBoneBlack,
      "topLayer": _vm.homeImages.hipBone
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#scissors",
      "data-to": "#scissors"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.scissorsBlack,
      "topLayer": _vm.homeImages.scissors
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#endoprosthesis",
      "data-to": "#endoprosthesis"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.headBlack,
      "topLayer": _vm.homeImages.head
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#hip-bone",
      "data-to": "#hip-bone"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.kneeBlack,
      "topLayer": _vm.homeImages.knee
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#mechanism",
      "data-to": "#mechanism"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.mechanismBlack,
      "topLayer": _vm.homeImages.mechanism
    }
  })], 1)]), _c('div', {
    staticClass: "page-content"
  }, [_c('h2', {
    staticClass: "main-title mb70",
    attrs: {
      "id": _vm.getTranslate.home.techno
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.techno))]), _c('div', {
    staticClass: "row",
    attrs: {
      "id": "mechanism"
    }
  }, [_c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.plecho
    }
  })], 1), _c('div', {
    staticClass: "part part_left"
  }, [_c('span', {
    staticClass: "main-title main-title_left m-l_1",
    attrs: {
      "id": _vm.getTranslate.home.fiksators
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Clamps
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.fiksators) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_left m-l_1",
    attrs: {
      "id": _vm.getTranslate.home.miniInvazive
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.miniInvazive))])])]), _c('span', {
    staticClass: "main-title main-title_left main-title_smaller max-w_35 home__fracture-title",
    attrs: {
      "id": _vm.getTranslate.home.fracture
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.fracture))]), _c('div', {
    staticClass: "row m-t_1"
  }, [_c('div', {
    staticClass: "part"
  }, [_c('div', {
    staticClass: "home__ul"
  }, [_c('span', {
    staticClass: "sub-title sub-title_left"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.fractureOperation1) + " ")]), _c('span', {
    staticClass: "sub-title sub-title_left"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.fractureOperation2) + " ")])])]), _c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "sub-title sub-title_left max-w_50 m-l_3",
    attrs: {
      "id": _vm.getTranslate.home.operation
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.operation))]), _c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.fracture
    }
  })], 1)]), _c('broken-line', {
    attrs: {
      "className": "desktop__line",
      "rtl": ""
    }
  }), _c('div', {
    staticClass: "row",
    attrs: {
      "id": "scissors"
    }
  }, [_c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.wireCutters
    }
  })], 1), _c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left m-l_1",
    attrs: {
      "id": _vm.getTranslate.home.surgeryInstrumentHead
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Instruments
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.surgeryInstrumentHead) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_left m-l_1"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentHeadDesc))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "part"
  }, [_c('h3', {
    staticClass: "main-title main-title_left main-title_smaller m-b_1 max-w_90",
    attrs: {
      "id": _vm.getTranslate.home.surgeryInstrumentTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentTitleDesc))]), _c('span', {
    staticClass: "sub-title sub-title_left"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentTitleMadeOf))])]), _c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.whistle
    }
  })], 1)]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _c('div', {
    staticClass: "row",
    attrs: {
      "id": "additive-technologies"
    }
  }, [_c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.additive
    }
  })], 1), _c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.home.additiveHead
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Additive
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.additiveHead) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_left max-w_65",
    attrs: {
      "id": _vm.getTranslate.home.additiveSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.additiveSubtitle))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left main-title_smaller m-b_1 max-w_75",
    attrs: {
      "id": _vm.getTranslate.home.additiveTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.additiveTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.directions))])]), _c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.tail
    }
  })], 1)]), _c('broken-line', {
    attrs: {
      "className": "desktop__line",
      "rtl": ""
    }
  }), _c('div', {
    staticClass: "row m-b_1",
    attrs: {
      "id": "endoprosthesis"
    }
  }, [_c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.whiteHead
    }
  })], 1), _c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left m-b_1",
    attrs: {
      "id": _vm.getTranslate.home.endoProtesisMainTitle
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.RouterName.ProsthesisList,
        params: {
          id: 'stm'
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.endoProtesisMainTitle) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.endoProtesisMainSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.endoProtesisMainSubtitle))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left main-title_smaller m-b_1",
    attrs: {
      "id": _vm.getTranslate.home.stmMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.stmSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle))]), _c('span', {
    staticClass: "sub-title sub-title_left m-t_0"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle1))]), _c('span', {
    staticClass: "sub-title sub-title_left m-t_0"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle2))]), _c('span', {
    staticClass: "sub-title sub-title_left m-t_0"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle3))])]), _c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.balls
    }
  })], 1)]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _c('div', {
    staticClass: "row",
    attrs: {
      "id": "hip-bone"
    }
  }, [_c('div', {
    staticClass: "part"
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.glitter
    }
  })], 1), _c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left max-w_80",
    attrs: {
      "id": _vm.getTranslate.home.pharaonMainTitle
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Prostheses
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.pharaonMainTitle) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.pharaonSubTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaonSubTitle))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "part"
  }, [_c('span', {
    staticClass: "main-title main-title_left main-title_smaller",
    attrs: {
      "id": _vm.getTranslate.home.pharaonTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaonTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.pharaonSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaonSubtitle))]), _c('span', {
    staticClass: "sub-title sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.pharaon
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaon))])]), _c('div', {
    staticClass: "part"
  })])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
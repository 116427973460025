var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "coverings"
  }, [_vm.getPageTitle(_vm.$router.currentRoute.path) ? _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('span', {
    staticClass: "main-title main-title_left p-l_standard",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path),
      "title": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1) : _vm._e(), !!_vm.getTranslate.coverings ? _c('div', {
    staticClass: "coverings__content p-l_standard p-r_standard"
  }, [_c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.coverings.bioMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.bioMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_left sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.coverings.bioSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.bioSubtitle))]), _c('span', {
    staticClass: "simple m-t_1 t-a_l",
    attrs: {
      "id": _vm.getTranslate.coverings.creation
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.creation))]), _c('span', {
    staticClass: "simple t-a_l",
    attrs: {
      "id": _vm.getTranslate.coverings.creation2
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.creation2))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.nano
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.coverings.nanoSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.nanoSub))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.micro
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.coverings.microSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.microSub))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.chese
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.coverings.cheseSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.cheseSub))]), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.clouds
    }
  }), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic",
    attrs: {
      "id": _vm.getTranslate.coverings.cloudSub
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.coverings.cloudSub))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-wrapper"
  }, [_c('img', {
    staticClass: "top",
    attrs: {
      "src": _vm.topLayer,
      "alt": ""
    }
  }), _c('img', {
    staticClass: "bottom",
    attrs: {
      "src": _vm.bottomLayer,
      "alt": ""
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <div class="mobile-page-title" v-if="getPageTitle($router.currentRoute.path)">
            <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)" :title="getPageTitle($router.currentRoute.path)" >{{getPageTitle($router.currentRoute.path)}}</h2>
            <broken-line />
        </div>

        <div class="vacancy_item" v-for="item in menuPages" :key="item.id">
            <h2 class="main-title main-title_left m-b_1">{{item.title}}</h2>
            <div class="vacancy_text t-a_i">
                {{item.description}}
            </div>
            <div class="vacancy_text t-a_i m-t_1" v-html="item.content"></div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace(/[/]/ig, '')) || null
    }
  }
}
</script>

<style lang="scss" scoped>
.vacancy_item {
    padding: 0 0.8rem;
}

.vacancy_text {
    font-size: 1rem;
    line-height: 1.8rem;
    white-space: pre-wrap;
}

.main-title {
    margin-bottom: 24px;
    font-size: 1.4rem;
}
</style>

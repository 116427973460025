var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "products"
  }, [_c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_left p-l_standard"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1), _c('div', {
    staticClass: "products__content"
  }, [_c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.bake
    }
  }), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.room
    }
  }), _c('div', {
    staticClass: "products__company-name light-title light-title_left ",
    attrs: {
      "id": _vm.getTranslate.products.prodmainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.products.prodmainTitle))]), _c('div', {
    staticClass: "simple products__doing simple_left",
    attrs: {
      "id": _vm.getTranslate.products.prod
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.products.prod))]), _c('div', {
    staticClass: "products__list-title special simple_left",
    attrs: {
      "id": _vm.getTranslate.products.typesSpecial
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.products.typesSpecial))]), _vm._l(_vm.getTranslate.products.items, function (item) {
    return _c('div', {
      key: item,
      staticClass: "simple simple_left",
      attrs: {
        "id": item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _c('img', {
    staticClass: "capture",
    attrs: {
      "src": _vm.Images.micro
    }
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
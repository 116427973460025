<template>
  <router-link :to="link" class='instrument' :class="className">
    <div class='instrument__id'>{{instrument.slug}}</div>
    <img :alt="instrument.title || instrument.name" class='instrument__img' :src='instrument.images?.[0] || defaultImage' />
    <div class='instrument__bottom-block'>
      <div class='instrument__name' :title="instrument.title || instrument.name">{{instrument.title || instrument.name}}</div>
    </div>
 </router-link>
</template>

<script>
import commonImages from '@/shared/Images/Common'

export default {
  data () {
    return {
      defaultImage: commonImages.emptyImage
    }
  },
  name: 'instrument',
  props: {
    className: String,
    instrument: Object,
    link: Object
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';

.instrument {
  box-shadow: 1px 1px 4px rgba(128, 128, 128, 0.836), -1px 1px 4px rgba(128, 128, 128, 0.726);
  overflow: hidden;
  display: block;
  position: relative;

  &__img {
    width: 100%;
    object-fit: contain;
  }

  &__bottom-block {
      align-items: center;
      background-color: $primary-2;
      bottom: 0;
      transform: skew(30deg);
      position: absolute;
      right: 10px;
      height: 2.2rem;
      display: flex;
      overflow: hidden;
      width: 100%;
  }

  &__id {
      width: 100%;
      text-align: start;
      padding: 1rem 0 0 1rem;
      font-weight: bold;
      text-transform: uppercase;
  }

  &__name {
    color: white;
    font-size: 0.9rem;
    transform: skew(-30deg);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 18px;
  }
}
</style>

<template>
  <div class='mobile-home'>
    <div class="mobile-home__content">
      <div class='faded'>
        <div class='faded-top'>
          <a href="#additive-technologies" data-to="#additive-technologies" @click="handleClick">
            <FadedCap  :bottomLayer='homeImages.hipBoneBlack'
                     :topLayer='homeImages.hipBone'/>
          </a>
          <a href="#scissors" data-to="#scissors" @click="handleClick">
            <FadedCap  :bottomLayer='homeImages.scissorsBlack'
                     :topLayer='homeImages.scissors'/>
          </a>
          <a href="#endoprosthesis" data-to="#endoprosthesis" @click="handleClick">
            <FadedCap  :bottomLayer='homeImages.headBlack'
                     :topLayer='homeImages.head'/>
          </a>
        </div>
        <div class='faded-bot'>

          <a href="#mechanism" data-to="#mechanism" @click="handleClick">
            <FadedCap  :bottomLayer='homeImages.mechanismBlack'
                     :topLayer='homeImages.mechanism'/>
          </a>
          <a href="#hip-bone" data-to="#hip-bone" @click="handleClick">
            <FadedCap  :bottomLayer='homeImages.kneeBlack'
                     :topLayer='homeImages.knee'/>
          </a>
        </div>
        <span class='main-title' id="mechanism" >{{getTranslate.home.techno}}</span>
        <RoundCap className="mobile-home__image" imageClass="w_full" :img='homeImages.plecho' />
        <span class='main-title main-title_left' :id="getTranslate.home.fiksators" >
          <router-link :to="Routes.Clamps">
            {{getTranslate.home.fiksators}}
          </router-link>
        </span>
        <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.miniInvazive">{{getTranslate.home.miniInvazive}}</span>
        <span class='main-title main-title_left max-w_90' :id="getTranslate.home.fracture">{{getTranslate.home.fracture}}</span>
<!--        <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.operationTitle">{{getTranslate.home.operationTitle}}</span> -->
        <span class='sub-title sub-title_helvetica-italic sub-title_left max-w_50' :id="getTranslate.home.operation">{{getTranslate.home.operation}}</span>
        <RoundCap imageClass="w_full" :img='homeImages.fracture' />
        <ul class="mobile-home__ul">
          <li class="simple m-t_1 sub-title sub-title_helvetica-italic t-a_l">
            {{getTranslate.home.fractureOperation1}}
          </li>
          <li class="simple m-t_1 sub-title sub-title_helvetica-italic t-a_l">
<!--          mobile-home__li  -->
            {{getTranslate.home.fractureOperation2}}
          </li>
<!--          <li class="mobile-home__li"> -->
<!--            {{getTranslate.home.fractureOperation3}} -->
<!--          </li> -->
<!--          <li class="mobile-home__li"> -->
<!--            {{getTranslate.home.fractureOperation4}} -->
<!--          </li> -->
        </ul>
      </div>
    </div>
    <div class="mobile-home__right-line">
      <broken-line rtl />
    </div>
    <div class="mobile-home__content" id="scissors">
      <span class='main-title main-title_left max-w_90' :id="getTranslate.home.surgeryInstrumentHead">
        <router-link :to="Routes.Instruments">
          {{getTranslate.home.surgeryInstrumentHead}}
        </router-link>
      </span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left'>{{getTranslate.home.surgeryInstrumentHeadDesc}}</span>
      <RoundCap  imageClass="w_full" :img='homeImages.wireCutters' />
      <span class='main-title main-title_smaller main-title_left max-w_80' :id="getTranslate.home.surgeryInstrumentTitle">{{getTranslate.home.surgeryInstrumentTitle}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.surgeryInstrumentSubtitle">{{getTranslate.home.surgeryInstrumentSubtitle}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left'>{{getTranslate.home.surgeryInstrumentTitleDesc}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left'>{{getTranslate.home.surgeryInstrumentTitleMadeOf}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.oNpk">{{getTranslate.home.oNpk}}</span>
      <RoundCap imageClass="w_full" :img='homeImages.whistle' />
    </div>
    <div class="mobile-page-title">
      <broken-line />
    </div>
    <div class="mobile-home__content" id="additive-technologies">
      <RoundCap imageClass="w_full" :img='homeImages.additive' />
      <span class='main-title main-title_left' :id="getTranslate.home.additiveHead">
        <router-link :to="Routes.Additive">
          {{getTranslate.home.additiveHead}}
        </router-link>
      </span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.additiveSubtitle">{{getTranslate.home.additiveSubtitle}}</span>
      <span class='main-title main-title_smaller main-title_left' :id="getTranslate.home.additiveTitle">{{getTranslate.home.additiveTitle}}</span>
<!--      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.additiveDir">{{getTranslate.home.additiveDir}}</span> -->
      <span class='simple m-t_1 sub-title sub-title_helvetica-italic t-a_l' :id="getTranslate.home.directions ">{{getTranslate.home.directions}}</span>
      <!-- <RoundCap imageClass="w_full" :img='homeImages.tail' /> -->
      <RoundCap imageClass="w_full" :img='homeImages.tail' />
    </div>
    <div class="mobile-home__right-line">
      <broken-line rtl />
    </div>
    <div class="mobile-home__content" id="endoprosthesis">
      <RoundCap imageClass="w_full" :img='homeImages.whiteHead' />
      <span class='main-title main-title_left max-w_85' :id="getTranslate.home.endoProtesisMainTitle">
        <router-link :to="{ name: RouterName.ProsthesisList, params: { id: 'stm' } }">
          {{getTranslate.home.endoProtesisMainTitle}}
        </router-link>
      </span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.endoProtesisMainSubtitle">{{getTranslate.home.endoProtesisMainSubtitle}}</span>
      <span class='main-title main-title_smaller main-title_left' :id="getTranslate.home.stmMainTitle">{{getTranslate.home.stmMainTitle}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.stmSubtitle">{{getTranslate.home.stmSubtitle}}</span>
          <span class="sub-title sub-title_helvetica-italic sub-title_left m-t_0">{{getTranslate.home.stmSubtitle1}}</span>
          <span class="sub-title sub-title_helvetica-italic sub-title_left m-t_0">{{getTranslate.home.stmSubtitle2}}</span>
          <span class="sub-title sub-title_helvetica-italic sub-title_left m-t_0">{{getTranslate.home.stmSubtitle3}}</span>
      <RoundCap imageClass="w_full" :img='homeImages.balls' />
    </div>
    <div class="mobile-page-title">
      <broken-line />
    </div>
    <div class="mobile-home__content" id="hip-bone">
      <RoundCap imageClass="w_full" :img='homeImages.glitter' />
      <span class='main-title main-title_left max-w_85' :id="getTranslate.home.pharaonMainTitle">
        <router-link :to="Routes.Prostheses">
          {{getTranslate.home.pharaonMainTitle}}
        </router-link>
      </span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.pharaonSubTitle">{{getTranslate.home.pharaonSubTitle}}</span>
      <span class='main-title main-title_smaller main-title_left' :id="getTranslate.home.pharaonTitle">{{getTranslate.home.pharaonTitle}}</span>
      <span class='sub-title sub-title_helvetica-italic sub-title_left' :id="getTranslate.home.pharaonSubtitle">{{getTranslate.home.pharaonSubtitle}}</span>
      <span class='simple m-t_1 sub-title sub-title_helvetica-italic t-a_l'  :id="getTranslate.home.pharaon">{{getTranslate.home.pharaon}}</span>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import RoundCap from '@/components/RoundCap'
import FadedCap from '@/components/FadedCap'
import { mapGetters } from 'vuex'
import { homeImages } from '@/shared/Images/Home'
import { Routes, RouterName } from '@/shared/constants/routes'
import zenscroll from 'zenscroll'

const HEADER_HEIGHT = 140
const DURATION = 500
export default {
  components: { BrokenLine, FadedCap, RoundCap },
  data () {
    return {
      Routes,
      RouterName,
      homeImages
    }
  },
  computed: mapGetters(['getTranslate']),
  methods: {
    // плавный скролл
    scrollTo (anchorId) {
      const anchor = document.querySelector(anchorId)

      if (anchor) {
        this.$myScroller.to(anchor)
      }
    },
    handleClick (e) {
      const { dataset: { to }} = e.currentTarget
      this.scrollTo(to)
    }
  },
  mounted () {
    const appContainer = document.getElementById('app')
    this.$myScroller = zenscroll.createScroller(appContainer, DURATION, HEADER_HEIGHT)

    if (this.$router.currentRoute.hash) {
      setTimeout(() => {
        this.scrollTo(this.$router.currentRoute.hash)
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-t_0{
  margin-top: 0;
}
.t-a_l{
  text-align: left;
}
.mobile-home {
  width: 100%;
  padding: 2rem 0 0;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/

  &__right-line {
    padding: 0 2rem 0 0.8rem;
  }

  &__content {
    padding: 0 0.8rem;
  }
  &__image {
    margin-top: 2rem;
  }

  &__inner-image {
    width: 100%;
  }

  &__ul {
    margin-inline-start: 0;
    padding-inline-start: 0;
  }

  &__li {
    font-family: 'Open sans', sans-serif;
    text-align: left;
    font-size: 1rem;
  }

  &__instrument-image {
    padding: 1rem 0.8rem 0;
  }
}

.faded {
  width: 100%;
}

</style>


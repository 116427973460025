<template>
  <div class="error-component">
    <h1>
      {{title}}
    </h1>
    <p>
      {{content || getTranslate.common.pageNotFound}}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    content: String,
    title: {
      default: '404',
      type: String
    }
  },
  computed: mapGetters(['getTranslate']),
  name: 'ErrorComponent'
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.error-component {
  color: $primary;
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>

<template>
  <spinner-container :loading="!getTranslate || getPost.loading || getDiscussionList.data.loading">
    <div class="view-post" v-if="getTranslate.forum && getPost.data && getPost.data.discussion">
      <div class="view-post__main">
        <breadcrumbs :itemList="[
            {label: getTranslate.forum.forum, route: Routes.ForumTopicList},
            {label: getPost.data.discussion.title, route: {name: RouterName.ForumTopicView, params: {topicId: getPost.data.discussion.id}}},
            {label: getPost.data.title, route: ''}
          ]"
          wrapperClass="topic-list__breadcrumbs"
        />

        <h1 class="view-post__title">
          {{getPost.data.title}}
        </h1>
        <div class="view-post__content">
          <div class="output ql-snow">
            <div class="ql-editor" v-html="getPost.data.content"></div>
          </div>
          <button class="view-post__comment-btn prevent-button-styles" @click="handleCreateNewComment">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:class="'view-post__comment-icon'" v-bind:src="'@/shared/Images/Forum/comment.svg'"><path d="M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z" fill="currentColor"/></svg>
            {{getTranslate.forum.btnComment}}
          </button>
          <div class="view-post__comment-block" v-if="getCommentList.length">
            <div :key="id" :id="`comment-${id}`" v-for="({author, content, id}) in getCommentList" class="comment">
              <div class="comment__row">
                <div class="comment__username">
                  <router-link :to="{name: RouterName.ForumCurrentParticipant, params: {userId: author.id}}">
                    {{author.name}}
                  </router-link>
                </div>
                <button :title="getTranslate.forum.deleteComment" v-if="author.id === getSelf?.id || permissions.deleteComments" class="comment__delete-btn prevent-button-styles" @click="handleDeleteComment(id)">
                  <svg height="512" viewBox="0 0 386.667 386.667" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/cross.svg'"><path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" fill="currentColor"/></svg>
                </button>
              </div>
              <div v-html="content" />
              <button class="comment__answer-btn prevent-button-styles" :data-id="id" :data-label="author.name" @click="handleCreateAnswer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:class="'view-post__comment-icon'" v-bind:src="'@/shared/Images/Forum/comment.svg'"><path d="M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z" fill="currentColor"/></svg>
                {{getTranslate.forum.btnAnswer}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="view-post__sidebar">
        <div class="sidebar">
          <router-link v-if="permissions.updatePosts || (getSelf?.id && getPost.data?.creatorId === getSelf?.id)" class="forum-button m-b_1 sidebar__delete-link" :to="{ name: RouterName.ForumPostEdit, params: { topicId: getPost.data.discussion.id, postId: getPost.data.id }}">{{ getTranslate.forum.editPost }}</router-link>
          <button v-if="permissions.deletePosts || (getSelf?.id && getPost.data?.creatorId === getSelf?.id)" class="warning-button m-b_1" :class="{'warning-button_loading': getPost.deletePostLoading}" :disabled="getPost.deletePostLoading" @click="handleDeletePost">{{ getTranslate.forum.deletePost }}</button>
          <button class="forum-button" :disabled="getPost.data.isSubscribed || getPost.subscribePostLoading" :class="{'forum-button_loading': getPost.subscribePostLoading}" @click="actionPostSubscribe(getPost.data.id)">{{getPost.data.isSubscribed ? getTranslate.forum.buttonYouAreFollowPost: getTranslate.forum.buttonFollowPost }}</button>
          <div class="sidebar__statistic-block">
            <div class="sidebar__row">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 511.999 511.999" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/eye.svg'" v-bind:class="'sidebar__icon'"><path d="M508.129 245.942c-1.155-1.278-28.809-31.661-73.431-62.307C375.034 142.659 313.241 121 255.999 121c-57.241 0-119.034 21.659-178.698 62.635-44.622 30.646-72.275 61.029-73.43 62.307-5.162 5.712-5.162 14.404 0 20.115 1.155 1.278 28.809 31.661 73.431 62.307C136.965 369.341 198.759 391 255.999 391c57.241 0 119.034-21.659 178.698-62.635 44.622-30.646 72.275-61.029 73.431-62.307 5.161-5.712 5.161-14.404.001-20.116zM145.484 333.44c-40.404-19.392-78.937-47.81-109.449-77.434 17.675-17.131 57.684-52.53 109.471-77.478C130.074 200.472 121 227.195 121 256c-.001 28.791 9.064 55.502 24.484 77.44zM255.999 361c-57.848 0-105-47.06-105-105 0-57.897 47.103-105 105-105 57.854 0 105 47.066 105 105 .001 57.897-47.102 105-105 105zm110.495-27.528C381.926 311.528 391 284.805 391 256c0-28.791-9.065-55.502-24.484-77.44 40.404 19.392 78.937 47.81 109.449 77.434-17.674 17.131-57.684 52.53-109.471 77.478z" fill="currentColor"/><path d="M255.999 181c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75c.001-41.355-33.644-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.186 45-45 45z" fill="currentColor"/></svg>
              {{getTranslate.forum.viewCountLabel}} {{getPost.data.views}}
            </div>
            <div class="sidebar__row">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:class="'sidebar__icon'" v-bind:src="'@/shared/Images/Forum/comment.svg'"><path d="M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z" fill="currentColor"/></svg>
              {{getTranslate.forum.commentCountLabel}} {{getPost.data.comments}}
            </div>
            <div class="sidebar__row">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:class="'sidebar__icon'" v-bind:src="'@/shared/Images/Forum/comment.svg'"><path d="M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1z" fill="currentColor"/></svg>
              {{getTranslate.forum.answerCountLabel}} {{0}}
            </div>
          </div>
          <div class="sidebar__statistic-block">
            <div class="sidebar__row">
              {{getTranslate.forum.topicList}}
            </div>
            <router-link class="sidebar__row" :key="id" v-for="{id, title} in getDiscussionList.data.list.slice(0, 3)" :to="{name: RouterName.ForumTopicView, params: {topicId: id}}">{{title}}</router-link>
            <router-link class="sidebar__row sidebar__show-all-topic" :to="Routes.ForumTopicList">{{getTranslate.forum.viewAllTopicList}}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!getPost.loading && !getPost.data?.id">
      <ErrorComponent title="404" :content="getTranslate.common.pageNotFound" />
    </div>
  </spinner-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs'
import dedent from 'dedent'
import { getPermissions, getUserName } from '@/shared/helpers'
import EditorComment from '@/components/editor-comment'
import SpinnerContainer from '@/components/SpinnerContainer'
import Vue from 'vue'
import ErrorComponent from '@/views/ErrorComponent'
import { Routes, RouterName } from '@/shared/constants/routes'

// todo запрашивать список разделов
export default {
  data () {
    return {
      Routes,
      RouterName,
      parentId: null,
      editorInstance: null,
      openEditor: false,
      editorContent: ''
    }
  },
  name: 'ViewPost',
  components: {
    ErrorComponent,
    // eslint-disable-next-line vue/no-unused-components
    EditorComment,
    Breadcrumbs,
    SpinnerContainer
  },
  computed: {
    ...mapGetters('auth', ['getACL', 'getSelf']),
    ...mapGetters(['getTranslate', 'getPost']),
    ...mapGetters('discussion', ['getDiscussionList']),
    ...mapGetters('comment', ['getCommentList']),
    permissions () {
      return this.getPermissions(this.getACL, this.getSelf, ['deleteComments', 'deletePosts', 'updatePosts'])
    }
  },
  methods: {
    ...mapActions('comment', ['actionCreateComment', 'actionGetCommentList', 'actionUpdateComment', 'actionDeleteComment', 'actionClearComments']),
    ...mapActions(['actionPostSubscribe', 'actionGetPost', 'actionDeletePost', 'actionClearPostData']),
    ...mapActions('discussion', ['actionGetDiscussionList']),
    getUserName,
    getPermissions,
    handleOpenEditor () {
      this.openEditor = true
    },
    handleDeletePost () {
      const discussionId = this.getPost.data.discussion.id
      this.actionDeletePost(this.getPost.data.id).then((response) => {
        this.$router.push({ name: this.RouterName.ForumTopicView, params: { topicId: discussionId }})
      })
    },
    handleDeleteComment (id) {
      this.actionDeleteComment(id)
      this.handleCloseComment()
    },
    handleCreateNewComment (e) {
      if (this.editorInstance) {
        return
      }
      const editor = document.createElement('div')
      editor.setAttribute('id', 'editor-comment')
      e.currentTarget.after(editor)

      this.editorInstance = new this.EditorContentComponent({
        propsData: {
          content: this.editorContent,
          onChange: this.handleChangeComment,
          onClose: this.handleCloseComment,
          onSave: this.handleSaveComment,
          isOpen: true,
          closeBtn: this.getTranslate.forum.commentCloseBtn,
          submitBtn: this.getTranslate.forum.commentSubmitBtn
        }
      }).$mount('#editor-comment')
    },
    handleCreateAnswer (e) {
      const parentId = e.currentTarget.getAttribute('data-id')
      if (this.editorInstance && this.parentId === parentId) {
        return
      }
      this.parentId = parentId
      this.handleCloseComment()

      const editor = document.createElement('div')
      editor.setAttribute('id', 'editor-comment')
      e.currentTarget.parentNode.after(editor)

      const user = e.currentTarget.getAttribute('data-label')
      const userName = user || this.getTranslate.forum.guest
      this.editorContent = `${userName}, `

      this.editorInstance = new this.EditorContentComponent({
        propsData: {
          content: this.editorContent,
          onChange: this.handleChangeComment,
          onClose: this.handleCloseComment,
          onSave: this.handleSaveComment,
          isOpen: true,
          closeBtn: this.getTranslate.forum.commentCloseBtn,
          submitBtn: this.getTranslate.forum.commentSubmitBtn
        }
      }).$mount('#editor-comment')
    },
    handleSaveComment () {
      this.editorInstance.loading = true
      this.actionCreateComment({
        postId: this.getPost.data.id,
        body: {
          parent_id: this.parentId ? Number(this.parentId) : null,
          content: this.editorContent
        }
      })
        .then(this.handleCloseComment)
    },
    handleCloseComment () {
      this.openEditor = false
      this.editorContent = ''
      if (this.editorInstance) {
        this.editorInstance.$destroy()
        this.editorInstance.$el.remove()
        this.editorInstance.loading = false
      }
      this.editorInstance = null
    },
    handleChangeComment (e) {
      this.editorContent = e
      this.editorInstance.content = this.editorContent
    }
  },
  async beforeMount () {
    const { postId } = this.$route.params
    this.actionGetDiscussionList({ page: 1, perPage: 3 })
    this.actionGetPost({ postId })
    this.actionGetCommentList({ postId })
    this.EditorContentComponent = Vue.extend(EditorComment)
  },
  beforeDestroy () {
    this.actionClearPostData()
    this.actionClearComments()
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.view-post {
  font-family: 'Open sans', sans-serif;
  height: 100%;
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-height: 400px;

  @include mq-m {
    flex-direction: row;
    width: calc(846px + 16px + 16px);
  }

  @include mq-l {
    width: calc(970px + 16px + 16px);
  }

  @include mq-xl {
    width: calc(1280px + 16px + 16px);
  }

  @include mq-xxl {
    width: calc(1440px + 16px + 16px);
  }

  &__main {
    flex-shrink: 0;
    width: 100%;

    @include mq-m {
      max-width: calc(100% - 228px);
    }
  }

  &__sidebar {
    display: flex;
    padding-top: 72px;
    // width: 100%;
    position: relative;

    @include mq-m {
      margin-left: 32px;
    }
  }

  &__content {
    margin-top: 32px;
  }

  &__title {
    font-family: 'Open sans', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin-top: 24px;
    text-align: left;
  }

  &__description {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2rem;
    margin: 24px auto 0;
    max-width: 500px;
  }

  &__post {
    border: 1px solid $black;
    margin-bottom: 16px;
    padding: 16px 24px;
  }

  &__link {
    color: $primary;
    font-size: 1.2rem;
  }

  &__post-row {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__td {
    &:nth-child(1) {
      flex: 1 1 75%;
      text-align: left;
    }

    &:nth-child(2) {
      flex: 1 1 50px;
    }

    &:nth-child(3) {
      flex: 1 1 50px;
    }

    &:nth-child(4) {
      flex: 1 1 50px;

    }

    &:nth-child(5) {
      flex: 1 1 15%;
      text-align: left;
      padding-left: 16px;
    }

  }

  &__post-icon {
    color: $gray-dark;
    height: 18px;
    margin: 0 14px;
    max-width: 18px;
    width: 18px;
  }

  &__author {
    font-size: 1.2rem;
  }

  &__activity {
    font-size: 1.2rem;
  }

  &__comment-icon {
    margin-right: 6px;
    height: 14px;
    width: 14px;
  }

  &__comment-btn {
    cursor: pointer;
    margin: 32px 0;
    align-items: center;
    display: flex;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq-m {
    position: sticky;
    width: 228px;
  }

  &__delete-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__row {
    font-family: 'Open sans', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    color: $gray-dark;
    height: 18px;
    margin-right: 8px;
    width: 18px;
  }

  &__statistic-block {
    border: 2px solid $gray-dark;
    padding: 16px;
    margin-top: 16px;
  }

  &__show-all-topic {
    color: $primary;
    transition: color 0.2s;

    &:hover {
      color: rgba($primary, 0.6);
    }
  }
}

.comment {
  padding: 16px 16px 16px 0;
  border-bottom: 2px solid $gray;
  text-align: left;

  @media (hover: hover) {
    &:hover {
      .comment {
        &__delete-btn {
          color: rgba($red, 0.6);
        }
      }
    }
  }

  &:first-child {
    border-top: 2px solid $gray;
  }

  button {
    @media (hover: hover) {
      &.comment__delete-btn:hover {
        color: $red;
      }
    }
  }

  &__row {
    display: flex;
  }

  &__username {
    flex-basis: 100%;
  }

  &__delete-btn {
    cursor: pointer;
    flex-basis: 30px;
    color: $red;
    transition: color 0.2s;

    @media (hover: hover) {
      color: transparent;
    }

    svg {
      height: 16px;
      outline: none;
      width: 16px;
    }
  }

  &__answer-btn {
    cursor: pointer;
    padding: 4px 4px 4px 0;
    align-items: center;
    display: flex;
  }
}

</style>

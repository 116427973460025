var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('Header'), _vm.getModal ? _c('div', {
    staticClass: "modal-wrapper"
  }, [_c('Modal')], 1) : _vm._e(), _c('div', {
    staticClass: "mobile-content-wrapper"
  }, [_c('spinner-container', {
    attrs: {
      "loading": _vm.showLoading
    }
  }, [_c('router-view', {
    staticClass: "mobile-content-inner"
  })], 1), _c('Footer')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
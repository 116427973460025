var render = function () {
  var _vm$instrument$images;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "instrument",
    class: _vm.className,
    attrs: {
      "to": _vm.link
    }
  }, [_c('div', {
    staticClass: "instrument__id"
  }, [_vm._v(_vm._s(_vm.instrument.slug))]), _c('img', {
    staticClass: "instrument__img",
    attrs: {
      "alt": _vm.instrument.title || _vm.instrument.name,
      "src": ((_vm$instrument$images = _vm.instrument.images) === null || _vm$instrument$images === void 0 ? void 0 : _vm$instrument$images[0]) || _vm.defaultImage
    }
  }), _c('div', {
    staticClass: "instrument__bottom-block"
  }, [_c('div', {
    staticClass: "instrument__name",
    attrs: {
      "title": _vm.instrument.title || _vm.instrument.name
    }
  }, [_vm._v(_vm._s(_vm.instrument.title || _vm.instrument.name))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
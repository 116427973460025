var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editor-comment__wrapper"
  }, [_c('editor', {
    attrs: {
      "show": _vm.isOpen,
      "isComment": true,
      "content": _vm.content
    },
    on: {
      "change": _vm.onChange
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "editor-comment__btn-row-editor"
  }, [_c('div', {
    staticClass: "editor-comment__btn-column-editor"
  }, [_c('button', {
    staticClass: "forum-button editor-comment__save-btn",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.closeBtn) + " ")]), _c('button', {
    staticClass: "forum-button editor-comment__save-btn",
    class: {
      'forum-button_loading': _vm.loading
    },
    attrs: {
      "disabled": !_vm.content || _vm.loading
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" " + _vm._s(_vm.submitBtn) + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span v-if="error" class="error" :class="classList">{{error}}</span>
</template>

<script>
export default {
  name: 'Error',
  computed: {
    classList: ({ size }) => {
      return {
        [size]: size
      }
    }
  },
  props: {
    error: {
      default: '',
      type: String
    },
    size: {
      default: 'small',
      type: 'small' | 'medium' | 'large'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.error {
  color: $red;
}
</style>

import { Status } from '@/shared/constants/load-status'

const isLoading = (status) => Status.loading === status
const isSuccess = (status) => Status.success === status
const isError = (status) => Status.error === status

export {
  isLoading,
  isSuccess,
  isError
}

<template>
  <div class='proteses'>
    <div class="mobile-page-title" v-if="getPageTitle($router.currentRoute.path)">
      <h4 class='main-title main-title_left p-l_standard' :id="getPageTitle($router.currentRoute.path)" >{{getPageTitle($router.currentRoute.path)}}</h4>
      <BrokenLine/>
    </div>

    <div class="proteses__content">
      <instrument
        className="proteses__image-wrapper"
        :link="{ name: RouterName.ProsthesisList, params: { id: prosthesisGroup.id } }"
        :instrument="prosthesisGroup"
        v-for="prosthesisGroup in menuPages"
        :key="prosthesisGroup.id"
        :id="prosthesisGroup.id"
      />
    </div>
  </div>
</template>

<script>
import Instrument from '@/components/Instrument'
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'
import { RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      RouterName
    }
  },
  components: { Instrument, BrokenLine },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuChildren']),
    menuPages () {
      return this.getMenuChildren(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
.proteses {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    padding: 1rem 2rem;
  }

  &__image-wrapper {
    margin-bottom: 0.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-container",
    class: {
      'spinner-container_loading': _vm.loading
    },
    style: {
      'min-height': _vm.height
    }
  }, [_c('spinner-loader', {
    attrs: {
      "loading": _vm.loading,
      "className": "spinner-container__loader"
    }
  }), _c('div', {
    staticClass: "spinner-container__content"
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$getTranslate$foru, _vm$getTranslate$comm;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header__wrapper"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "header__logo-image",
    attrs: {
      "src": _vm.Images.logo,
      "alt": "capture"
    }
  })]), _c('button', {
    staticClass: "header__profile-btn",
    on: {
      "click": _vm.handleLogin
    }
  }, [_vm.show ? _c('svg', {
    class: 'header__profile-svg',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50",
      "svg-inline": '',
      "src": '@/shared/Images/Common/profile.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0025 50a24.881 24.881 0 0016.069-5.861.126.126 0 01.003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 001.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0",
      "fill": "currentColor"
    }
  })]) : _vm._e(), _vm._v(" " + _vm._s(_vm.getSelf ? _vm.getSelf.name : (_vm$getTranslate$foru = _vm.getTranslate.forum) === null || _vm$getTranslate$foru === void 0 ? void 0 : _vm$getTranslate$foru.login) + " ")]), _c('div', {
    on: {
      "click": function ($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_c('spinner-loader', {
    attrs: {
      "size": "sm",
      "loading": _vm.isLoading(_vm.getMenuStatus)
    }
  }), !_vm.show && !_vm.isLoading(_vm.getMenuStatus) ? _c('img', {
    staticClass: "header__hamburger",
    attrs: {
      "src": _vm.Images.burger
    }
  }) : _vm._e(), _vm.show && !_vm.isLoading(_vm.getMenuStatus) ? _c('svg', {
    class: 'header__header-icon',
    attrs: {
      "height": "512",
      "viewBox": "0 0 386.667 386.667",
      "width": "512",
      "xmlns": "http://www.w3.org/2000/svg",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/cross.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z",
      "fill": "currentColor"
    }
  })]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "search"
  }, [_c('form', {
    staticClass: "search-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSearchRedirect.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.toFind,
      expression: "toFind"
    }],
    staticClass: "search-input",
    attrs: {
      "placeholder": (_vm$getTranslate$comm = _vm.getTranslate.common) === null || _vm$getTranslate$comm === void 0 ? void 0 : _vm$getTranslate$comm.placeholder
    },
    domProps: {
      "value": _vm.toFind
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.toFind = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "languages"
  }, _vm._l(_vm.langs, function (flag) {
    return _c('img', {
      key: flag,
      staticClass: "languages-flag",
      class: {
        'languages-flag-active': flag === _vm.getLang
      },
      attrs: {
        "src": _vm.Images.languages[flag]
      },
      on: {
        "click": function ($event) {
          return _vm.changeLanguage(flag);
        }
      }
    });
  }), 0)])]), _c('nav', {
    staticClass: "nav",
    class: {
      nav__opened: _vm.show
    },
    attrs: {
      "id": "nav"
    }
  }, [_c('div', {
    staticClass: "nav__content"
  }, _vm._l(_vm.mobileMenuItems, function (_ref) {
    var name = _ref.name,
        url = _ref.url,
        id = _ref.id;
    return _c('div', {
      key: id,
      staticClass: "header__nav-link",
      on: {
        "click": function ($event) {
          _vm.show = !_vm.show;
        }
      }
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": url,
        "exact": true,
        "exact-active-class": "active-link"
      }
    }, [_vm._v(" " + _vm._s(name) + " ")])], 1);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
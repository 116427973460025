var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-wrapper",
    class: _vm.className
  }, [_c('img', {
    staticClass: "image",
    class: _vm.imageClass,
    attrs: {
      "src": _vm.img,
      "alt": "picture"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }
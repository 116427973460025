import mainBack from './mainBack.jpg'
import rainbow from './rainbow.jpg'
import secondRent from './secondRent.jpg'
import firstRent from './firstRent.jpg'
import thirdRent from './thirdRent.jpg'
import skelet from './skelet.jpg'
import fixGold from './fixGold.jpg'
import fixGoldVertical from './fixGoldVertical.png'

export const Images = {
  mainBack: mainBack,
  rainbow,
  secondRent,
  firstRent,
  thirdRent,
  skelet,
  fixGold,
  fixGoldVertical
}

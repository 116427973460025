<template>
    <div class='desktop-vacancies__content page-content'>
        <h1 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)" >{{ getPageTitle($router.currentRoute.path) }}</h1>
        <broken-line className="desktop__line" />

        <div class="vacancy_item" v-for="item in menuPages" :key="item.id">
            <div class="vacancy_column">
                <h2 class="main-title main-title_left m-b_1">{{item.title}}</h2>
                <div class="vacancy_text">
                    {{item.description}}
                </div>
            </div>
            <div class="vacancy_column">
                <div class="vacancy_text t-a_i" v-html="item.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace(/[/]/ig, '')) || null
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-vacancies {
    &__content {
        flex: 1;
    }
}

li {
    margin-left: 20px;
    list-style-type: disc;
    list-style-position: outside;
}

.vacancy_item {
    display: flex;
    margin: 0 -20px 35px -20px;
}
.vacancy_column {
    width: 50%;
    padding: 0 20px;
}
.vacancy_text{
    text-align: left;
    font-size: 1.1rem;
    line-height: 1.8rem;
    white-space: pre-wrap;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-content new"
  }, [_c('div', {
    staticClass: "news-title-wrapper"
  }, [_c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.Routes.News)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.Routes.News)))]), _c('broken-line')], 1), _c('div', {
    staticClass: "d_f m-t_1 p-t_1 p-b_1"
  }, [_c('router-link', {
    staticClass: "d_f news-button-back",
    attrs: {
      "to": _vm.Routes.News
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.common.back))])], 1), _vm.currentNews ? _c('div', [_c('h2', {
    staticClass: "main-title main-title_left"
  }, [_vm._v(_vm._s(_vm.currentNews.title))]), _c('span', {
    staticClass: "sub-title sub-title_left",
    domProps: {
      "innerHTML": _vm._s(_vm.currentNews.content)
    }
  }), _vm.currentNews.images.length ? _c('div', {
    staticClass: "image-gallery"
  }, _vm._l(_vm.currentNews.images, function (src) {
    return _c('div', {
      key: src,
      staticClass: "image-wrapper"
    }, [_c('img', {
      staticClass: "image-src",
      attrs: {
        "alt": "",
        "src": src
      }
    })]);
  }), 0) : _vm._e()]) : _vm.isError(_vm.getCurrentLoadingStatus) ? _c('div', [_c('error-component', {
    attrs: {
      "title": "404",
      "content": _vm.getTranslate.common.pageNotFound
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "researches"
  }, [_c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h1', {
    staticClass: "main-title main-title_left p-l_1"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1), _vm._l(_vm.menuPages, function (_ref) {
    var id = _ref.id,
        images = _ref.images,
        title = _ref.title,
        content = _ref.content,
        external_url = _ref.external_url;
    return _c('div', {
      key: id,
      staticClass: "researches__block p-l_1 p-r_1"
    }, [_c('h4', {
      staticClass: "light-title light-title_justify"
    }, [_vm._v(_vm._s(title))]), !!images.length ? _c('img', {
      staticClass: "research_img",
      attrs: {
        "src": images[0]
      }
    }) : _vm._e(), _c('span', {
      staticClass: "simple simple_left f-d_c m-t_1",
      domProps: {
        "innerHTML": _vm._s(content)
      }
    }), external_url ? _c('a', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "href": external_url
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslate.researches.detail) + " ")]) : _vm._e()]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="edit-post">
    <spinner-container :loading="getPost.loading">
      <div v-if="!getPost.loading && !!getPost.data?.title">
        <breadcrumbs
          wrapperClass="topic-list__breadcrumbs"
          :itemList="[
            {
              label: getTranslate.forum.forum,
              route: Routes.ForumTopicList
            },
            {
              label: getPost.data.discussion.title,
              route: {name: RouterName.ForumTopicView, params: {topicId: getPost.data.discussion.id}}
            },
            {
              label: getPost.data.title,
              route: {name: RouterName.ForumPostView, params: {topicId: getPost.data.discussion.id, postId: getPost.data.id}}
            }
          ]"
        />

        <div class="edit-post__content">
          <form @submit.prevent="handleSubmit">
            <div class="edit-post__field">
              <forum-input
                :value="title"
                :placeholder="getTranslate.forum.postName"
                @input="value => this.title = value"
              />
            </div>
            <div class="edit-post__field">
              <forum-input
                :value="description"
                :placeholder="getTranslate.forum.postDescription"
                @input="value => this.description = value"
              />
            </div>
            <editor :content="content" @change="value => this.content = value" :show="true" />
            <button class="forum-button edit-post__button"  :class="{'forum-button_loading': getPost.updateLoading}" :disabled="getPost.updateLoading" type="submit">{{getTranslate.forum.editPost}}</button>
          </form>
        </div>
      </div>
    </spinner-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SpinnerContainer from '@/components/SpinnerContainer'
import ForumInput from '@/components/ForumInput'
import Breadcrumbs from '@/components/Breadcrumbs'
import Editor from '@/components/Editor'
import { RouterName, Routes } from '@/shared/constants/routes'

export default {
  data () {
    return {
      Routes,
      RouterName,
      content: '',
      description: '',
      title: ''
    }
  },
  name: 'EditPost',
  components: { Breadcrumbs, Editor, ForumInput, SpinnerContainer },
  watch: {
    'getPost.data': function (data) {
      this.description = data.description
      this.title = data.title
      this.content = data.content
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getPost'])
  },
  methods: {
    ...mapActions(['actionUpdatePost']),
    handleSubmit () {
      this.actionUpdatePost({
        postId: this.getPost.data.id,
        body: {
          title: this.title,
          description: this.description,
          content: this.content
        }}).then((response) => {
        if (response) {
          this.$router.push({ name: this.RouterName.ForumPostView, params: { topicId: this.getPost.data.discussion.id, postId: this.getPost.data.id }})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/media.scss';

.edit-post {
  font-family: 'Open sans', sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;

  @include mq-m {
    width: calc(846px + 16px + 16px);
  }

  @include mq-l {
    width: calc(970px + 16px + 16px);
  }

  @include mq-xl {
    width: calc(1280px + 16px + 16px);
  }

  @include mq-xxl {
    width: calc(1440px + 16px + 16px);
  }

  &__content {
    margin-top: 32px;
  }

  &__field {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 20px;
  }
}
</style>

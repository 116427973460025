import logo from '../logo.png'
import burger from './burger.jpg'
import search from './search.jpg'
import ru from './ru.jpg'
import en from './uk.jpg'
import ge from './ge.jpg'
import wave from '../wave.svg'
import strip from '../stripLeft.jpg'
import shiny from './shiny.jpg'
import cross from '../cross.jpg'
import line from './line.svg'
import emptyImage from './empty-image.jpg'

const commonImages = {
  logo,
  burger,
  search,
  wave,
  strip,
  shiny,
  cross,
  line,
  languages: {
    'ru': ru,
    'en': en,
    'de': ge
  },
  emptyImage
}
export default commonImages

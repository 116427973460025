import DiscussionApi from '@/api/discussion-api'

const state = () => ({
  data: {
    list: [],
    meta: {},
    loading: false
  },
  discussion: {}
})

// getters
const getters = {
  getDiscussionList (state) {
    return {
      data: state.data
    }
  },
  getDiscussion (state) {
    return {
      discussion: state.discussion
    }
  }
}

// actions
const actions = {
  actionGetDiscussionList ({ commit }, { perPage = 20, page = 1 } = {}) {
    commit('setDiscussionListLoading', true)
    DiscussionApi
      .listGet({ query: { per_page: perPage, page }})
      .then((response) => {
        if ('data' in response) {
          commit('setDiscussionList', response)
        }
      })
      .finally(() => {
        commit('setDiscussionListLoading', false)
      })
  },
  actionDeleteDiscussion ({ commit }, id) {
    DiscussionApi.deleteDiscussion(id)
      .catch((e) => {
        console.error(e)
      })
  },
  actionGetDiscussion ({ commit }, { topicId } = {}) {
    DiscussionApi
      .getDiscussion(topicId)
      .then((response) => {
        commit('setDiscussion', response.data)
      })
  },
  actionDiscussionSubscribe ({ commit }, id) {
    DiscussionApi.subscribe(id)
      .then((response) => {
        if (!response.error) {
          commit('setSubscribe', true)
        }
      })
      .catch((e) => console.error(e))
  }
}

// mutations
const mutations = {
  setDiscussionListLoading (state, flag) {
    state.data.loading = flag
  },
  setDiscussionList (state, response) {
    state.data = {
      list: response.data,
      meta: response.meta
    }
  },
  setDiscussion (state, data) {
    state.discussion = data
  },
  setSubscribe (state, flag) {
    state.discussion.isSubscribe = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="spinner-loader" :class="{'spinner-loader_load': loading, [`spinner-loader_size_${size}`]: !!size, [className]: className}">
    <span class="spinner-loader__bounce1"></span>
    <span class="spinner-loader__bounce2"></span>
  </div>
</template>

<script>
export default {
  name: 'spinner-loader',
  props: {
    className: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>

<style lang="scss">
@keyframes bounce {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.spinner-loader {
  right: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  position: relative;
  display: none;

  &_load {
    display: inline-block;
    opacity: 1;
  }
  &_size {
    &_sm {
      height: 40px;
      width: 40px;
    }

    &_md {
      height: 60px;
      width: 60px;
    }
  }

  &__bounce2,
  &__bounce1 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #4172bb;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2.0s infinite ease-in-out;
  }

  &__bounce2 {
    animation-delay: -1.0s;
  }
}
</style>

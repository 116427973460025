import nano from './nano.jpg'
import micro from './micro.jpg'
import chese from './chese.jpg'
import clouds from './clouds.jpg'
import strip from '../stripLeft.jpg'

const Images = {
  nano: nano,
  micro: micro,
  chese: chese,
  clouds: clouds,
  strip: strip
}
export default Images

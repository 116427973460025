var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "proteses"
  }, [_vm.getPageTitle(_vm.$router.currentRoute.path) ? _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h4', {
    staticClass: "main-title main-title_left p-l_standard",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('BrokenLine')], 1) : _vm._e(), _c('div', {
    staticClass: "proteses__content"
  }, _vm._l(_vm.menuPages, function (prosthesisGroup) {
    return _c('instrument', {
      key: prosthesisGroup.id,
      attrs: {
        "className": "proteses__image-wrapper",
        "link": {
          name: _vm.RouterName.ProsthesisList,
          params: {
            id: prosthesisGroup.id
          }
        },
        "instrument": prosthesisGroup,
        "id": prosthesisGroup.id
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-home"
  }, [_c('div', {
    staticClass: "mobile-home__content"
  }, [_c('div', {
    staticClass: "faded"
  }, [_c('div', {
    staticClass: "faded-top"
  }, [_c('a', {
    attrs: {
      "href": "#additive-technologies",
      "data-to": "#additive-technologies"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.hipBoneBlack,
      "topLayer": _vm.homeImages.hipBone
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#scissors",
      "data-to": "#scissors"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.scissorsBlack,
      "topLayer": _vm.homeImages.scissors
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#endoprosthesis",
      "data-to": "#endoprosthesis"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.headBlack,
      "topLayer": _vm.homeImages.head
    }
  })], 1)]), _c('div', {
    staticClass: "faded-bot"
  }, [_c('a', {
    attrs: {
      "href": "#mechanism",
      "data-to": "#mechanism"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.mechanismBlack,
      "topLayer": _vm.homeImages.mechanism
    }
  })], 1), _c('a', {
    attrs: {
      "href": "#hip-bone",
      "data-to": "#hip-bone"
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.homeImages.kneeBlack,
      "topLayer": _vm.homeImages.knee
    }
  })], 1)]), _c('span', {
    staticClass: "main-title",
    attrs: {
      "id": "mechanism"
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.techno))]), _c('RoundCap', {
    attrs: {
      "className": "mobile-home__image",
      "imageClass": "w_full",
      "img": _vm.homeImages.plecho
    }
  }), _c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.home.fiksators
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Clamps
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.fiksators) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.miniInvazive
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.miniInvazive))]), _c('span', {
    staticClass: "main-title main-title_left max-w_90",
    attrs: {
      "id": _vm.getTranslate.home.fracture
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.fracture))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left max-w_50",
    attrs: {
      "id": _vm.getTranslate.home.operation
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.operation))]), _c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.fracture
    }
  }), _c('ul', {
    staticClass: "mobile-home__ul"
  }, [_c('li', {
    staticClass: "simple m-t_1 sub-title sub-title_helvetica-italic t-a_l"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.fractureOperation1) + " ")]), _c('li', {
    staticClass: "simple m-t_1 sub-title sub-title_helvetica-italic t-a_l"
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.fractureOperation2) + " ")])])], 1)]), _c('div', {
    staticClass: "mobile-home__right-line"
  }, [_c('broken-line', {
    attrs: {
      "rtl": ""
    }
  })], 1), _c('div', {
    staticClass: "mobile-home__content",
    attrs: {
      "id": "scissors"
    }
  }, [_c('span', {
    staticClass: "main-title main-title_left max-w_90",
    attrs: {
      "id": _vm.getTranslate.home.surgeryInstrumentHead
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Instruments
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.surgeryInstrumentHead) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentHeadDesc))]), _c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.wireCutters
    }
  }), _c('span', {
    staticClass: "main-title main-title_smaller main-title_left max-w_80",
    attrs: {
      "id": _vm.getTranslate.home.surgeryInstrumentTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentTitle))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.surgeryInstrumentSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentSubtitle))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentTitleDesc))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.surgeryInstrumentTitleMadeOf))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.oNpk
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.oNpk))]), _c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.whistle
    }
  })], 1), _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('broken-line')], 1), _c('div', {
    staticClass: "mobile-home__content",
    attrs: {
      "id": "additive-technologies"
    }
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.additive
    }
  }), _c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getTranslate.home.additiveHead
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Additive
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.additiveHead) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.additiveSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.additiveSubtitle))]), _c('span', {
    staticClass: "main-title main-title_smaller main-title_left",
    attrs: {
      "id": _vm.getTranslate.home.additiveTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.additiveTitle))]), _c('span', {
    staticClass: "simple m-t_1 sub-title sub-title_helvetica-italic t-a_l",
    attrs: {
      "id": _vm.getTranslate.home.directions
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.directions))]), _c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.tail
    }
  })], 1), _c('div', {
    staticClass: "mobile-home__right-line"
  }, [_c('broken-line', {
    attrs: {
      "rtl": ""
    }
  })], 1), _c('div', {
    staticClass: "mobile-home__content",
    attrs: {
      "id": "endoprosthesis"
    }
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.whiteHead
    }
  }), _c('span', {
    staticClass: "main-title main-title_left max-w_85",
    attrs: {
      "id": _vm.getTranslate.home.endoProtesisMainTitle
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.RouterName.ProsthesisList,
        params: {
          id: 'stm'
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.endoProtesisMainTitle) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.endoProtesisMainSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.endoProtesisMainSubtitle))]), _c('span', {
    staticClass: "main-title main-title_smaller main-title_left",
    attrs: {
      "id": _vm.getTranslate.home.stmMainTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmMainTitle))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.stmSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left m-t_0"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle1))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left m-t_0"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle2))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left m-t_0"
  }, [_vm._v(_vm._s(_vm.getTranslate.home.stmSubtitle3))]), _c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.balls
    }
  })], 1), _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('broken-line')], 1), _c('div', {
    staticClass: "mobile-home__content",
    attrs: {
      "id": "hip-bone"
    }
  }, [_c('RoundCap', {
    attrs: {
      "imageClass": "w_full",
      "img": _vm.homeImages.glitter
    }
  }), _c('span', {
    staticClass: "main-title main-title_left max-w_85",
    attrs: {
      "id": _vm.getTranslate.home.pharaonMainTitle
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.Routes.Prostheses
    }
  }, [_vm._v(" " + _vm._s(_vm.getTranslate.home.pharaonMainTitle) + " ")])], 1), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.pharaonSubTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaonSubTitle))]), _c('span', {
    staticClass: "main-title main-title_smaller main-title_left",
    attrs: {
      "id": _vm.getTranslate.home.pharaonTitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaonTitle))]), _c('span', {
    staticClass: "sub-title sub-title_helvetica-italic sub-title_left",
    attrs: {
      "id": _vm.getTranslate.home.pharaonSubtitle
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaonSubtitle))]), _c('span', {
    staticClass: "simple m-t_1 sub-title sub-title_helvetica-italic t-a_l",
    attrs: {
      "id": _vm.getTranslate.home.pharaon
    }
  }, [_vm._v(_vm._s(_vm.getTranslate.home.pharaon))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "documentation"
  }, [_c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h1', {
    staticClass: "main-title main-title_left p-l_standard"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1), _c('div', {
    staticClass: "documentation__content"
  }, _vm._l(_vm.menuPages, function (_ref) {
    var id = _ref.id,
        title = _ref.title,
        images = _ref.images;
    return _c('div', {
      key: id
    }, [_c('h4', {
      staticClass: "light-title documentation__sub-title"
    }, [_vm._v(_vm._s(title))]), _vm._l(images, function (image) {
      return _c('img', {
        key: image,
        staticClass: "capture",
        attrs: {
          "src": image,
          "alt": title
        }
      });
    })], 2);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
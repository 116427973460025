<template>
  <div class="fake-content">
    <discussion-form
      :breadcrumbs="breadcrumbs"
      :title="title"
      :loading="loading"
      :description="description"
      @change-title="changeTitle"
      @change-description="changeDescription"
      :button-text="getTranslate.forum.editSection"
      @submit="handleSubmit"
      :permissions="permissions.updateDiscussions"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import discussionApi from '@/api/discussion-api'
import DiscussionForm from '@/components/DiscussionForm'
import { getPermissions } from '@/shared/helpers'
import { Routes, RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      Routes,
      RouterName,
      discussionId: 0,
      loading: false,
      title: '',
      description: ''
    }
  },
  name: 'EditTopic',
  components: { DiscussionForm },
  watch: {
    getDiscussion: function (value) {
      this.description = value.discussion.description
      this.title = value.discussion.title
    }
  },
  computed: {
    ...mapGetters(['getTranslate']),
    ...mapGetters('discussion', ['getDiscussion']),
    ...mapGetters('auth', ['getSelf', 'getACL']),
    permissions () {
      return this.getPermissions(this.getACL, this.getSelf, ['updateDiscussions'])
    },
    breadcrumbs: function () {
      if (this.discussionId && !isNaN(this.discussionId) && this.getTranslate.forum) {
        return [
          {
            label: this.getTranslate.forum.forum,
            route: this.Routes.ForumTopicList
          },
          {
            label: this.getDiscussion.discussion.title,
            route: { name: this.RouterName.ForumTopicView, params: { topicId: this.discussionId }}
          },
          {
            label: this.getTranslate.forum.editTopic,
            route: ''
          }
        ]
      }
      return []
    }
  },

  methods: {
    ...mapActions('discussion', ['actionGetDiscussion']),
    getPermissions,
    changeDescription (value) {
      this.description = value
    },
    changeTitle (value) {
      this.title = value
    },
    handleSubmit () {
      this.loading = true
      discussionApi
        .update(this.getDiscussion.discussion.id, {
          title: this.title,
          description: this.description
        })
        .then((response) => {
          this.loading = false
          if (response && typeof response === 'boolean') {
            this.$router.push({ name: this.RouterName.ForumTopicView, params: { topicId: this.getDiscussion.discussion.id }})
          }
        })
    }
  },
  beforeMount () {
    this.discussionId = +this.$route.params.topicId

    this.actionGetDiscussion({ topicId: this.discussionId })
  }
}
</script>

<template>
  <div class='desktop-researches__content page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)">
      {{getPageTitle($router.currentRoute.path)}}
    </h2>
    <broken-line className="desktop__line" />

    <div class="desktop-researches__content-inner">
      <div class="desktop-researches__post" :key="id" v-for="({id, images, title, content, external_url}) in menuPages">
        <img v-if="!!images.length" :src='images[0]' class="research_img"/>
        <h4 class='desktop-researches__post-title'>
          {{title}}
        </h4>
        <span class='simple simple_left f-d_c' v-html="content"></span>
        <a v-if="external_url" :href="external_url" class="detail-btn m-t_1">
          {{getTranslate.researches.detail}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.desktop-researches {
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__content-inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__post {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding: 0 1rem;
    max-width: 33.3%;
    margin-top: 2rem;
  }

  &__post-title {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: justify;
  }
}
.research_img {
  object-fit: contain;
  margin-bottom: 2rem;
  width: 100%;
}
</style>

<template>
  <div class='image-wrapper'>
    <img class='top' :src='topLayer' alt="" />
    <img class='bottom' :src='bottomLayer' alt="" />
 </div>
</template>

<script>
export default {
  name: 'FadedCap',
  props: {
    topLayer: String,
    bottomLayer: String,
    width: Number,
    height: Number
  }
}
</script>

<style scoped lang="scss">
@import "@/shared/style/media.scss";

.image-wrapper {
  position: relative;
  height: 9rem;
  width: 7rem;

  &:hover {
    .bottom {
      opacity: 0.01;
    }
  }

  @include mq-m {
      width: 13rem;
      height: 17rem;
  }
}

.top {
   width: 7rem;
   height: 9rem;

   @include mq-m {
       width: 13rem;
       height: 17rem;
   }
}

.bottom {
  height: 9rem;
  left: 0;
  position: absolute;
  transition: 0.8s opacity;
  width: 7rem;

    /*&:hover {*/
    /*    opacity: 0.01;*/
    /*}*/

    @include mq-m {
        height: 17rem;
        width: 13rem;

        /*&:hover {*/
        /*    opacity: 0.01;*/
        /*}*/
    }
}
</style>

<template>
  <div class='proteses page-content'>
    <h1 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)" >{{ getPageTitle($router.currentRoute.path) }}</h1>
    <broken-line class-name="desktop__line" />

    <div class="proteses__content" v-if="menuPages">
      <div class='preview' v-for="prosthesisGroup in menuPages" :key='prosthesisGroup.id' :id="prosthesisGroup.id">
          <instrument :instrument="prosthesisGroup" :link="{ name: RouterName.ProsthesisList, params: { id: prosthesisGroup.id } }" />
      </div>
    </div>
  </div>
</template>

<script>
import Instrument from '@/desktop/components/Instrument'
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      RouterName
    }
  },
  components: { BrokenLine, Instrument },
  computed: {
    ...mapGetters(['getPageTitle', 'getPageId', 'getMenuChildren']),
    menuPages () {
      return this.getMenuChildren(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/media.scss';

.proteses {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }
}
.preview {
  flex: 1 1 100%;
  margin: 0.2rem 0;
  max-width: 296px;

  @include mq-m {
    max-width: 364px;
  }

  @include mq-l {
    max-width: 316px;
  };

  @include mq-xl {
    max-width: 420px;
    margin-bottom: 25px;
  };
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getTranslate.forum ? _c('div', [_c('h3', {
    staticClass: "forgot-password__title"
  }, [_vm._v(_vm._s(_vm.getTranslate.forum.resetTitle))]), !_vm.isSuccess ? _c('div', [_c('router-link', {
    staticClass: "forgot-password__back-btn",
    attrs: {
      "to": _vm.Routes.ForumSignIn
    }
  }, [_c('svg', {
    class: 'forgot-password__back-icon',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 511.995 511.995",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/arrow.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z",
      "fill": "currentColor"
    }
  })]), _vm._v(" " + _vm._s(_vm.getTranslate.forum.signInTitle) + " ")]), _c('ValidationObserver', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('form', {
          staticClass: "forgot-password__form",
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return _vm.handleSubmit.apply(null, arguments);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }],
                staticClass: "forgot-password__input",
                class: {
                  'forgot-password__input_error': errors[0]
                },
                attrs: {
                  "type": "text",
                  "placeholder": _vm.getTranslate.forum.email
                },
                domProps: {
                  "value": _vm.email
                },
                on: {
                  "input": function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.email = $event.target.value;
                  }
                }
              }), _c('Error', {
                attrs: {
                  "size": "small",
                  "error": errors[0]
                }
              })];
            }
          }], null, true)
        }), _c('Error', {
          attrs: {
            "size": "small",
            "error": _vm.commonError
          }
        }), _c('button', {
          staticClass: "forum-button forgot-password__submit-btn",
          class: {
            'forum-button_loading': _vm.submitting
          },
          attrs: {
            "title": _vm.getTranslate.forum.resetFormBtn,
            "type": "submit",
            "disabled": invalid || _vm.submitting
          }
        }, [_vm._v(" " + _vm._s(_vm.getTranslate.forum.resetFormBtn) + " ")])], 1)];
      }
    }], null, false, 3798562499)
  })], 1) : _vm._e(), _vm.isSuccess ? _c('div', {
    staticClass: "forgot-password__success-modal"
  }, [_c('router-link', {
    staticClass: "forgot-password__back-btn",
    attrs: {
      "to": _vm.Routes.ForumForgotPassword
    },
    nativeOn: {
      "click": function ($event) {
        _vm.isSuccess = false;
      }
    }
  }, [_c('svg', {
    class: 'forgot-password__back-icon',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 511.995 511.995",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/arrow.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z",
      "fill": "currentColor"
    }
  })]), _vm._v(" " + _vm._s(_vm.getTranslate.instruments.back) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.getTranslate.forum.completeRecovery1) + " "), _c('b', [_vm._v(_vm._s(_vm.email))]), _vm._v(". " + _vm._s(_vm.getTranslate.forum.completeRecovery2) + " ")])], 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
import AuthApi from '@/api/auth-api'

const state = () => ({
  acl: null,
  deleteLoading: false,
  user: null,
  visibleUser: null,
  visibleUserLoading: false,
  userUpdateLoading: false,
  userLoading: false,
  userListLoading: false,
  userList: {
    data: [],
    meta: {}
  }
})

const getters = {
  getSelf (state) {
    return state.user
  },
  getACL (state) {
    // + createDiscussions: 16
    // + createPosts: 1
    // + deleteComments: 8
    // + deleteDiscussions: 64
    // deletePosts: 4
    // + deleteUsers: 128
    // + updateDiscussions: 32
    // + updatePosts: 2
    // + updateUsers: 256
    // + viewUsersList: 512

    // + view

    return state.acl
  },
  getUpdateLoading (state) {
    return state.userUpdateLoading
  },
  getUserLoading (state) {
    return state.userLoading
  },
  getDeleteLoading (state) {
    return state.deleteLoading
  },
  getUserList (state) {
    return state.userList
  },
  getUserListLoading (state) {
    return state.userListLoading
  },
  getVisibleUser (state) {
    return state.visibleUser
  },
  getVisibleUserLoading (state) {
    return state.visibleUserLoading
  }
}

const actions = {
  actionGetACL ({ commit }) {
    commit('setACLLoading', true)
    AuthApi.getACL()
      .then((response) => {
        if (response.data) {
          commit('setACL', response.data)
        }
        commit('setACLLoading', false)
      })
      .catch(() => {
        commit('setACLLoading', false)
      })
  },
  actionGetSelf ({ commit }) {
    commit('setUserLoading', true)
    AuthApi.currentUser()
      .then((response) => {
        commit('setUserLoading', false)
        commit('setUser', response.data)
      })
      .catch(() => {
        commit('setUserLoading', false)
      })
  },
  actionDeleteUser ({ commit }, { id }) {
    commit('setUserDeleteLoading', true)
    AuthApi.deleteUser(id)
      .then((response) => {
        commit('setUserDeleteLoading', false)
      })
      .catch(() => {
        commit('setUserDeleteLoading', false)
      })
  },
  actionUpdateUser ({ commit, getters }, { id, body }) {
    commit('setUserUpdateLoading', true)
    AuthApi.updateUser(id, body)
      .then((response) => {
        // обновить себя, если изменяю информацию о себе
        if (response.data && getters.getSelf.id === id) {
          // commit('setVisibleUser', response.data)
          commit('setUser', response.data)
        }
        commit('setUserUpdateLoading', false)
      })
      .catch((e) => {
        console.error(e)
        commit('setUserUpdateLoading', false)
      })
  },
  actionGetUserList ({ commit }, { limit = 0, offset = 20 } = {}) {
    commit('setUserListLoading', true)
    AuthApi.getList()
      .then((response) => {
        if (response.data) {
          commit('setUserList', response)
        }
        commit('setUserListLoading', false)
      })
      .catch(() => {
        commit('setUserListLoading', false)
      })
  },
  actionGetVisibleUser ({ commit }, id) {
    commit('setVisibleUserLoading', true)
    AuthApi.getUserById(id)
      .then((response) => {
        commit('setVisibleUserLoading', false)
        if (response.data) {
          commit('setVisibleUser', response.data)
        }
      })
      .catch(() => {
        commit('setVisibleUserLoading', false)
      })
  },
  // отсылать на почту письмо
  actionForgotPassword ({ commit }, email) {
    return AuthApi.forgotPassword({ email })
  },
  // сбрасывать пароль на новый
  actionNewPassword ({ commit }, body) {
    return AuthApi.resetPassword(body)
  },
  actionLogout ({ commit }) {
    return AuthApi.logout()
      .then((result) => {
        if (result.ok) {
          commit('setLogout')
        }
      })
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setUserDeleteLoading (state, flag) {
    state.deleteLoading = flag
  },
  setUserLoading (state, flag) {
    state.userLoading = flag
  },
  setUserUpdateLoading (state, flag) {
    state.userUpdateLoading = flag
  },
  setLogout (state) {
    state.user = null
    state.acl = null
  },
  setACLLoading (state, flag) {
    state.aclLoading = flag
  },
  setACL (state, data) {
    state.acl = data
  },
  setUserListLoading (state, flag) {
    state.userListLoading = flag
  },
  setUserList (state, data) {
    state.userList = data
  },
  setVisibleUserLoading (state, flag) {
    state.visibleUserLoading = flag
  },
  setVisibleUser (state, data) {
    state.visibleUser = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class='fixators'>
<!--    <img class='capture' :src="Images.mainBack"/> -->
    <iframe class="capture" width="360" height="220" src="https://www.youtube.com/embed/mY20Oz80zho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h2 class="main-title">{{getTranslate.clamps.mainTitle}}</h2>
    <div class="sub-title sub-title_helvetica-italic">{{getTranslate.clamps.mainSubTitle}}</div>
    <img class="capture" :src="Images.skelet"/>
    <div class="fixators__vertical-block">
      <img class='capture middle' :src="Images.fixGoldVertical"/>
      <div class="trio">
        <div>
          <img class='capture small' :src="Images.firstRent"/>
          <div class="small-image-title sub-title_helvetica-italic">{{getTranslate.clamps.imageSubTitle1}}</div>
        </div>
        <div>
          <img class='capture small' :src="Images.secondRent"/>
          <div class="small-image-title sub-title_helvetica-italic">{{getTranslate.clamps.imageSubTitle2}}</div>
        </div>
        <div>
          <img class='capture small' :src="Images.thirdRent"/>
          <div class="small-image-title sub-title_helvetica-italic">{{getTranslate.clamps.imageSubTitle3}}</div>
        </div>
      </div>
    </div>
    <div class='blue-canvas'>
      <div class="blue-block">
        <div class="white-text" v-html="getTranslate.clamps.mini"></div>
        <div class="white-text" v-html="getTranslate.clamps.constructive"></div>
      </div>
      <div class="blue-block">
        <div class="white-text" v-html="getTranslate.clamps.bio"></div>
        <div class="white-text" v-html="getTranslate.clamps.osteo"></div>
      </div>
    </div>
    <div class="w_full" v-for="menu in menuChildren" :key="menu.id">
      <div class="mobile-page-title">
        <h4 class='main-title main-title_left p-l_standard' :id="menu.name" :title="menu.name">{{menu.name}}</h4>
        <broken-line />
      </div>

      <div class="fixators__content-wrapper">
        <!-- Пока открываю в отдельной вкладке -->
        <button v-if="!!menu.catalog_link" @click="buttonClick(menu.catalog_link)" class="download-catalog-btn m-t_1 m-b_1">{{getTranslate.clamps.downloadCatalog}}</button>

        <Instrument
          v-for="item in getMenuPages(menu.id)"
          :key='item.id'
          :instrument="item"
          class="preview"
          :link="{ name: RouterName.CurrentInstrument, params: { id: item.id } }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Instrument from '@/components/Instrument'
import { mapGetters } from 'vuex'
import { Images } from '@/shared/Images/Clamps'
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'

export default {
  components: { BrokenLine, Instrument },
  data () {
    return {
      Images: Images,
      RouterName
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuChildren', 'getMenuPages']),
    // для отображения каталога и скачать
    menuChildren () {
      return this.getMenuChildren(this.$router.currentRoute.path.replace('/', '')) || null
    }
  },
  methods: {
    buttonClick (link) {
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped >
.fixators {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;

  &__vertical-block {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    .middle {
      width: 40%;
      height: 39rem;
    }
  }

  /*.main-title {*/
  /*  font-size: 1.3rem;*/
  /*}*/

  &__content-wrapper {
    align-items: center;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 0.8rem;
  }
}
.preview {
    margin: 0.4rem 0;
    max-width: 450px;
}

.trio {
  align-items: baseline;
  width: 50%;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

.small {
  width: 160px;
  padding: 0 15px;
}

.small-image-title {
  margin-top: 8px;
  position: absolute;
  width: 150px;
}

.middle {
  width: 50rem;
}
.blue {
  &-canvas {
    background: rgb(65, 114, 187);
    padding: 0 2rem;
    width: 100%;
  }

  &-block {
    border-left: 1px solid white;
    margin: 0 auto 3rem;
    max-width: 450px;
    padding-left: 0.5rem;

    &:first-child {
      padding-top: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 1.5rem;
    }
  }
}
.white-text {
  color: white;
  font-size: 1.1rem;
  text-align: justify;
  white-space: normal;
  word-break: break-all;
  margin-bottom: 1rem;
}
</style>

<template>
 <div class='broken-line' :class="{'broken-line_rtl': rtl, [className]: className}">
   <svg width="117" height="8" viewBox="0 0 117 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Common/line.svg'" v-bind:class="'broken-line__svg'"><path d="M2 6H0M2 6c3.5 0 3.5-5 6-5s2.5 5 6 5M14 6c3.5 0 3.5-5 6-5s2.5 5 6 5M26 6c3.5 0 3.5-5 6-5s2.5 5 6 5M38 6c3.5 0 3.5-5 6-5s2.5 5 6 5M50 6c3.5 0 3.5-5 6-5s2.5 5 6 5M62 6c3.5 0 3.5-5 6-5s2.5 5 6 5M74 6c3.5 0 3.5-5 6-5s2.5 5 6 5M86 6c3.5 0 3.5-5 6-5s2.5 5 6 5M98 6c3.5 0 3.5-5 6-5s2.5 5 6 5M110 6c3 0 3-5 6.5-5" stroke="#0073BF"/></svg>
   <div class="broken-line__bottom"></div>
 </div>
</template>

<script>
export default {
  name: 'BrokenLine',
  props: {
    className: String,
    rtl: Boolean
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';

.broken-line {
  margin-bottom: 4px;
  margin-top: 4px;
  position: relative;
  width: 100%;

  &__bottom {
    border-bottom: 2px solid #0073BF;
  }

  &__svg {
    bottom: -5px;
    left: -204px;
    position: absolute;
    height: 19px;
    transform: rotateY(-180deg);
    width: 206px;
  }

  &_rtl {
    transform: rotateY(180deg);

    /*.broken-line {*/
    /*  &__bottom {*/
    /*  }*/

    /*  &__svg {*/
    /*  }*/
    /*}*/
  }
}
</style>

<template>
  <div class="breadcrumbs" v-if="itemList.length" :class="wrapperClass">
    <div class="breadcrumbs__item" v-for="item in itemList" :key="item.label">
      <router-link v-if="item.route" class="breadcrumbs__link" :to="item.route">
        {{item.label}}
      </router-link>
      <span v-else class="breadcrumbs__link">
        {{item.label}}
      </span>
      <div class="breadcrumbs__divider">-</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: {
    itemList: {
      default: () => [],
      type: Array
    },
    wrapperClass: String
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.breadcrumbs {
  display: flex;
  margin-top: 24px;
  overflow: hidden;

  &__item {
    color: $black;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    display: flex;
    margin-right: 8px;

    &:last-child {
      overflow: hidden;

      .breadcrumbs {
        &__divider {
          display: none;
        }
      }
    }
  }

  &__link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__divider {
    margin-left: 8px;
  }
}
</style>

<template>
  <div class='desktop-prosthesis-list page-content'>
    <!-- TODO здесь по id -->
    <h1 class='main-title main-title_left'>{{ getPageTitle($router.currentRoute.params.id) }}</h1>
    <broken-line class-name="desktop-prosthesis-list__line" />

    <div class="desktop-prosthesis-list__content" v-if="menuPages">
      <div class='desktop-prosthesis-list__preview' v-for="prosthesis in menuPages" :key='prosthesis.id'>
        <instrument :link="{ name: RouterName.ProsthesisDetail, params: { id: prosthesis.id } }" :instrument="prosthesis" />
      </div>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import Instrument from '@/desktop/components/Instrument'
import { mapGetters } from 'vuex'
import { RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return {
      RouterName
    }
  },
  name: 'ProsthesisList',
  components: { Instrument, BrokenLine },
  computed: {
    ...mapGetters(['getPageTitle', 'getPageId', 'getMenuPages']),
    pageId () {
      return this.getPageId(this.$router.currentRoute.path)
    },
    menuPages () {
      const { id: slug } = this.$router.currentRoute.params
      return this.getMenuPages(slug) || null
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/media.scss';

.desktop-prosthesis-list {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 0 2rem;
  width: 100%;
  flex: 1;

  &__line {

    @include mq-m {
      left: -64px;
      width: calc(100% + 64px);
    }
  }

  &__content {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // column-gap: 20px;
    // row-gap: 10px;
    // justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  &__preview {
    margin: 0.5rem 0;
    padding: 0.5rem;
    flex: 1 1 33%;
    max-width: 33%;

    // @include mq-s {
    //   max-width: 265px;
    // }

    // @include mq-m {
    //   max-width: 360px;
    // }

    // @include mq-xl {
    //   max-width: 412px;
    // };
  }
}
@media (max-width: 880px){
  .desktop-prosthesis-list__preview {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

@media (max-width: 640px){
  .desktop-prosthesis-list__preview {
    display: inline-block;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

</style>

<template>
  <div class='products'>
    <div class="mobile-page-title">
      <h2 class='main-title main-title_left p-l_standard'>{{getPageTitle($router.currentRoute.path)}}</h2>
      <broken-line />
    </div>

    <div class="products__content">
      <img :src='Images.bake' class='capture'/>
      <img :src='Images.room' class='capture'/>
      <div class='products__company-name light-title light-title_left ' :id="getTranslate.products.prodmainTitle">{{getTranslate.products.prodmainTitle}}</div>
      <div class='simple products__doing simple_left' :id="getTranslate.products.prod">{{getTranslate.products.prod}}</div>
      <div class='products__list-title special simple_left' :id="getTranslate.products.typesSpecial">{{getTranslate.products.typesSpecial}}</div>
      <div class='simple simple_left' v-for="item in getTranslate.products.items" :key='item' :id="item" >
          {{item}}
      </div>
      <img :src='Images.micro' class='capture'/>
    </div>
    </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Developing'

export default {
  data () {
    return {
      Images: Images
    }
  },
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle'])
  }
}
</script>

<style lang="scss" scoped >
.products {
  &__content {
    padding: 0 0.8rem;
    max-width: 500px;
    margin: 0 auto;
  }

  &__company-name {
    margin-top: 10px;
  }

  &__list-title {
    font-size: 1rem;
    margin-top: 1.3rem;
    margin-bottom: 4px;
  }

  &__doing {
    margin-top: 1rem;
  }
}

</style>

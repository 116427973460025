<template>
  <div class='services'>
    <div class="mobile-page-title">
      <h2 class='main-title main-title_left p-l_standard' :id="getPageTitle($router.currentRoute.path)"> {{getPageTitle($router.currentRoute.path)}} </h2>
      <broken-line />

      <div class="m-t_1 t-a_i" v-html="menuPage.content"></div>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getPageTitle', 'getPageId', 'getMenuPages']),
    menuPage () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', ''))?.[0] || null
    }
  }
}
</script>

<style lang="scss" scoped >
.services {
  display: flex;
  flex-direction: column;
  flex: 1;
}

</style>

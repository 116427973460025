import { BaseApi } from '@/api/base-api'

class DiscussionApi extends BaseApi {
  /**
   * Получить список обсуждений
   * @param params Object {per_page: number, page: number}
   * @returns {Promise<any|undefined>}
   */
  listGet (params) {
    return this.get('/api/discussions/', params)
  }

  /**
   * Создание обсуждения
   * @param body Object {title: string, description: string}
   * @returns {Promise<any|undefined>}
   */
  createDiscussion (body) {
    return this.post('/api/discussions/', { body })
  }

  /**
   *
   */
  getDiscussion (id) {
    return this.get(`/api/discussions/${id}`)
  }

  /**
   * Удаление обсуждения
   * @param id number обсуждение
   * @returns {Promise<any|undefined>}
   */
  deleteDiscussion (id) {
    return this.delete(`/api/discussions/${id}`)
  }

  /**
   * Обновление обсуждении
   * @param id айди обсуждения
   * @param body Object {title: string, description: string}
   * @returns {Promise<any|undefined>}
   */
  update (id, body) {
    return this.put(`/api/discussions/${id}`, { body })
  }

  /**
   * Подписаться на обсуждение
   * @param id Number айди обсуждения
   * @returns {Promise<any|undefined>}
   */
  subscribe (id) {
    return this.post('/api/subscribe/discussion', { body: { discussion_id: id }})
  }
}

export default new DiscussionApi()

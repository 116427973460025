export const Status = {
  // Загрузка
  loading: 'loading',
  // Успешная загрузка
  success: 'success',
  // Ошибка на этапе загрузки. Выводится сообщение на разных статусах ошибки от сервера
  error: 'error',
  // начальное состояние загрузки
  initial: 'initial'
}

<template>
  <div id="app">
  <div class="w1240">
      <Header v-if="isSuccess(getMenuStatus)"></Header>

      <!-- TODO Это используется вообще? -->
      <div class="content" :class="{'content_disable-scroll': disableScroll}">

        <div v-if="isError(getMenuPagesStatus) || isError(getCurrentLoadingStatus) || isError(getMenuChildrenStatus)" class="fake-content align-block-content">
          <!-- Сюда если меню не загрузилось -->
          <h2 v-if="!!getTranslate.common" class="main-title">{{ isError(getMenuPagesStatus) ? getTranslate.common.menuNotExist : getTranslate.common.commonError }}</h2>
        </div>
        <!-- <div v-else-if="isSuccess(getMenuPagesStatus) && !getMenuPages(pageId)?.length"> -->
          <!-- <h2 v-if="!!getTranslate.common" class="main-title">{{ isSuccess(getMenuPagesStatus) ? getTranslate.common.menuNotExist : getTranslate.common.dataNotExist }}</h2> -->
        <!-- </div> -->
        <!-- <router-view /> -->
        <router-view v-else />
        <Footer></Footer>
      </div>
    </div>
    <!-- Показывать спиннер когда загрузка всей страницы -->
    <spinner-modal :loading="true" v-if="isLoading(getMenuStatus) || isLoading(getMenuPagesStatus) || isLoading(getCurrentLoadingStatus) || isLoading(getMenuChildrenStatus)"></spinner-modal>
  </div>
</template>

<script>
import Header from '@/desktop/components/Header'
import Footer from '@/desktop/components/Footer'
import SpinnerModal from '@/components/SpinnerModal'
import { isSuccess, isLoading, isError } from '@/shared/helpers/menu'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { Header, Footer, SpinnerModal },
  computed: {
    ...mapGetters(['getTranslate', 'getMenuStatus', 'getPageId', 'getMenuPages', 'getMenuPagesStatus', 'getMenuChildrenStatus', 'getCurrentLoadingStatus']),
    // ...mapGetters(['getModal', 'getModalChild']),
    pageId () {
      return this.getPageId(this.$router.currentRoute.path)
    }
  },
  methods: {
    isSuccess,
    isLoading,
    isError,
    ...mapActions(['loadTranslation', 'identifyUserAgent', 'changOpenModal', 'actionGetMenuItems', 'actionGetPagesByMenuId'])
  },
  beforeMount () {
    this.loadTranslation()
    this.identifyUserAgent()
  },
  data () {
    return {
      // TODO Сделать использование этого
      disableScroll: false
    }
  }
}
</script>

<style lang="scss">
@import "shared/style/variables.scss";
@import 'shared/style/colors.scss';
@import 'shared/style/common.scss';
@import 'shared/style/media.scss';

.modal {
  &-wrapper{
    width: 90%;
    height: 90%;
  }
  width: 30%;
  // height: 80%;
  border: 2px solid rgb(65, 114, 187);
  box-shadow: 1px 1px 7px rgba(128, 128, 128, 0.836), -1px 1px 7px rgba(128, 128, 128, 0.726);
  z-index: 1000;
  position: fixed;
  right: 35%;
  top:10%;
}

.cross {
  width: 2rem;
  height: 2rem;
  position: fixed;
  right: 36%;
  top:11%;
  z-index: 1001;
}

#app {
  font-family: 'Open sans', "HelveticaNeueCyr-Thin",
  "HelveticaNeueCyr-ThinItalic", "Din", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: white;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  position: sticky;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  scroll-behavior: smooth;
}
.w1240{
  width: 1240px;
  margin: 0 auto;
  height: 100%;
}

.fake-content {
  flex: 1;
}

#nav {
  a {
    font-weight: lighter;
    color: #000000;
    &:hover {
      color: rgb(65, 114, 187);
    }

    &.router-link-active,
    &.router-link-exact-active {
      color: rgb(65, 114, 187);
    }
  }

  // .nav-link:first-child {
  //   a.link {
  //     color: $orange;
  //   }
  // }
}

.page-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;

  @include mq-m {
    max-width: 740px;
  }

  @include mq-l {
    max-width: 970px;
  }

  @include mq-xl {
    max-width: 970px;
  }

  @include mq-xxl {
    max-width: 1100px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 10rem;
  width: 100%;
  height: 100%;

  @include mq-l {
    padding-top: 12rem;
  }

  @include mq-xxl {
    padding-top: 10rem;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.desktop__line {
  &.broken-line {
    left: -64px;
    width: calc(100% + 64px);
  }

  &.broken-line_rtl {
    &.broken-line {
      left: auto;
      right: 0;
      width: calc(100% + 64px);
    }
  }
}

.main-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Din", sans-serif;
  color: $primary;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 100%;
  margin-top: 1.5rem;
  width: 100%;

  &_remove-offset {
    margin-top: 0;
  }

  &_smaller {
    font-size: 1.4rem;
  }

  &_left {
    align-self: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  @include mq-m {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Din", sans-serif;
    color: $primary;
    font-size: 1.8rem;
    line-height: 2rem;
    margin-top: 1rem;
    width: 100%;

    &_smaller {
      font-size: 2.3rem;
      line-height: 2.7rem;
    }

    &_left {
      align-items: flex-start;
      text-align: left;
    }

    &_remove-offset {
      margin-top: 0;
    }
  }
}

.detail {
  color: $primary;
}
.detail-btn {
  align-items: center;
  border: 1px solid transparent;
  background-color: transparent;
  color: $primary;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  line-height: 100%;
  margin-right: auto;
  min-height: 22px;
  outline: none;
  padding-left: 0.8rem;
  position: relative;
  transition: color 0.2s;

  &::before {
    content: "›";
    display: block;
    font-size: 20px;
    font-weight: 400;
    height: 20px;
    left: 0;
    line-height: 20px;
    position: absolute;
    top: -2px;
    width: 6px;
  }

  @include mq-m {
    font-size: 1.1rem;
  }

  &:hover {
    color: $primary-2;
  }
}
.title {
  width: 100%;
  display: flex;
  font-family: "Din";
  flex-direction: column;
  align-items: center;
  color: rgb(60, 92, 139);
  font-size: 3rem;
  margin-top: 1rem;
}

.sub-title {
  color: $black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Mulish-ExtraLightItalic', sans-serif;
  // font-family:  'Open sans', sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 1rem;

  &_helvetica-italic {
    font-family: 'Mulish-ExtraLightItalic', sans-serif;
  }

  &_left {
    align-items: flex-start;
    text-align: left;
  }

  @include mq-m {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Mulish-ExtraLightItalic', sans-serif ;
    // font-family: "HelveticaNeueCyr-ThinItalic";
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin-top: 1rem;

    &_helvetica-italic {
      font-family: 'Mulish-ExtraLightItalic', sans-serif;
    }

    &_left {
      align-items: flex-start;
      text-align: left;
    }
  }
}

.simple {
  display: flex;
  justify-content: flex-start;
/*  font-family: $openSans;*/
  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: justify;

  &_remove-offset {
    margin-top: 0;
  }

  &_column {
    flex-direction: column;
  }

  &_left {
    align-self: flex-start;
    text-align: left;
  }

  &-wrapper {
    width: 80%;
  }

  @include mq-m {
    align-self: flex-start;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    line-height: 1.3rem;
    font-family: 'Open sans', sans-serif;
    text-align: justify;
    margin-left: 0%;
    margin-top: 1rem;

    &_remove-offset {
      margin-top: 0;
    }

    &_left {
      align-self: flex-start;
      text-align: left;
    }

    &-wrapper {
      width: 80%;
      margin-left: 10%;
    }
  }
}
.special {
  font-weight: bold;
}

.capture {
  width: 100%;
  margin-top: 1rem;
}

.line {
  width: 95%;
  margin: 0.5rem;
  margin-top: 1rem;
  position: relative;
  right: 2.5%;

  &-reverse {
    width: 95%;
    margin: 0.5rem;
    margin-top: 1rem;
    transform: rotateY(180deg);
    position: relative;
    left: 2.5%;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.col-wrapper {
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.row-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faded {
  &-bot,
  &-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }
}

.content-wrapper{
  width:65%;
}

.columns-wrapper {
  display: flex;
  flex-direction: row;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
}

.center-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 2rem;
}

.right-volumn {
  display: flex;
  flex-direction: column;
}
</style>

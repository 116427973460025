var render = function () {
  var _vm$menuPage;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-services__content page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), !!((_vm$menuPage = _vm.menuPage) !== null && _vm$menuPage !== void 0 && _vm$menuPage.content) ? _c('div', {
    staticClass: "desktop-services__inner-content m-t_40 t-a_i services_text",
    domProps: {
      "innerHTML": _vm._s(_vm.menuPage.content)
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import MenuItemsApi from '@/api/menu-items-api'
import { Status } from '@/shared/constants/load-status'

const state = () => ({
  menuChildren: {},
  menuChildrenStatus: Status.initial,
  menuStatus: Status.initial,
  menuPages: [],
  menuItems: [],
  menuData: {},
  menuPagesStatus: Status.initial
})

export const MenuAction = {
  actionGetPagesByMenuId: 'actionGetPagesByMenuId',
  actionGetChildrenByMenuId: 'actionGetChildrenByMenuId',
  actionGetMenuItems: 'actionGetMenuItems'
}

const arrayToTree = (arr, parent = null) => arr.filter(item => item.parent_id === parent).map(child => ({ ...child, children: arrayToTree(arr, child.id) }))

const rootGetters = {
  desktopMenuItems (state) {
    return arrayToTree(state.menuItems)
  },
  mobileMenuItems (state) {
    return arrayToTree(state.menuItems).reduce((prev, item) => [...prev, item, ...(item.children.length ? item.children : [])], [])
  },
  menuItems (state) {
    return state.menuItems
  },
  menuData (state) {
    return state.menuData
  },
  getMenuStatus (state) {
    return state.menuStatus
  },
  getMenuPages (state) {
    return (id) => {
      return state.menuPages?.[id]?.length ? state.menuPages[id] : null
    }
  },
  getMenuPagesStatus (state) {
    return state.menuPagesStatus
  },
  getMenuChildrenStatus (state) {
    return state.menuChildrenStatus
  },
  getMenuChildren (state) {
    return (id) => {
      return state.menuChildren?.[id]?.length ? state.menuChildren[id] : null
    }
  },
  getPageTitle (state) {
    return (url) => state.menuData[url]?.name || ''
  },
  //  Получение по id и по слагу?
  // Мб не нужна?
  getPageId (state) {
    return (url) => {
      return state.menuData[url]?.id || 0
    }
  }
}

const actions = {
  [MenuAction.actionGetMenuItems] ({ commit }) {
    commit('setMenuStatus', Status.loading)
    MenuItemsApi
      .getMenuItems()
      .then((response) => {
        if (!('data' in response)) {
          throw response
        }
        commit('setMenuItems', response.data)
        commit('setMenuStatus', Status.success)
      })
      .catch((error) => {
        commit('setMenuStatus', Status.error)
        throw error
      })
  },
  [MenuAction.actionGetPagesByMenuId] ({ commit }, id) {
    // добавить здесь исключения на замоканные страницы
    commit('setMenuPagesStatus', Status.loading)

    if (!id) {
      commit('setMenuPages', { data: [], id })
      commit('setMenuPagesStatus', Status.success)

      return
    }

    MenuItemsApi.getMenuPages(id)
      .then((response) => {
        if (!('data' in response)) {
          throw response
        }

        commit('setMenuPages', { data: response.data, id })
        commit('setMenuPagesStatus', Status.success)
      })
      .catch((error) => {
        commit('setMenuPagesStatus', Status.error)
        throw error
      })
  },
  [MenuAction.actionGetChildrenByMenuId] ({ commit }, id) {
    // добавить здесь исключения на замоканные страницы
    commit('setMenuChildrenStatus', Status.loading)

    if (!id) {
      commit('setMenuChildren', { data: [], id })
      commit('setMenuChildrenStatus', Status.success)

      return
    }

    return MenuItemsApi.getMenuChildren(id)
      .then((response) => {
        if (!('data' in response)) {
          throw response
        }

        commit('setMenuChildren', { data: response.data, id })
        commit('setMenuChildrenStatus', Status.success)
        return response.data
      })
      .catch((error) => {
        commit('setMenuChildrenStatus', Status.error)
        throw error
      })
  }
}

const mutations = {
  setMenuItems (state, data) {
    // TODO закидывать пост при апдейте и при создании
    state.menuItems = data
    // Дополнительно кидаю [item.id]: item чтобы была возможность вытащить по id и url
    state.menuData = data.reduce((acc, item) => ({ ...acc, [item.url]: item, [item.id]: item, [item.slug]: item }), {})
  },
  setMenuStatus (state, status) {
    state.menuStatus = status
  },
  setMenuPages (state, { data, id }) {
    state.menuPages = { ...state.menuPages, [id]: data }
  },
  setMenuPagesStatus (state, status) {
    state.menuPagesStatus = status
  },
  setMenuChildren (state, { data, id }) {
    state.menuChildren = { ...state.menuChildren, [id]: data }
  },
  setMenuChildrenStatus (state, status) {
    state.menuChildrenStatus = status
  }
}

export default {
  namespaced: false,
  state,
  getters: rootGetters,
  actions,
  mutations
}

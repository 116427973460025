<template>
  <div class='desktop-coverings page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)" :title="getPageTitle($router.currentRoute.path)">{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line class-name="desktop__line" />

    <div class="desktop-coverings__content" v-if="!!getTranslate.coverings">
      <div class="desktop-coverings__column desktop-coverings__column_left">
      <div class="mx-w_80">
        <span class='simple simple_remove-offset' :id="getTranslate.coverings.creation" >{{getTranslate.coverings.creation}}</span>
        <span class='simple m-t_0' :id="getTranslate.coverings.creation2" >{{getTranslate.coverings.creation2}}</span>
      </div>
      </div>
      <div class="desktop-coverings__column desktop-coverings__column_right">
        <span class='main-title main-title_remove-offset main-title_left max-w_70' :id="getTranslate.coverings.bioMainTitle" >{{getTranslate.coverings.bioMainTitle}}</span>
        <span class='sub-title sub-title_left max-w_80' :id="getTranslate.coverings.bioSubtitle" >{{getTranslate.coverings.bioSubtitle}}</span>
      </div>
    </div>
    <div class="desktop-coverings__row">
      <div class="desktop-coverings__column desktop-coverings__column_left">
        <div class="desktop-coverings__img-wrapper">
          <img alt="" :src='Images.nano' class='desktop-coverings__img'/>
          <span class='sub-title' :id="getTranslate.coverings.nanoSub" >{{getTranslate.coverings.nanoSub}}</span>
        </div>
      </div>
      <div class="desktop-coverings__column desktop-coverings__column_right">
        <div class="desktop-coverings__img-wrapper">
          <img alt="" :src='Images.micro' class='desktop-coverings__img'/>
          <span class='sub-title' :id="getTranslate.coverings.microSub" >{{getTranslate.coverings.microSub}}</span>
        </div>
      </div>
    </div>
    <div class="desktop-coverings__row">
      <div class="desktop-coverings__column desktop-coverings__column_left">
        <div class="desktop-coverings__img-wrapper">
          <img alt="" :src='Images.chese' class='desktop-coverings__img'/>
          <span class='sub-title' :id="getTranslate.coverings.cheseSub" >{{getTranslate.coverings.cheseSub}}</span>
        </div>
      </div>
      <div class="desktop-coverings__column desktop-coverings__column_right">
        <div class="desktop-coverings__img-wrapper">
          <img alt="" :src='Images.clouds' class='desktop-coverings__img'/>
          <span class='sub-title' :id="getTranslate.coverings.cloudSub" >{{getTranslate.coverings.cloudSub}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Coverings'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  data () {
    return {
      Images
    }
  },
  computed: mapGetters(['getTranslate', 'getPageTitle', 'getPageId', 'getMenuChildren'])
}
</script>

<style lang="scss" scoped >
.mx-w_80{
  max-width: 80%;
}
.desktop-coverings {
  display: flex;
  flex-direction: column;

  &__content {
    margin-top: 4rem;
    display: flex;
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    max-width: 50%;

    &_left {
      padding-right: 16px;
    }

    &_right {
      padding-left: 10px;
    }
  }

  &__row {
    display: flex;
  }

  &__img {
    width: 100%;
  }

  &__img-wrapper {
    align-self: flex-start;
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

.special {
  margin-top: 0.7rem;
}

</style>

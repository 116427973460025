<template>
   <div class='additive-desktop__wrapper page-content' v-if="getTranslate?.additive">
    <h2 class='main-title main-title_left'>{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line class-name="desktop__line" />

    <div class='additive-desktop__content'>
      <div class="additive-desktop__row">
        <div class="additive-desktop__left-side">
          <img :src='Images.printer' class='w_full'/>
          <span class='sub-title' :id="getTranslate.additive.printer">{{getTranslate.additive.printer}}</span>
          <span class='simple' :id="getTranslate.additive.process">{{getTranslate.additive.process}}</span>
          <span class='main-title max-w_90 main-title_left additive-desktop__empty-block' :id="getTranslate.additive.planMainTitle" >{{getTranslate.additive.planMainTitle}}</span>
          <div class='analize__top'>
            <div class='analize__item'>
              <img alt="" :src='Images.MRT' class="additive-desktop__rounded-image" />
              <span class='sub-title max-w_40' :id="getTranslate.additive.ktSubtitle" >{{getTranslate.additive.ktSubtitle}}</span>
            </div>
            <div class='analize__item analize__item_last'>
              <img alt="" :src='Images.modeling' class="additive-desktop__rounded-image" />
              <span class='sub-title max-w_60' :id="getTranslate.additive.modelSubtitle" >{{getTranslate.additive.modelSubtitle}}</span>
            </div>
          </div>
          <img alt="" :src='Images.screws' class='additive-desktop__clinic-img'/>
          <span class='simple'>{{getTranslate.additive.deleting}}</span>
          <span class='sub-title sub-title_left additive-desktop__prosthesis-sub-title'>{{getTranslate.additive.endoSubTitle}}</span>
        </div>
        <div class="additive-desktop__right-side">
          <span class='main-title main-title_left'  :id="getTranslate.additive.whatIsMainTitle" >{{getTranslate.additive.whatIsMainTitle}}</span>
          <span class='sub-title sub-title_left'  :id="getTranslate.additive.compModelSubtitle" >{{getTranslate.additive.compModelSubtitle}}</span>
          <img alt="" :src='Images.welding' class='w_full m-b_1 additive-desktop__welding-img v-hidden'/>
          <span class='simple additive-desktop__empty-block simple_left'  :id="getTranslate.additive.materials"  >{{getTranslate.additive.materials}}</span>
          <span class='simple m-t_0 simple_left special'  :id="getTranslate.additive.forImplantsBold"  >{{getTranslate.additive.forImplantsBold}}</span>
          <span class='simple m-t_0 simple_left'  :id="getTranslate.additive.titan" >{{getTranslate.additive.titan}}</span>
          <span class='simple m-t_0 simple_left special' :id="getTranslate.additive.forModelBold"  >{{getTranslate.additive.forModelBold}}</span>
          <span class='simple m-t_0 simple_left'  :id="getTranslate.additive.bio" >{{getTranslate.additive.bio}}</span>
          <div class='analize__item analize__item_last analize__item_left m-l_1'>
            <img alt="" :src='Images.production' class="additive-desktop__rounded-image" />
            <span class='sub-title max-w_70'  :id="getTranslate.additive.productionSubtitle" >{{getTranslate.additive.productionSubtitle}}</span>
          </div>
          <span class='main-title main-title_left additive-desktop__clinic-title'  :id="getTranslate.additive.clinickMainTitle" >{{getTranslate.additive.clinickMainTitle}}</span>
          <span class='sub-title sub-title_left'  :id="getTranslate.additive.clinickSubtitle" >{{getTranslate.additive.clinickSubtitle}}</span>
           <img :src='Images.compModel' class='additive-desktop__comp-img '/>
        </div>
      </div>
        <div class="additive-desktop__row m-t_1">
<!--          <img :src='Images.shoulders' class='capture max-w_60'/>-->
          <img :src='Images.shouldersModel' class='capture max-w_45 h_full'/>
        </div>
        <!-- <span class='sub-title sub-title_left'  :id="getTranslate.additive.models" >{{getTranslate.additive.models}}</span>
        <div class="additive-desktop__row m-t_1">
          <div class="additive-desktop__img-column">
            <img :src='Images.shuriken' class='additive-desktop__bottom-image'/>
            <img :src='Images.washbasin' class='additive-desktop__bottom-image'/>
            <img :src='Images.sweater' class='additive-desktop__bottom-image'/>
          </div>
          <div class="additive-desktop__img-column">
            <img :src='Images.bigBone' class='additive-desktop__bottom-image special_img'/>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Images } from '@/shared/Images/Additive'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  data () {
    return {
      Images
    }
  },
  computed: mapGetters(['getTranslate', 'getPageTitle'])
}
</script>

<style lang="scss" scoped>
.d-none{
  display: none;
}
.v-hidden{
  visibility: hidden;
}
.additive-desktop {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
  }

  &__left-side {
    flex: 1 1 100%;
    max-width: 50%;
    padding-right: 2.5rem;
  }

  &__right-side {
    flex: 1 1 100%;
    max-width: 50%;
    padding-left: 2.5rem;
  }

  &__row {
    display: flex;
  }

  &__welding-img {
    margin-top: 10rem;
  }

  &__empty-block {
    margin-top: 6rem;
  }

  &__rounded-image {
    width: 240px;
  }

  &__clinic-img {
    margin-top: 4rem;
    width: 100%;
  }

  &__clinic-title {
    margin-top: 30rem;
  }

  &__comp-img {
    display: flex;
    margin: 17.5rem auto 0 0;
    width: 400px;
  }

  &__prosthesis-sub-title {
    margin-top: 2rem;
    max-width: 79%;
  }

  &__img-column {
    flex: 1 1 100%;
    max-width: 100%;

    &:first-child {
      display: flex;
      flex-direction: column;
      max-width: 377.5px;
    }

    &:last-child {
      padding-left: 0.8rem;
    }
  }

  &__bottom-image {
    height: 100%;
    width: 100%;
  }
}

.analize {
  &__top {
    display:flex;
    flex-direction: column;
  }

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    &_last {
      margin-top: 5rem;
    }

    &_left {
      margin-right: 9rem;
    }
  }
}

.special_img {
  height: 100%;
  margin-top: 3px;
  object-fit: cover;
  padding-bottom: 6px;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "carousel__wrapper",
    class: _vm.className
  }, [_c('carousel', {
    ref: "carouselRef",
    attrs: {
      "per-page": 1,
      "navigate-to": _vm.currentSlide,
      "mouse-drag": _vm.mouseDrag,
      "pagination-active-color": "#4172bb"
    },
    on: {
      "page-change": _vm.changePage
    }
  }, _vm._l(_vm.images, function (item, index) {
    return _c('slide', {
      key: index
    }, [_c('img', {
      staticClass: "carousel__image-slide",
      class: _vm.imageClass,
      attrs: {
        "alt": "",
        "src": item
      }
    })]);
  }), 1), _vm.images.length > 1 ? _c('button', {
    staticClass: "carousel__arrow carousel__arrow_prev",
    on: {
      "click": _vm.handlePrevClick
    }
  }, [_c('svg', {
    class: 'carousel__arrow-btn',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 511.995 511.995",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/arrow.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z",
      "fill": "currentColor"
    }
  })])]) : _vm._e(), _vm.images.length > 1 ? _c('button', {
    staticClass: "carousel__arrow carousel__arrow_next",
    on: {
      "click": _vm.handleNextClick
    }
  }, [_c('svg', {
    class: 'carousel__arrow-btn',
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 511.995 511.995",
      "svg-inline": '',
      "src": '@/shared/Images/Forum/arrow.svg'
    }
  }, [_c('path', {
    attrs: {
      "d": "M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z",
      "fill": "currentColor"
    }
  })])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
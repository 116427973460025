<template>
  <div>
    <modal :max-height="180" height="auto" :scrollable="true" :adaptive="true" name="delete-user" class="popup__delete-user">
      <slot></slot>
    </modal>
    <modal :max-height="180" height="auto" :scrollable="true" :adaptive="true" name="delete-topic" class="popup__delete-user">
      <slot></slot>
    </modal>
  </div>
</template>

<script>
import VModal from 'vue-js-modal'
import Vue from 'vue'

Vue.use(VModal)

export default {

}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';

.popup {
  &__delete-user {
    background-color: $white;
    z-index: 1000;
  }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "popup__delete-user",
    attrs: {
      "max-height": 180,
      "height": "auto",
      "scrollable": true,
      "adaptive": true,
      "name": "delete-user"
    }
  }, [_vm._t("default")], 2), _c('modal', {
    staticClass: "popup__delete-user",
    attrs: {
      "max-height": 180,
      "height": "auto",
      "scrollable": true,
      "adaptive": true,
      "name": "delete-topic"
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import bigBone from './bigBone.jpg'
import compModel from './compModel.jpg'
import modeling from './modeling.png'
import MRT from './MRT.png'
import printer from './printer.webp'
import production from './production.png'
import screws from './screws.jpg'
import shoulders from './shoulders.jpg'
import shouldersModel from './shouldersModel.jpg'
import shuriken from './shuriken.jpg'
import sweater from './sweater.jpg'
import washbasin from './washbasin.jpg'
import welding from './welding.jpg'
import wave from '../wave.svg'
import strip from '../stripLeft.jpg'

export const Images = {
  bigBone,
  compModel,
  modeling,
  MRT,
  printer,
  production,
  screws,
  shoulders,
  shouldersModel,
  shuriken,
  sweater,
  washbasin,
  welding,
  wave,
  strip
}

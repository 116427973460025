var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-vacancies__content page-content"
  }, [_c('h1', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _vm._l(_vm.menuPages, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "vacancy_item"
    }, [_c('div', {
      staticClass: "vacancy_column"
    }, [_c('h2', {
      staticClass: "main-title main-title_left m-b_1"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "vacancy_text"
    }, [_vm._v(" " + _vm._s(item.description) + " ")])]), _c('div', {
      staticClass: "vacancy_column"
    }, [_c('div', {
      staticClass: "vacancy_text t-a_i",
      domProps: {
        "innerHTML": _vm._s(item.content)
      }
    })])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
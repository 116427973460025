import { BaseApi } from '@/api/base-api'

class MenuItemsApi extends BaseApi {
  getMenuItems () {
    return this.get('/api/menu-items/')
  }

  getMenuPages (id) {
    return this.get(`/api/menu-items/${id}/pages`)
  }

  getMenuChildren (id) {
    return this.get(`/api/menu-items/${id}/children`)
  }
}

export default new MenuItemsApi()

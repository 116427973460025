<template>
    <div class='instrument-wrapper'>
    <div class='instrument-id'>{{instrument.slug}}</div>
    <!-- TODO Как тут работает пустое to? Зачем тут :click? -->
    <router-link class="instrument-image-link" :to="link">
      <img :alt="instrument.title || instrument.name" class='instrument-img' :src='instrument.images?.[0] || defaultImage'
       v-on:click="changeCurrentItem({images: instrument.images, description: instrument.title})" />
    </router-link>
    <div class='instrument-name' :title="instrument.title || instrument.name">
      <span class='bottom'>{{instrument.title || instrument.name}}</span>
    </div>
 </div>
</template>

<script>
import { mapActions } from 'vuex'
import commonImages from '@/shared/Images/Common'

export default {
  data () {
    return {
      defaultImage: commonImages.emptyImage
    }
  },
  name: 'instrument',
  methods: mapActions(['changeCurrentItem']),
  props: {
    instrument: Object,
    link: Object
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/media.scss';

.instrument {
  &-wrapper {
    box-shadow: 1px 1px 7px rgba(128, 128, 128, 0.836), -1px 1px 7px rgba(128, 128, 128, 0.726);
    overflow: hidden;
    height: 100%;
    position: relative;
  }

  &-img {
    // max-width: 100%;
    width: 100%;
    object-fit: contain;
    // max-height: 20.5rem;
    // min-height: 16.5rem;
  }

  &-name {
      background: rgb(39, 161, 231);
      bottom: -1px;
      color: white;
      transform: skew(30deg);
      position: absolute;
      left: -11px;
      height: 38px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1rem;
      padding-left: 2rem;
      width: 100%;
  }

  &-id {
    font-size: 12px;
    font-weight: bold;
    height: 29px;
    padding: 1rem 0 0 1rem;
    text-align: start;
    width: 100%;

    @include mq-xl {
      height: 35px;
    }

    @media (min-width: 1600px){
      height: 39px;
    }
  }

  &-image-link {
    display: block;
    height: calc(100% - 38px - 35px);
    line-height: 0;
    position: relative;
    display: flex;
  }
}
.bottom {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: skew(-30deg);
  white-space: nowrap;

  @include mq-m {
    font-size: inherit;
  }
}
</style>

<template>
  <div class='smi'>

    <div class="mobile-page-title">
      <h2 class='main-title main-title_left p-l_standard'>{{getPageTitle($router.currentRoute.path)}}</h2>
      <broken-line />
    </div>

    <div class="p-l_standard p-r_standard smi__content" v-for="({title, description, images, external_url, id}) in menuPages" :key="id">
      <img v-if="!!images.length" :src='images[0]' class='capture' :alt="title" />
      <h4 class='main-title main-title_left' >{{title}}</h4>
      <div class='sub-title sub-title_helvetica-italic sub-title_left smi__subtitle'>{{ description }}</div>
      <a v-if="external_url" target="_blank" :href="external_url" class="detail-btn m-t_1">
          {{getTranslate.smi?.detail}}
        </a>
    </div>
  </div>
</template>

<script>
import BrokenLine from '@/components/BrokenLine'
import { mapGetters } from 'vuex'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
.smi {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__subtitle {
    white-space: pre-wrap;
  }
}

</style>

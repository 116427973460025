<template>
  <div v-if="getTranslate.forum">
    <h3 class="forgot-password__title">{{getTranslate.forum.resetTitle}}</h3>
    <div v-if="!isSuccess">
      <router-link :to="Routes.ForumSignIn" class="forgot-password__back-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.995 511.995" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/arrow.svg'" v-bind:class="'forgot-password__back-icon'"><path d="M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z" fill="currentColor"/></svg>
        {{getTranslate.forum.signInTitle}}
      </router-link>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <form class="forgot-password__form" @submit.prevent="handleSubmit">
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
            <input
              class="forgot-password__input"
              :class="{'forgot-password__input_error': errors[0]}"
              v-model="email"
              type="text"
              :placeholder="getTranslate.forum.email"
            />
            <Error size="small" :error="errors[0]" />
          </ValidationProvider>
          <Error size="small" :error="commonError" />
          <button :title="getTranslate.forum.resetFormBtn" :class="{'forum-button_loading': submitting}" class="forum-button forgot-password__submit-btn" type="submit" :disabled="invalid || submitting">
            {{getTranslate.forum.resetFormBtn}}
          </button>
        </form>
      </ValidationObserver>
    </div>
    <div v-if="isSuccess" class="forgot-password__success-modal">
      <router-link @click.native="isSuccess = false" :to='Routes.ForumForgotPassword' class="forgot-password__back-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.995 511.995" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/arrow.svg'" v-bind:class="'forgot-password__back-icon'"><path d="M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z" fill="currentColor"/></svg>
        {{getTranslate.instruments.back}}
      </router-link>
      <div>
        {{ getTranslate.forum.completeRecovery1 }} <b>{{email}}</b>. {{ getTranslate.forum.completeRecovery2 }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Error from '@/views/Error'
import { Routes } from '@/shared/constants/routes'

export default {
  data () {
    return { Routes, isSuccess: false, commonError: '', email: '', submitting: false }
  },
  components: { Error, ValidationObserver, ValidationProvider },
  computed: mapGetters(['getTranslate']),
  methods: {
    ...mapActions('auth', ['actionForgotPassword']),
    handleSubmit () {
      this.submitting = true
      this.actionForgotPassword(this.$refs.form.refs.email.value).then((response) => {
        const { message, errors = {}} = response
        if (message) {
          this.$refs.form.setErrors(errors)
          this.commonError = message
        } else {
          this.isSuccess = response
        }
        this.submitting = false
      })
    }
  },
  watch: {
    'getTranslate.forum': (forum) => {
      extend('email', {
        ...email,
        message: forum.formEmail
      })
      extend('required', {
        ...required,
        message: forum.formRequired
      })
    }
  },
  name: 'ForumForgotPassword'
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.forgot-password {
  &__input {
    border-width: 0;
    border-bottom: 2px solid $primary;
    font-weight: bold;
    height: 40px;
    margin: 10px auto;
    max-width: 150px;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: $black;
    }

    &:focus {
      outline: 0;
      border-width: 0;
      border-bottom: 2px solid $primary;
    }

    &_error {
      border-bottom-color: $red;
    }
  }

  &__back-btn {
    color: $primary;
    display: inline-flex;
    font-size: 12px;
    left: 16px;
    position: absolute;
    top: 16px;
    transition: color 0.2s;

    &:hover {
      color: rgba($primary, 0.5);
    }
  }

  &__back-icon {
    height: 18px;
    margin-right: 5px;
    transform: rotate(180deg);
    width: 14px;
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 150px;
    margin: 0 auto;
    text-align: left;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__submit-btn {
    max-width: 150px;
    margin: 25px 0;
  }

  &__success-modal {
    margin-top: 1.5rem;
  }

  @include mq-m {
    &__title {
      font-size: 2rem;
    }
  }
}
</style>

<template>
  <div class='desktop-about__content page-content'>
    <h2 class='main-title main-title_left' v-if="!!menuPage">{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line className="desktop-about__line" v-if="!!menuPage" />
    <div class="desktop-about__block" v-if="!!menuPage && getTranslate?.about">
      <div class="desktop-about__image-block">
        <img class="desktop-about__building-image" v-if="!!menuPage.images?.[0]" :src="menuPage.images?.[0]" alt="building">
        <div class="desktop-about__resident-wrapper">
          <img class="desktop-about__resident-image" :src="Images.eco" alt="ROCOES" />
          <div class="desktop-about__resident-name">{{getTranslate.about.tvt}} <b>{{getTranslate.about.tvtBold}}</b></div>
        </div>
      </div>
      <div class="desktop-about__text">
        <h2 class="desktop-about__sub-title">{{menuPage.title}}</h2>
        <div class="simple simple_column">
          <p v-html="menuPage.content"></p>
        </div>
      </div>
    </div>
    <h2 class='main-title main-title_left' v-if="getTranslate?.about" :id="getTranslate.about.contacts">{{getTranslate.about.contacts}}</h2>
    <broken-line className="desktop-about__line" />
    <section class="desktop-about__contact-info" v-if="getTranslate?.about">
      <div class="desktop-about__contact-wrapper">
        <h4 class="desktop-about__sub-title m-b_1">{{getTranslate.about.salesDepartment}}</h4>
        <span class="desktop-about__contact-row">
          <span>{{getTranslate.about.phoneLabel}}</span>{{': '}}
          <a :href="`tel:${getTranslate.about.salesPhone}`">{{getTranslate.about.salesPhone}}</a>
        </span>
        <span class="desktop-about__contact-row">
          <span>{{getTranslate.about.emailLabel}}</span>{{': '}}
          <a :href="`mailto:${getTranslate.about.salesMail}`">{{getTranslate.about.salesMail}}</a>
        </span>
      </div>
      <div class="desktop-about__contact-wrapper">
        <h4 class="desktop-about__sub-title m-b_1">{{getTranslate.about.productionDepartment}}</h4>
        <span class="desktop-about__contact-row">
          <span>{{getTranslate.about.phoneLabel}}</span>{{': '}}
          <a :href="`tel:${getTranslate.about.productionPhone}`">{{getTranslate.about.productionPhone}}</a>
        </span>
        <span class="desktop-about__contact-row">
          <span>{{getTranslate.about.emailLabel}}</span>{{': '}}
          <a :href="`mailto:${getTranslate.about.productionMail}`">{{getTranslate.about.productionMail}}</a>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Images } from '@/shared/Images/About'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  data () {
    return {
      Images
    }
  },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPage () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', ''))?.[0] || null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/variables.scss';
@import '@/shared/style/colors.scss';

.about-wrapper {
  width: 100%;
}
.desktop-about {
  &__content {
    display: flex;
    flex-direction: column;
    font-family: 'Open sans', Helvetica, Arial, sans-serif;
    margin-left: auto;
    flex: 1;
    margin-right: auto;
  }

  &__line {
    left: -120px;
    width: calc(100% + 120px);
  }

  &__block {
    display: flex;
    justify-content: space-between;
    margin-top: 72px;
    margin-bottom: 2rem;
  }

  &__text {
    width: 55%;
  }

  &__image-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 65px;
    width: 44%;
  }

  &__building-image {
    width: 100%;
  }

  &__resident-wrapper {
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  &__resident-image {
    width: 180px;
  }

  &__resident-name {
    font-size: 1.3rem;
    line-height: 1.7rem;
    max-width: 42%;
    padding-left: 14px;
    text-align: left;
    overflow-wrap: break-word;
  }

  &__sub-title {
    font: 2rem/100% $openSans;
    text-align: left;
    color: $primary;
  }

  &__contact-info {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 0 0;
    width: 100%;
  }

  &__contact-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 3rem;
    width: 100%;
  }

  &__contact-row {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
.resident {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}
.ros {
  width: 40%;
  height: auto;
}
.text {
  display: flex;
  flex-direction: row;
}

.contacts {
  margin: 0 auto;
  max-width: 85%;
}
</style>

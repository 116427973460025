<template>
  <spinner-container :loading="getVisibleUserLoading">
    <div class="profile" v-if="getTranslate.forum">
      <Breadcrumbs :itemList="[{label: getTranslate.forum.forum, route: Routes.ForumTopicList}, {label: getTranslate.forum.anketa, route: ''}]" />
      <form class="profile__content">
        <forum-input :disabled="disabledForm" className="profile__input" name="surname"  :placeholder="getTranslate.forum.lastName" @input="value => user.surname = value" :value="user.surname || ''" />
        <forum-input :disabled="disabledForm" className="profile__input" name="name"  :placeholder="getTranslate.forum.firstName" @input="value => user.name = value" :value="user.name || ''" />
        <forum-input :disabled="disabledForm" className="profile__input" name="middlename"  :placeholder="getTranslate.forum.middleName" @input="value => user.middlename = value" :value="user.middlename" />
        <forum-input :disabled="true" className="profile__input" name="email"  :placeholder="getTranslate.forum.emailPlaceholder" @input="value => user.email = value" :value="user.email" />
        <forum-input :disabled="disabledForm" className="profile__input" name="phone"  :placeholder="getTranslate.forum.phonePlaceholder" @input="value => user.phone = value" :value="user.phone" />
        <forum-input :disabled="disabledForm" className="profile__input" name="country"  :placeholder="getTranslate.forum.countryPlaceholder" @input="value => user.country = value" :value="user.country" />
        <forum-input :disabled="disabledForm" className="profile__input" name="city"  :placeholder="getTranslate.forum.cityPlaceholder" @input="value => user.city = value" :value="user.city" />
        <forum-input :disabled="disabledForm" className="profile__input" name="interests"  :placeholder="getTranslate.forum.interestPlaceholder" @input="value => user.interests = value" :value="user.interests" />
        <forum-input :disabled="disabledForm" className="profile__input" name="company"  :placeholder="getTranslate.forum.appointmentPlaceholder" @input="value => user.company = value" :value="user.company" />
        <forum-input :disabled="disabledForm" className="profile__input" name="job"  :placeholder="getTranslate.forum.jobPlaceholder" @input="value => user.job = value" :value="user.job" />

        <button  class="forum-button profile__logout-btn m-b_1" type="button" @click="handleLogout">{{getTranslate.forum.exitFromAccount}}</button>
        <button v-if="permissions.deleteUsers" class="profile__delete-btn warning-button m-b_1" type="button" @click="toggleModal">{{getTranslate.forum.deleteUser}}</button>
        <button :disabled="getUpdateLoading" v-if="canSaveForm" :class="{'forum-button_loading': getUpdateLoading}" class="forum-button profile__submit-btn m-b_1" type="submit" @click="handleSaveInfo">{{getTranslate.forum.actionSave}}</button>
      </form>
      <modal>
        <div class="popup">
          <div class="popup__title">
            {{getTranslate.forum.warningDeleteText}}
          </div>
          <div class="popup__row">
            <button :class="{'warning-button_loading': getDeleteLoading}" class="warning-button" @click="onDeleteUser">{{getTranslate.forum.deleteUser}}</button>
            <button class="white-button popup__cancel" type="submit" @click="toggleModal">{{getTranslate.forum.cancel}}</button>
          </div>
        </div>
      </modal>
    </div>
  </spinner-container>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import Modal from '@/components/Modal'
import { mapActions, mapGetters } from 'vuex'
import ForumInput from '@/components/ForumInput'
import SpinnerContainer from '@/components/SpinnerContainer'
import { getPermissions } from '@/shared/helpers'
import { Routes } from '@/shared/constants/routes'

export default {
  data () {
    return { Routes }
  },
  name: 'Profile',
  components: { SpinnerContainer, ForumInput, Breadcrumbs, Modal },
  computed: {
    ...mapGetters(['getTranslate']),
    ...mapGetters('auth', ['getSelf', 'getACL', 'getVisibleUser', 'getVisibleUserLoading', 'getUpdateLoading', 'getDeleteLoading']),
    user: function () {
      if (this.getSelf && this.getSelf.id === Number(this.$route.params.userId)) {
        return { ...this.getSelf }
      }
      return { ...this.getVisibleUser }
    },
    permissions () {
      return this.getPermissions(this.getACL, this.getSelf, ['deleteUsers', 'updateUsers'])
    },
    canSaveForm () {
      return this.permissions?.updateUsers || (this.getSelf?.id && this.getSelf?.id === this.getVisibleUser?.id)
    },
    disabledForm () {
      return !this.canSaveForm || this.getUpdateLoading || this.getDeleteLoading
    }
  },
  methods: {
    ...mapActions('auth', ['actionLogout', 'actionGetVisibleUser', 'actionUpdateUser', 'actionDeleteUser']),
    getPermissions,
    toggleModal () {
      this.$modal.toggle('delete-user')
    },
    onDeleteUser () {
      this.actionDeleteUser({ id: this.$route.params.userId })
      this.$router.push(this.Routes.ForumParticipants)
    },
    handleSaveInfo () {
      const { id, ...rest } = this.user
      this.actionUpdateUser({
        id,
        body: rest
      })
    },
    handleLogout () {
      this.actionLogout().then(() => {
        this.$router.push(this.Routes.ForumSignIn)
      })
    }
  },
  beforeMount () {
    const { userId } = this.$route.params
    this.actionGetVisibleUser(userId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.profile {
    padding: 0 16px;
    margin: 24px auto;
    width: 100%;

    @include mq-m {
      width: calc(846px + 16px + 16px);
    }

    @include mq-l {
      width: calc(970px + 16px + 16px);
    }

    @include mq-xl {
      width: calc(1280px + 16px + 16px);
    }

    @include mq-xxl {
      width: calc(1440px + 16px + 16px);
    }

    &__content {
      border: 2px solid $gray-dark;
      padding: 16px 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 40px;
    }

    &__input {
      margin-bottom: 12px;

      @include mq-m {
        flex: 1 1 calc(50% - 6px);
        max-width: calc(50% - 6px);

        &:nth-child(2n-1),
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__submit-btn {
      align-self: flex-end;
      max-width: 133px;
    }

    &__delete-btn {
      width: auto;
    }

    &__logout-btn {
      max-width: 300px;
    }
  }

  .popup {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 24px 16px;
    height: 100%;

    &__title {
      font-size: 17px;
      line-height: 32px;
      margin-bottom: 32px;
    }

    &__row {
      display: flex;
    }

    &__cancel {
      margin-left: 16px;
    }
  }
</style>

<template>
 <div :class="className" class='image-wrapper' >
  <img class='image' :src='img' :class="imageClass" alt="picture" />
 </div>
</template>

<script>

export default {
  name: 'RoundCap',
  props: {
    className: String,
    img: String,
    imageClass: String,
    width: Number,
    height: Number
  }
}
</script>

<style lang="scss">
</style>

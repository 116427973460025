var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.itemList.length ? _c('div', {
    staticClass: "breadcrumbs",
    class: _vm.wrapperClass
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.label,
      staticClass: "breadcrumbs__item"
    }, [item.route ? _c('router-link', {
      staticClass: "breadcrumbs__link",
      attrs: {
        "to": item.route
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]) : _c('span', {
      staticClass: "breadcrumbs__link"
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('div', {
      staticClass: "breadcrumbs__divider"
    }, [_vm._v("-")])], 1);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-wrapper page-content"
  }, [_vm.show ? _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "search"
  }, [_c('form', {
    staticClass: "search-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSearchRedirect.apply(null, arguments);
      }
    }
  }, [_vm.getTranslate.common ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    staticClass: "search-input",
    attrs: {
      "placeholder": _vm.getTranslate.common.placeholder
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.inputValue = $event.target.value;
      }
    }
  }) : _vm._e()])]), _c('button', {
    staticClass: "header__profile-btn",
    on: {
      "click": _vm.handleLogin
    }
  }, [_c('profile-svg', {
    staticClass: "header__profile-svg"
  }), _c('div', {
    staticClass: "header__profile-name"
  }, [_vm._v(" " + _vm._s(_vm.getSelf ? _vm.getSelf.name : _vm.getTranslate.forum.signIn) + " ")])], 1), _c('div', {
    staticClass: "languages"
  }, _vm._l(_vm.langs, function (flag) {
    return _c('img', {
      key: flag,
      staticClass: "languages-flag",
      class: {
        'languages-flag-active': _vm.getLang === flag
      },
      attrs: {
        "src": _vm.Images.languages[flag]
      },
      on: {
        "click": function ($event) {
          return _vm.changeLanguage(flag);
        }
      }
    });
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "top"
  }, [_c('router-link', {
    staticClass: "logo-link",
    attrs: {
      "to": "/#home"
    }
  }, [_c('img', {
    staticClass: "logo-img",
    attrs: {
      "alt": "logo",
      "src": _vm.Images.logo
    }
  })]), _c('nav', {
    staticClass: "nav",
    class: {
      show: !_vm.show
    },
    attrs: {
      "id": "nav"
    }
  }, _vm._l(_vm.desktopMenuItems, function (item) {
    return _c('div', {
      key: item.name,
      staticClass: "nav-link"
    }, [_c('router-link', {
      staticClass: "nav-main-link link",
      attrs: {
        "to": item.url,
        "exact": true,
        "exact-active-class": "active-link"
      }
    }, [_c('span', [_vm._v(_vm._s(item.name))])]), item.children ? _c('div', {
      staticClass: "nav-link-sub"
    }, [_c('div', {
      staticClass: "nav-link-sub-inner"
    }, _vm._l(item.children, function (link) {
      return _c('div', {
        key: link.name,
        staticClass: "sub-link-wrapper"
      }, [_c('router-link', {
        staticClass: "sub-link",
        attrs: {
          "to": link.url,
          "exact-active-class": "sub-link-active"
        }
      }, [_c('span', [_vm._v(_vm._s(link.name))])])], 1);
    }), 0)]) : _vm._e()], 1);
  }), 0)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { BaseApi } from '@/api/base-api'

class PagesApi extends BaseApi {
  pagesListGet () {
    return this.get('/api/pages/')
  }

  getPage (id) {
    return this.get(`/api/pages/${id}`)
  }
}

export default new PagesApi()

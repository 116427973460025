var render = function () {
  var _vm$menuPages;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-news__content page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), !!((_vm$menuPages = _vm.menuPages) !== null && _vm$menuPages !== void 0 && _vm$menuPages.length) ? _c('div', {
    staticClass: "desktop-news__columns-wrapper m-t_1"
  }, _vm._l(_vm.menuPages, function (newsItem, index) {
    return _c('div', {
      key: newsItem.id
    }, [_c('div', {
      staticClass: "desktop-news__post-wrapper m-t_1",
      class: {
        'reverse': index % 2 == 0
      }
    }, [_c('div', {
      staticClass: "desktop-news__right-side"
    }, [_c('h4', {
      staticClass: "main-title main-title_left main-title_remove-offset"
    }, [_vm._v(_vm._s(newsItem.title))]), _c('p', {
      staticClass: "sub-title sub-title_left overflow-ellipse-6"
    }, [_vm._v(_vm._s(newsItem.description))]), newsItem.external_url && newsItem.is_show_link ? _c('a', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "href": newsItem.external_url,
        "target": "_blank",
        "id": newsItem.external_url
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslate.news.detail) + " ")]) : newsItem.is_show_link ? _c('router-link', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "to": {
          name: _vm.RouterName.CurrentNews,
          params: {
            id: newsItem.id
          }
        },
        "id": newsItem.external_url
      }
    }, [_vm._v(" " + _vm._s(_vm.getTranslate.news.detail) + " ")]) : _vm._e()], 1), _c('div', {
      staticClass: "desktop-news__left-side"
    }, [newsItem.images[0] ? _c('img', {
      staticClass: "capture m-b_1",
      attrs: {
        "src": newsItem.images[0]
      }
    }) : _vm._e()])])]);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
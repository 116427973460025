export const Routes = {
  Home: '/',
  About: '/about',
  Additive: '/additive',
  Clamps: '/clamps',
  Coverings: '/coverings',
  Developing: '/developing',
  Documentation: '/documentation',
  Vacancies: '/vacancies',
  Instruments: '/instruments',
  CurrentInstrument: '/instruments/:id',
  News: '/news',
  CurrentNews: '/news/:id',
  Products: '/products',
  Prostheses: '/prostheses',
  ProsthesisList: '/prosthesis/:id',
  ProsthesisDetail: '/prosthesis-detail/:id',
  Researches: '/researches',
  Services: '/services',
  Smi: '/smi',
  Search: '/search',

  /* Форум */
  ForumHome: '/forum',
  ForumSignIn: '/forum/sign-in',
  ForumSignUp: '/forum/sign-up',
  ForumForgotPassword: '/forum/forgot-password',
  ForumNewPassword: '/forum/new-password',
  ForumProfile: '/forum/profile',
  ForumTopicList: '/forum/topic-list',
  ForumCreateTopic: '/forum/topic/create-topic',
  ForumTopicView: '/forum/topic/:topicId/view',
  ForumTopicEdit: '/forum/topic/:topicId/edit',
  ForumCreatePost: '/forum/topic/:topicId/create-post',
  ForumPostView: '/forum/:topicId/post/:postId/view',
  ForumPostEdit: '/forum/:topicId/post/:postId/edit',
  ForumParticipants: '/forum/participants',
  ForumCurrentParticipant: '/forum/participants/:userId',
  /* Форум */

  NotFound: '*'
}

export const RouterName = {
  Home: 'Home',
  About: 'About',
  News: 'News',
  CurrentNews: 'CurrentNews',
  Smi: 'Smi',
  Researches: 'Researches',
  Developing: 'Developing',
  Documentation: 'Documentation',
  Services: 'Services',
  Products: 'Products',
  Additive: 'Additive',
  Instruments: 'Instruments',
  CurrentInstrument: 'CurrentInstrument',
  Prostheses: 'Prostheses',
  ProsthesisList: 'ProsthesisList',
  ProsthesisDetail: 'ProsthesisDetail',
  Clamps: 'Clamps',
  Coverings: 'Coverings',
  Vacancies: 'Vacancies',
  Search: 'Search',
  NotFound: 'Not found',

  /* Форум */
  ForumHome: 'ForumHome',
  ForumSignIn: 'ForumSignIn',
  ForumSignUp: 'ForumSignUp',
  ForumForgotPassword: 'ForumForgotPassword',
  ForumNewPassword: 'ForumNewPassword',
  ForumProfile: 'ForumProfile',
  ForumTopicList: 'ForumTopicList',
  ForumCreateTopic: 'ForumCreateTopic',
  ForumTopicView: 'ForumTopicView',
  ForumTopicEdit: 'ForumTopicEdit',
  ForumCreatePost: 'ForumCreatePost',
  ForumPostView: 'ForumPostView',
  ForumPostEdit: 'ForumPostEdit',
  ForumParticipants: 'ForumParticipants',
  ForumCurrentParticipant: 'ForumCurrentParticipant'
}

<template>
  <div class='desktop-smi__content page-content'>
    <span class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)">{{getPageTitle($router.currentRoute.path)}}</span>
    <broken-line className="desktop__line" />

    <div class="desktop-smi__post" v-for="({title, description, images, external_url, is_show_link, id}, index) in menuPages" :key="id" :class="{reverse : index % 2==0}">
      <img v-if="!!images.length" :src='images[0]' class='desktop-smi__image' :alt="title"/>
      <div class="desktop-smi__right-side">
        <h4 class="main-title main-title_left main-title_remove-offset">{{title}}</h4>
        <p class='sub-title sub-title_left m-b_1 max-w_75'>{{ description }}</p>
        <a v-if="external_url && is_show_link" target="_blank" :href="external_url" class="detail-btn m-t_1">
          {{getTranslate.smi?.detail}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BrokenLine from '@/components/BrokenLine'

export default {
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', '')) || null
    }
  }
}
</script>

<style lang="scss" scoped >
.reverse{
  flex-direction: row-reverse;
}
.desktop-smi {
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__post {
    display: flex;
//    padding-left: 7rem;
    margin-top: 3rem;
  }

  &__right-side {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
//    padding-left: 100px;
  }

  &__image {
    width: 380px;
    object-fit: contain;
    margin: 0 20px;
  }
}

.smi-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}
.detil {
  color: rgb(65,114,187)!important;
}
.arrow {
  width: 1.5rem;
  height: 1.5rem;
}

</style>


export function getUserName (surname = '', name = '', middlename = '') {
  return `${surname || ''} ${name || ''} ${middlename || ''}`
}

export function getPermissions (aclData = null, selfData = null, list = []) {
  return list.reduce((acc, acl) => ({
    ...acc,
    [acl]: aclData && selfData ? Boolean(aclData[acl] & selfData.role) : false
  }), {})
}

export const isMobileDevice = (userAgent) => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent))

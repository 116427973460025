var render = function () {
  var _vm$getTranslate$inst;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "prosthesis-detail page-content"
  }, [_vm.page ? _c('h2', {
    staticClass: "main-title main-title_left prosthesis-detail__title"
  }, [_vm._v(_vm._s(_vm.page.title))]) : _vm._e(), _vm.page ? _c('broken-line', {
    attrs: {
      "class-name": "prosthesis-detail__line desktop__line"
    }
  }) : _vm._e(), _vm.page ? _c('section', {
    staticClass: "prosthesis-detail__content"
  }, [_c('div', {
    staticClass: "prosthesis-detail__top-content"
  }, [_c('slider', {
    attrs: {
      "initialSlide": 0,
      "images": _vm.page.images,
      "class-name": "prosthesis-detail__slider",
      "imageClass": "prosthesis-detail__slide"
    }
  }), _c('div', {
    staticClass: "prosthesis-detail__info-block",
    domProps: {
      "innerHTML": _vm._s(_vm.page.content)
    }
  })], 1), _c('div', {
    staticClass: "prosthesis-detail__table custom-table",
    domProps: {
      "innerHTML": _vm._s(_vm.page.table)
    }
  }), _vm.hasBack ? _c('button', {
    staticClass: "detail-btn m-t_1",
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v(_vm._s((_vm$getTranslate$inst = _vm.getTranslate.instruments) === null || _vm$getTranslate$inst === void 0 ? void 0 : _vm$getTranslate$inst.back))]) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
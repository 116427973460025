<template>
  <div class="editor-comment__wrapper">
    <editor :show="isOpen" :isComment="true" :content="content" @change="onChange" />
    <div v-show="isOpen" class="editor-comment__btn-row-editor">
      <div class="editor-comment__btn-column-editor">
        <button
          :disabled="loading"
          class="forum-button editor-comment__save-btn"
          @click="onClose"
        >
          {{closeBtn}}
        </button>
        <button
          :disabled="!content || loading"
          class="forum-button editor-comment__save-btn"
          :class="{'forum-button_loading': loading}"
          @click="onSave"
        >
          {{submitBtn}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor'

export default {
  components: { Editor },
  name: 'editor-comment',
  props: {
    content: String,
    loading: {
      default: false,
      type: Boolean
    },
    isOpen: Boolean,
    onChange: Function,
    onClose: Function,
    onSave: Function,
    closeBtn: String,
    submitBtn: String
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';

.editor-comment {
  &__btn-column-editor {
    display: flex;
    justify-content: space-between;
  }

  &__btn-row-editor {
    background-color: $white;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  &__wrapper {
   background-color: $white;
   //border: 1px solid #ccc;
   display: flex;
   flex-direction: column;
  }

  &__save-btn {
    align-self: flex-end;
    max-width: 280px;
  }
}
</style>

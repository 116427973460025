<template>
  <div v-if="getTranslate.forum">
    <h3 class="sign-in-modal__title">{{getTranslate.forum.signInTitle}}</h3>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <form class="sign-in-modal__form" @submit.prevent="submitSignIn">
        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
          <input
            class="sign-in-modal__input"
            :class="{'sign-in-modal__input_error': errors[0]}"
            v-model="email"
            type="email"
            :placeholder="getTranslate.forum.email"
          />
          <Error size="small" :error="errors[0]" />
        </ValidationProvider>
        <ValidationProvider name="password" rules="required" v-slot="{ errors }">
          <input
            class="sign-in-modal__input"
            :class="{'sign-in-modal__input_error': errors[0]}"
            v-model="password"
            type="password"
            :placeholder="getTranslate.forum.pass"
          />
          <Error size="small" :error="errors[0]" />
        </ValidationProvider>
        <Error size="small" :error="commonError" />
        <button :class="{'forum-button_loading': submit}" class="forum-button sign-in-modal__submit-btn" type="submit" :disabled="invalid || submit">
          {{getTranslate.forum.signIn}}
        </button>
      </form>
    </ValidationObserver>
    <div class="sign-in-modal__helper-text">
      {{getTranslate.forum.signInForgotTitle}}
      <router-link class="sign-in-modal__redirect-link" :to="Routes.ForumForgotPassword">{{getTranslate.forum.pass}}</router-link>
      ?
    </div>
    <div class="sign-in-modal__helper-text">
      {{getTranslate.forum.signInCreateAccount}}
      <router-link class="sign-in-modal__redirect-link" :to="Routes.ForumSignUp">{{getTranslate.forum.signUp}}</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Error from '@/views/Error'
import AuthApi from '@/api/auth-api'
import { Routes } from '@/shared/constants/routes'

export default {
  data: () => ({
    Routes,
    commonError: '',
    email: '',
    password: '',
    submit: false
  }),
  components: { Error, ValidationProvider, ValidationObserver },
  computed: mapGetters(['getTranslate']),
  watch: {
    'getTranslate.forum': (forum) => {
      extend('email', {
        ...email,
        message: forum.formEmail
      })
      extend('required', {
        ...required,
        message: forum.formRequired
      })
    }
  },
  methods: {
    ...mapActions('auth', ['actionGetSelf', 'actionGetACL']),
    submitSignIn () {
      this.submit = true
      this.commonError = ''
      AuthApi.login({ email: this.email, password: this.password })
        .then(async (response) => {
          const { errors, error, message, two_factor: twoRefactor } = response
          this.submit = false
          if (errors) {
            console.log('errors', errors)
            this.$refs.form.setErrors(errors)
            this.commonError = message
          } else if (error) {
            this.commonError = error
          } if (typeof twoRefactor === 'boolean') {
            await this.actionGetSelf()
            await this.actionGetACL()
            this.$router.push(this.Routes.ForumTopicList)
            return
          }
          throw Error('Authorization error')
        }).catch((response) => {
          this.submit = false
          this.commonError = this.getTranslate.forum.someThingWrong
        })
    }
  },
  name: 'forum-sign-in'
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.sign-in-modal {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  overflow: hidden auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__input {
    border-width: 0;
    border-bottom: 2px solid $primary;
    font-weight: bold;
    height: 40px;
    margin: 10px auto;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: $black;
    }

    &:focus {
      outline: 0;
      border-width: 0;
      border-bottom: 2px solid $primary;
    }

    &_error {
      border-bottom-color: $red;
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 150px;
    margin: 0 auto;
    text-align: left;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__hint {
    font-size: 1rem;
    color: $black;
  }

  &__submit-btn {
    max-width: 150px;
    margin: 25px 0;
  }

  &__helper-text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  &__redirect-link {
    color: $primary;
    transition: color 0.2s;

    &:hover {
      color: rgba($primary, 0.5);
    }
  }

  @include mq-m {
    &__title {
      font-size: 3rem;
    }
  }
}
</style>

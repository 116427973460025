import { BaseApi } from '@/api/base-api'

export class AuthApi extends BaseApi {
  getACL () {
    return this.get('/api/roles')
  }
  login (body) {
    return this.post('/login', { body })
  }
  logout () {
    return this.post('/logout', { json: false })
  }
  signUp (body) {
    return this.post('/register', { body })
  }
  updateUser (id, body) {
    return this.put(`/api/users/update/${id}`, { body })
  }
  currentUser () {
    return this.get('/api/users/current')
  }
  getList () {
    return this.get('/api/users/')
  }
  getUserById (id) {
    return this.get(`/api/users/${id}`)
  }
  deleteUser (id) {
    return this.delete(`/api/users/${id}`)
  }
  forgotPassword (body) {
    return this.post('/forgot-password', { body })
  }
  resetPassword (body) {
    return this.post('/reset-password', { body })
  }
}

export default new AuthApi()

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "products"
  }, [_c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "p-l_standard main-title main-title_left"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1), _c('div', {
    staticClass: "faded-bot m-t_2"
  }, [_c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Additive
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.hipBoneBlack,
      "topLayer": _vm.Images.hipBone
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Instruments
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.scissorsBlack,
      "topLayer": _vm.Images.scissors
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Prostheses
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.headBlack,
      "topLayer": _vm.Images.head
    }
  })], 1)], 1), _c('div', {
    staticClass: "faded-bot"
  }, [_c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Clamps
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.mechanismBlack,
      "topLayer": _vm.Images.mechanism
    }
  })], 1), _c('RouterLink', {
    attrs: {
      "to": _vm.Routes.Coverings
    }
  }, [_c('FadedCap', {
    attrs: {
      "bottomLayer": _vm.Images.kneeBlack,
      "topLayer": _vm.Images.knee
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
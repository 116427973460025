var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-instruments page-content"
  }, [_c('h2', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "class-name": "desktop__line"
    }
  }), _c('div', {
    staticClass: "desktop-instruments__content"
  }, _vm._l(_vm.menuPages, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "preview"
    }, [_c('instrument', {
      attrs: {
        "link": {
          name: _vm.RouterName.CurrentInstrument,
          params: {
            id: item.id
          }
        },
        "instrument": item
      }
    })], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
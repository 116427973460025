var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "proteses page-content"
  }, [_c('h1', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "class-name": "desktop__line"
    }
  }), _vm.menuPages ? _c('div', {
    staticClass: "proteses__content"
  }, _vm._l(_vm.menuPages, function (prosthesisGroup) {
    return _c('div', {
      key: prosthesisGroup.id,
      staticClass: "preview",
      attrs: {
        "id": prosthesisGroup.id
      }
    }, [_c('instrument', {
      attrs: {
        "instrument": prosthesisGroup,
        "link": {
          name: _vm.RouterName.ProsthesisList,
          params: {
            id: prosthesisGroup.id
          }
        }
      }
    })], 1);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
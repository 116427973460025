<template>
  <div class="desktop-news__content page-content">
    <h2 class="main-title main-title_left">{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line className="desktop__line" />
    <div class="desktop-news__columns-wrapper m-t_1" v-if="!!menuPages?.length">
      <div v-for="(newsItem, index) in menuPages" :key="newsItem.id">
        <div  class="desktop-news__post-wrapper m-t_1" :class="{ 'reverse': index % 2 == 0}">
          <div class="desktop-news__right-side">
            <h4 class="main-title main-title_left main-title_remove-offset">{{newsItem.title}}</h4>
            <p class="sub-title sub-title_left overflow-ellipse-6">{{ newsItem.description }}</p>

            <a :href="newsItem.external_url" target="_blank" class="detail-btn m-t_1" v-if="newsItem.external_url && newsItem.is_show_link" :id="newsItem.external_url">
              {{getTranslate.news.detail}}
            </a>
            <router-link :to="{name: RouterName.CurrentNews, params: {id: newsItem.id}}" class="detail-btn m-t_1" v-else-if="newsItem.is_show_link" :id="newsItem.external_url">
              {{getTranslate.news.detail}}
            </router-link>
          </div>
          <div class="desktop-news__left-side">
            <img :src="newsItem.images[0]" v-if="newsItem.images[0]" class="capture m-b_1" />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BrokenLine from '@/components/BrokenLine'
import { RouterName } from '@/shared/constants/routes'

export default {
  data () {
    return { RouterName }
  },
  components: { BrokenLine },
  computed: {
    ...mapGetters(['getTranslate', 'getPageTitle', 'getMenuPages']),
    menuPages () {
      return this.getMenuPages(this.$router.currentRoute.path.replace('/', ''))
    }
  },
  methods: mapActions(['changeLang'])
}
</script>

<style lang="scss" scoped>
.desktop-news__left-side > img{
  max-height: 400px;
  object-fit: contain;
}
.reverse{
  flex-direction: row-reverse;
}
.desktop-news {
  &__content {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    flex: 1;
  }

  &__columns-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__post-wrapper {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
  }

  &__left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    padding-right: 32px;
  }

  &__right-side {
//    padding-left: 55px;
    width: 55%;
  }

  &__underline {
    height: 1rem;
    width: 100%;
  }
}
.vrtcl_img{
  max-height: 405px;
  max-width: 225px;
  margin: 0 auto;
}
.detil {
  color: rgb(65, 114, 187) !important;
}
.arrow {
  width: 1.5rem;
  height: 1.5rem;
}

.bot {
  margin-top: 50rem;
}
</style>

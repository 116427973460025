var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-prosthesis-list page-content"
  }, [_c('h1', {
    staticClass: "main-title main-title_left"
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.params.id)))]), _c('broken-line', {
    attrs: {
      "class-name": "desktop-prosthesis-list__line"
    }
  }), _vm.menuPages ? _c('div', {
    staticClass: "desktop-prosthesis-list__content"
  }, _vm._l(_vm.menuPages, function (prosthesis) {
    return _c('div', {
      key: prosthesis.id,
      staticClass: "desktop-prosthesis-list__preview"
    }, [_c('instrument', {
      attrs: {
        "link": {
          name: _vm.RouterName.ProsthesisDetail,
          params: {
            id: prosthesis.id
          }
        },
        "instrument": prosthesis
      }
    })], 1);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div ref="dropdownRef" class="dropdown" :class="wrapperClass" v-on-clickaway="handleClickAway">
    <button ref="moreIconRef" class="dropdown__button prevent-button-styles" :class="{'dropdown__button--active': isOpened}" @click="handleClickMore">
      <svg height="512" viewBox="0 0 515.555 515.555" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/more.svg'" v-bind:class="'dropdown__more-icon'"><path d="M303.347 18.875c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0M303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0M303.347 405.541c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138c25.166-25.167 65.97-25.167 91.138 0" fill="currentColor"/></svg>
    </button>
    <div class="dropdown__content" :class="{'dropdown__content--opened': isOpened}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [clickaway],
  data () {
    return {
      isOpened: false
    }
  },
  props: {
    wrapperClass: String
  },
  name: 'Dropdown',
  methods: {
    handleClickMore () {
      this.isOpened = !this.isOpened
    },
    handleClickAway (e) {
      if (this.$refs.dropdownRef.contains(e.target)) {
        return null
      }
      this.isOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';

.dropdown {
  position: relative;

  &__button {
    color: $gray-dark;
    cursor: pointer;
    position: relative;
    height: 28px;
    padding: 4px;
    width: 28px;
    transition: color 0.2s ease-in;

    &:hover {
      color: $black;
    }

    &--active {
      color: $black;
    }
  }

  &__more-icon {
    display: block;
    position: relative;
    height: 20px;
    transition: opacity 0.2s;
    width: 20px;
  }

  &__content {
    border: 1px solid $gray-dark;
    background-color: $white;
    display: none;
    padding: 5px 10px;
    position: absolute;
    right: 14px;
    top: 35px;
    width: max-content;

    &--opened {
      display: block;
    }
  }
}
</style>

import head from './head.webp'
import headBlack from './headBlack.webp'
import knee from './knee.webp'
import kneeBlack from './kneeBlack.webp'
import mechanism from './mechanism.webp'
import mechanismBlack from './mechanismBlack.webp'
import scissors from './scissors.webp'
import scissorsBlack from './scissorsBlack.webp'
import hipBone from './hipBone.webp'
import hipBoneBlack from './hipBoneBlack.webp'
import strip from '../stripLeft.jpg'

const Images = {
  head,
  headBlack,
  hipBone,
  hipBoneBlack,
  knee,
  kneeBlack,
  mechanism,
  mechanismBlack,
  scissors,
  scissorsBlack,
  strip
}
export default Images

<template>
  <input
    class="input"
    :class="className"
    :disabled="disabled"
    :name="name"
    :placeholder="placeholder"
    type="text"
    :value="value"
    v-on:input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'ForumInput',
  props: {
    className: String,
    disabled: Boolean,
    name: String,
    placeholder: String,
    value: String
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';

.input {
  border: 2px solid $gray-dark;
  background-color: $gray-lighter;
  color: $black;
  font-size: 14px;
  height: 36px;
  line-height: 16px;
  outline: none;
  padding: 6px;
  transition: background-color 0.2s;
  width: 100%;

  &:not(:disabled):hover {
    background-color: rgba($gray-lighter, 0.5);
  }

  &:focus {
    background-color: $gray-lighter;
    border-color: $primary;
  }

  &::placeholder {
    color: $gray-dark;
  }
}
</style>

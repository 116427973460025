var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desktop-smi__content page-content"
  }, [_c('span', {
    staticClass: "main-title main-title_left",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line', {
    attrs: {
      "className": "desktop__line"
    }
  }), _vm._l(_vm.menuPages, function (_ref, index) {
    var _vm$getTranslate$smi;

    var title = _ref.title,
        description = _ref.description,
        images = _ref.images,
        external_url = _ref.external_url,
        is_show_link = _ref.is_show_link,
        id = _ref.id;
    return _c('div', {
      key: id,
      staticClass: "desktop-smi__post",
      class: {
        reverse: index % 2 == 0
      }
    }, [!!images.length ? _c('img', {
      staticClass: "desktop-smi__image",
      attrs: {
        "src": images[0],
        "alt": title
      }
    }) : _vm._e(), _c('div', {
      staticClass: "desktop-smi__right-side"
    }, [_c('h4', {
      staticClass: "main-title main-title_left main-title_remove-offset"
    }, [_vm._v(_vm._s(title))]), _c('p', {
      staticClass: "sub-title sub-title_left m-b_1 max-w_75"
    }, [_vm._v(_vm._s(description))]), external_url && is_show_link ? _c('a', {
      staticClass: "detail-btn m-t_1",
      attrs: {
        "target": "_blank",
        "href": external_url
      }
    }, [_vm._v(" " + _vm._s((_vm$getTranslate$smi = _vm.getTranslate.smi) === null || _vm$getTranslate$smi === void 0 ? void 0 : _vm$getTranslate$smi.detail) + " ")]) : _vm._e()])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-modal",
    class: {
      'spinner-modal_loading': _vm.loading
    }
  }, [_c('spinner-loader', {
    attrs: {
      "loading": _vm.loading,
      "className": "spinner-modal__loader"
    }
  }), _c('div', {
    staticClass: "spinner-modal__content"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
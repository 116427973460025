<template>
  <div class='coverings'>
    <div class="mobile-page-title" v-if="getPageTitle($router.currentRoute.path)">
      <span class='main-title main-title_left p-l_standard' :id="getPageTitle($router.currentRoute.path)" :title="getPageTitle($router.currentRoute.path)" >{{getPageTitle($router.currentRoute.path)}}</span>
      <broken-line />
    </div>

    <div class="coverings__content p-l_standard p-r_standard" v-if="!!getTranslate.coverings">
      <span class='main-title main-title_left' :id="getTranslate.coverings.bioMainTitle" >{{getTranslate.coverings.bioMainTitle}}</span>
      <span class='sub-title sub-title_left sub-title_helvetica-italic' :id="getTranslate.coverings.bioSubtitle" >{{getTranslate.coverings.bioSubtitle}}</span>
      <span class='simple m-t_1 t-a_l' :id="getTranslate.coverings.creation" >{{getTranslate.coverings.creation}}</span>
      <span class='simple t-a_l' :id="getTranslate.coverings.creation2" >{{getTranslate.coverings.creation2}}</span>
      <img :src='Images.nano' class='capture'/>
      <span class='sub-title sub-title_helvetica-italic' :id="getTranslate.coverings.nanoSub" >{{getTranslate.coverings.nanoSub}}</span>
      <img :src='Images.micro' class='capture'/>
      <span class='sub-title sub-title_helvetica-italic' :id="getTranslate.coverings.microSub" >{{getTranslate.coverings.microSub}}</span>
      <img :src='Images.chese' class='capture'/>
      <span class='sub-title sub-title_helvetica-italic' :id="getTranslate.coverings.cheseSub" >{{getTranslate.coverings.cheseSub}}</span>
       <img :src='Images.clouds' class='capture'/>
      <span class='sub-title sub-title_helvetica-italic' :id="getTranslate.coverings.cloudSub" >{{getTranslate.coverings.cloudSub}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Coverings'
import BrokenLine from '@/components/BrokenLine'

export default {
  data () {
    return {
      Images
    }
  },
  components: { BrokenLine },
  computed: mapGetters(['getTranslate', 'getPageTitle', 'getPageId'])
}
</script>

<style lang="scss" scoped >
.t-a_l{
  text-align: left;
}
.coverings {
  display: flex;
  flex-direction: column;

  &__content {

  }
}
</style>

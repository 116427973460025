<template>
  <div class='carousel__wrapper' :class="className">
    <carousel
      ref="carouselRef"
      :per-page="1"
      :navigate-to="currentSlide"
      :mouse-drag="mouseDrag"
      pagination-active-color="#4172bb"
      @page-change="changePage"
    >
      <slide v-for="(item, index) in images"  :key='index'>
        <img alt="" :src="item" :class="imageClass" class="carousel__image-slide"/>
      </slide>
    </carousel>
    <button v-if="images.length > 1" class="carousel__arrow carousel__arrow_prev" @click="handlePrevClick">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.995 511.995" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/arrow.svg'" v-bind:class="'carousel__arrow-btn'"><path d="M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z" fill="currentColor"/></svg>
    </button>
    <button v-if="images.length > 1" class="carousel__arrow carousel__arrow_next" @click="handleNextClick">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.995 511.995" v-bind:svg-inline="''" v-bind:src="'@/shared/Images/Forum/arrow.svg'" v-bind:class="'carousel__arrow-btn'"><path d="M381.039 248.62L146.373 3.287C142.29-.942 135.54-1.13 131.29 2.954c-4.25 4.073-4.396 10.823-.333 15.083L358.56 255.995 130.956 493.954c-4.063 4.26-3.917 11.01.333 15.083a10.63 10.63 0 007.375 2.958 10.65 10.65 0 007.708-3.292L381.039 263.37c3.938-4.125 3.938-10.625 0-14.75z" fill="currentColor"/></svg>
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCarousel, { Carousel, Slide } from 'vue-carousel'

Vue.use(VueCarousel)

export default {
  data () {
    return {
      currentIndex: 0,
      nextSlide: null,
      prevSlide: null
    }
  },
  name: 'Slider',
  components: {
    Carousel,
    Slide
  },
  props: {
    imageClass: String,
    className: String,
    initialSlide: Number,
    mouseDrag: Boolean,
    images: Array,
    description: String
  },
  updated () {
    if (typeof this.initialSlide === 'number' && this.$refs.carouselRef) {
      this.$refs.carouselRef.goToPage(this.initialSlide, false)
    }
  },
  computed: {
    currentSlide () {
      if (typeof this.currentIndex === 'number') {
        return this.currentIndex
      }
      return this.initialSlide
    }
  },
  methods: {
    changePage (newIndex) {
      this.currentIndex = newIndex
    },
    handleNextClick () {
      this.currentIndex = this.$refs.carouselRef.canAdvanceForward ? this.$refs.carouselRef.getNextPage() : 0
    },
    handlePrevClick () {
      this.currentIndex = this.$refs.carouselRef.canAdvanceBackward ? this.$refs.carouselRef.getPreviousPage() : this.$refs.carouselRef.slideCount - 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/media.scss';
@import '@/shared/style/colors.scss';

.carousel {
  &__wrapper {
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @include mq-s {
      max-width: 400px;
    }

    @include mq-m {
      max-width: 700px;
    }

    @include mq-l {
      max-width: 700px;
    };

    @include mq-xl {
      max-width: 700px;
    };
  }

  &__image-slide {
    /*width: 286px;*/
    width: 100%;

    @include mq-s {
      width: 300px;
    }

    @include mq-m {
      width: 500px;
    }

    @include mq-l {
      width: 500px;
    };

    @include mq-xl {
      width: 500px;
    };
  }

  &__arrow-btn {
    height: 60px;
    width: 60px;
  }

  &__arrow {
    border-width: 0;
    border-radius: 4px;
    background-color: transparent;
    bottom: 0;
    color: $primary;
    cursor: pointer;
    display: none;
    outline: none;
    position: absolute;
    top: 0;
    transition: background-color 0.2s;
    width: 90px;

    @media (hover: hover) {
      &:hover {
        background-color: rgba($black, 0.1);
        color: $primary;
      }
    }

    @include mq-s {
      display: block;
    }

    &_prev {
      left: -90px;

      .carousel {
        &__arrow-btn {
          transform: rotate(180deg);
        }
      }
    }

    &_next {
      right: -90px;
    }
  }
}
</style>
<style lang="scss">
.VueCarousel-dot {
  margin-top: 10px !important;

  &:focus {
     outline: none !important;
   }

  &-container {
     margin-top: 0 !important;
   }
}

</style>

import process from 'process'
import languages, { langKey } from '@/shared/constants/i18n'

export class BaseApi {
  constructor (domain = process.env.VUE_APP_BASE_URL) {
    this.domain = domain
  }

  getUrl (pathname, queryParams = null) {
    const query = queryParams ? new URLSearchParams(queryParams).toString() : null
    const baseUrl = this.domain + pathname

    return query ? `${baseUrl}?${query}` : baseUrl
  }

  getHeaders (headers) {
    const lang = localStorage.getItem(langKey)

    return new Headers({
      ...headers,
      'Accept-Language': lang ? `${lang}` : `${languages.russian}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
  }

  getBody (body) {
    return JSON.stringify(body)
  }

  getOptions (
    method,
    body,
    requestOptions = {}
  ) {
    const options = {
      method,
      credentials: 'include',
      ...requestOptions
    }

    options.headers = this.getHeaders(requestOptions.headers)

    if (body) {
      options.body = this.getBody(body)
    }

    return options
  }

  async request (
    method,
    pathname,
    { body, extraOptions, options, query, json = true } = {}
  ) {
    const path = this.getUrl(pathname, query)
    const requestOptions = this.getOptions(method, body, options, extraOptions)

    try {
      const response = await fetch(path, requestOptions)

      if (!response.ok) {
        throw response
      }

      if (json) {
        return response.json()
      }

      return response
    } catch (response) {
      return response.json()
    }
  }

  get (pathname, requestParams) {
    return this.request('GET', pathname, requestParams)
  }

  post (pathname, requestParams) {
    return this.request('POST', pathname, requestParams)
  }

  put (pathname, requestParams) {
    return this.request('PUT', pathname, requestParams)
  }

  delete (pathname, requestParams) {
    return this.request('DELETE', pathname, requestParams)
  }
}

<template>
  <div>
    <h3 class="sign-up-modal__title">{{getTranslate.forum.signUp}}</h3>
    <div v-if="!isSuccess">
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <form class="sign-up-modal__form" @submit.prevent="handleSubmitSignUp">
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
            <input
              class="sign-up-modal__input"
              :class="{'sign-up-modal__input_error': errors[0]}"
              :disabled="submitting"
              v-model="email"
              name="email"
              type="text"
              :placeholder="getTranslate.forum.email"
            />
            <Error size="small" :error="errors[0]" />
          </ValidationProvider>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <input
              class="sign-up-modal__input"
              :class="{'sign-up-modal__input_error': errors[0]}"
              :disabled="submitting"
              v-model="name"
              name="name"
              type="text"
              :placeholder="getTranslate.forum.name"
            />
            <Error size="small" :error="errors[0]" />
          </ValidationProvider>
          <ValidationProvider name="password" rules="required" v-slot="{ errors }">
            <input
              class="sign-up-modal__input"
              :class="{'sign-up-modal__input_error': errors[0]}"
              :disabled="submitting"
              v-model="password"
              type="password"
              :placeholder="getTranslate.forum.pass"
            />
            <Error size="small" :error="errors[0]" />
          </ValidationProvider>
          <ValidationProvider name="passwordConfirmation" rules="required|password:@password" v-slot="{ errors }">
            <input
              class="sign-up-modal__input"
              :class="{'sign-up-modal__input_error': errors[0]}"
              :disabled="submitting"
              v-model="passwordConfirmation"
              type="password"
              :placeholder="getTranslate.forum.passwordConfirmation"
            />
            <Error size="small" :error="errors[0]" />
          </ValidationProvider>
          <Error size="small" :error="commonError" />
          <button :class="{'forum-button_loading': submitting}" class="forum-button sign-up-modal__submit-btn" type="submit" :disabled="invalid || submitting">
            {{getTranslate.forum.signUp}}
          </button>
        </form>
      </ValidationObserver>
      <div class="sign-up-modal__helper-text">
        {{getTranslate.forum.signUpHaveAccount}}
        <router-link :to="Routes.ForumSignIn" class="sign-up-modal__redirect-link">{{getTranslate.forum.signIn}}</router-link>
      </div>
    </div>
    <div v-if="isSuccess" class="sign-up-modal__success-modal">
      <p>
        {{getTranslate.forum.signUpSuccessSentOnEmail}} <b>{{email}}</b>. {{getTranslate.forum.signUpSuccessCheckEmail}}
      </p>
      <p class="m-t_1">
        <router-link class="forum-button p-l_1 p-r_1" :to="Routes.ForumSignIn">{{getTranslate.forum.signIn}}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Error from '@/views/Error'
import AuthApi from '@/api/auth-api'
import { Validator } from 'vee-validate'
import { Routes } from '@/shared/constants/routes'
import { langKey } from '@/shared/constants/i18n'


(async () => {
  const lang = localStorage.getItem(langKey);
  const localization = await import(`@/shared/locale/${lang}/index`)

  extend('email', email)
  extend('required', required)

  extend('password', {
    params: ['target'],
    validate (value, { target }) {
      return value === target
    },
    message: localization.forum.formPasswordMatch
  })
})()

export default {
  data: () => ({
    Routes,
    commonError: '',
    submitting: false,
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    isSuccess: false
  }),
  components: { Error, ValidationObserver, ValidationProvider },
  computed: mapGetters(['getTranslate']),
  watch: {
    'getTranslate.forum': (forum) => {
      extend('email', {
        ...email,
        message: forum.formEmail
      })
      extend('required', {
        ...required,
        message: forum.formRequired
      })
      extend('password', {
        params: ['target'],
        validate (value, { target }) {
          return value === target
        },
        message: forum.formPasswordMatch
      })
    }
  },
  methods: {
    ...mapActions(['changeLang']),
    handleSubmitSignUp () {
      this.submitting = true
      AuthApi.signUp({
        name: this.name,
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      }).then((response) => {
        const { errors, message } = response

        if (errors) {
          this.$refs.form.setErrors(errors)
          this.commonError = message
        } else {
          this.isSuccess = true
          //удаляем авторизацию, так как из-за этого возникает ошибка при верификации почты
          document.cookie = 'sintel_session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        this.submitting = false
      })
        .catch((e) => {
          this.submitting = false
          this.commonError = this.getTranslate.forum.someThingWrong
          this.isSuccess = false
        })
    }
  },
  name: 'forum-sign-up'
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/colors.scss';
@import '@/shared/style/media.scss';

.sign-up-modal {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  overflow: hidden auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__input {
    border-width: 0;
    border-bottom: 2px solid $primary;
    font-weight: bold;
    height: 40px;
    margin: 10px auto;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: $black;
    }

    &:focus {
      outline: 0;
      border-width: 0;
      border-bottom: 2px solid $primary;
    }

    &_error {
      border-bottom-color: $red;
    }
  }

  &__helper-text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  &__redirect-link {
    color: $primary;
    transition: color 0.2s;

    &:hover {
      color: rgba($primary, 0.5);
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 150px;
    margin: 0 auto;
    text-align: left;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__hint {
    font-size: 1rem;
    color: $black;
  }

  &__submit-btn {
    max-width: 150px;
    margin: 25px 0;
  }

  &__success-modal {
    margin-top: 1.5rem;
  }

  @include mq-m {
    &__title {
      font-size: 3rem;
    }
  }
}
</style>

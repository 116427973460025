<template>
  <div class='desktop-products__content page-content'>
    <h2 class='main-title main-title_left' :id="getPageTitle($router.currentRoute.path)">{{getPageTitle($router.currentRoute.path)}}</h2>
    <broken-line className="desktop__line" />

    <div class='desktop-products__inner-content'>
      <div class='faded-top'>
        <RouterLink :to="Routes.Additive">
          <FadedCap :bottomLayer='Images.hipBoneBlack' :topLayer='Images.hipBone'/>
        </RouterLink>
        <RouterLink :to="Routes.Instruments">
          <FadedCap :bottomLayer='Images.scissorsBlack' :topLayer='Images.scissors'/>
        </RouterLink>
        <RouterLink :to="Routes.Prostheses">
          <FadedCap :bottomLayer='Images.headBlack' :topLayer='Images.head'/>
        </RouterLink>
        <RouterLink :to="Routes.Clamps">
          <FadedCap :bottomLayer='Images.kneeBlack' :topLayer='Images.knee'/>
        </RouterLink>
        <RouterLink :to="Routes.Coverings">
          <FadedCap :bottomLayer='Images.mechanismBlack' :topLayer='Images.mechanism'/>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import FadedCap from '@/components/FadedCap'
import { mapGetters } from 'vuex'
import Images from '@/shared/Images/Products'
import BrokenLine from '@/components/BrokenLine'
import { Routes } from '@/shared/constants/routes'

export default {
  components: { BrokenLine, FadedCap },
  data () {
    return {
      Images,
      Routes
    }
  },
  computed: {
    ...mapGetters(['getPageTitle'])
  }
}
</script>

<style lang="scss" scoped >
.desktop-products {
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__inner-content {
    margin-top: 3rem;
  }
}
</style>

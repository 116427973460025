var render = function () {
  var _vm$getTranslate$comm, _vm$getTranslate$comm2, _vm$getTranslate$comm3, _vm$getTranslate$comm4, _vm$getTranslate$comm5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('address', {
    staticClass: "footer__content"
  }, [_c('a', {
    staticClass: "main-title",
    attrs: {
      "href": 'mailto:' + ((_vm$getTranslate$comm = _vm.getTranslate.common) === null || _vm$getTranslate$comm === void 0 ? void 0 : _vm$getTranslate$comm.info)
    }
  }, [_vm._v(_vm._s((_vm$getTranslate$comm2 = _vm.getTranslate.common) === null || _vm$getTranslate$comm2 === void 0 ? void 0 : _vm$getTranslate$comm2.info))]), _c('span', {
    staticClass: "main-title"
  }, [_vm._v(_vm._s((_vm$getTranslate$comm3 = _vm.getTranslate.common) === null || _vm$getTranslate$comm3 === void 0 ? void 0 : _vm$getTranslate$comm3.adress))]), _c('broken-line', {
    attrs: {
      "rtl": ""
    }
  }), _c('a', {
    staticClass: "footer__fax main-title",
    attrs: {
      "href": 'tel:' + ((_vm$getTranslate$comm4 = _vm.getTranslate.common) === null || _vm$getTranslate$comm4 === void 0 ? void 0 : _vm$getTranslate$comm4.fax)
    }
  }, [_vm._v(_vm._s((_vm$getTranslate$comm5 = _vm.getTranslate.common) === null || _vm$getTranslate$comm5 === void 0 ? void 0 : _vm$getTranslate$comm5.fax))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
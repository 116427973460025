<template>
  <div class="spinner-container" :style="{'min-height': height}" :class="{'spinner-container_loading': loading}">
    <spinner-loader :loading="loading" className="spinner-container__loader"></spinner-loader>
    <div class="spinner-container__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader'

export default {
  components: { SpinnerLoader },
  name: 'spinner-container',
  props: ['loading', 'height']
}
</script>

<style lang="scss">
.spinner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &_loading {
    .spinner-container {
      &__loader {
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
      }

      &__content {
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }

  &__loader {
    z-index: 10;
  }
}
</style>

<template>
  <header class="header__wrapper">
    <div class='header'>
      <div class="top">
        <router-link to="/" class="logo">
          <img :src='Images.logo' class="header__logo-image" alt="capture" />
        </router-link>
        <button class="header__profile-btn" @click="handleLogin">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" v-bind:svg-inline="''" v-if="show" v-bind:src="'@/shared/Images/Common/profile.svg'" v-bind:class="'header__profile-svg'"><path d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0025 50a24.881 24.881 0 0016.069-5.861.126.126 0 01.003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 001.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0" fill="currentColor"/></svg>
          {{getSelf ? getSelf.name : getTranslate.forum?.login}}
        </button>
        <div v-on:click="show = !show">
          <spinner-loader size="sm" :loading="isLoading(getMenuStatus)" />
          <img v-if="!show && !isLoading(getMenuStatus)" :src='Images.burger' class="header__hamburger" />
          <svg height="512" viewBox="0 0 386.667 386.667" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="show && !isLoading(getMenuStatus)" v-bind:src="'@/shared/Images/Forum/cross.svg'" v-bind:class="'header__header-icon'"><path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" fill="currentColor"/></svg>
        </div>
      </div>
      <div class="bottom">
        <div class="search">
          <form class="search-form" @submit.prevent="onSearchRedirect">
            <input class="search-input" :placeholder="getTranslate.common?.placeholder" v-model="toFind"/>
          </form>
        </div>
        <div class="languages">
          <img v-for='flag in langs' :key='flag'  :src="Images.languages[flag]" v-on:click='changeLanguage(flag)' class='languages-flag' :class="{'languages-flag-active': flag === getLang}" />
        </div>
      </div>
    </div>
    <nav id="nav" class="nav" :class="{nav__opened: show}">
      <div class="nav__content">
        <div v-for="{name, url, id} in mobileMenuItems" :key="id" v-on:click="show = !show" class='header__nav-link'>
          <router-link :to="url" class="link" :exact="true" exact-active-class="active-link">
            {{name}}
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import commonImages from '@/shared/Images/Common'
import languages from '@/shared/constants/i18n'
import { isLoading } from '@/shared/helpers/menu'
import SpinnerLoader from './SpinnerLoader.vue'
import { Routes } from '@/shared/constants/routes'

export default {
  components: { SpinnerLoader },
  data: () => ({
    Routes,
    show: false,
    Images: commonImages,
    langs: languages,
    toFind: ''
  }),
  computed: {
    ...mapGetters(['getTranslate', 'getLang', 'mobileMenuItems', 'getMenuStatus']),
    ...mapGetters('auth', ['getSelf'])
  },
  methods: {
    isLoading,
    ...mapActions(['changeLang', 'search']),
    handleLogin () {
      this.$router.push(this.Routes.ForumSignIn)
    },
    onSearchRedirect () {
      this.$router.push('/search?q=' + this.toFind)
    },
    async changeLanguage (language) {
      await this.changeLang(language)
      window.location.reload()
    }
  },
  watch: {
    toFind: function (newToFind) {
      this.toFind = newToFind
      return this.toFind
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/shared/style/colors.scss';

.header {
  background: $white;
  left: 0;
  padding: 0 0.8rem 8px;
  position: fixed;
  top: 0;
  touch-action: none;
  width: 100%;

  &__wrapper {
    background: $white;
    position: fixed;
    height: 145px;
    width: 100%;
    z-index: 100;
  }

  &__nav-link {
    display: inline-block;
    text-align: left;
    margin: 5px 4px 4px 0;

    .link {
      padding: 4px 4px 4px 0;
    }
  }

  &__header-icon {
    color: $primary;
    display: block;
    height: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 8px 8px 10px;
    width: 40px;
  }

  &__hamburger {
    height: 40px;
    padding: 4px;
    width: 40px;
  }

  &__logo-image {
    height: 88px;
    width: 150px;
  }

  &__profile-btn {
    align-items: center;
    background-color: transparent;
    border-width: 0;
    color: $primary;
    cursor: pointer;
    display: flex;
    outline: none;
    max-width: 110px;
    width: 100%;
  }

  &__profile-svg {
    height: 24px;
    margin-right: 16px;
    min-width: 24px;
    width: 24px;
  }
}

.logo {
  margin-left: -18px;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 0;
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search {
  width: 60%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &-icon {
    height: 16px;
    padding: 0 ;
    left: 6px;
    object-fit: contain;
    position: absolute;
    width: 16px;
    z-index: 2;
  }

  &-form {
    display: inline;
    position: relative;
  }

  &-button {
    height: 2rem;
    position: absolute;
    z-index: 20;
    align-items: center;
    display: flex;
  }

  &-input {
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: none;
    height: 2rem;
    font-size: 14px;
    line-height: 16px;
    margin-right: 0.5rem;
    padding-left: 10px;
    position: relative;
    width: 100%;
    z-index: 1;

    &:focus {
      border-color: $primary;
      outline: none;
    }
  }
}
.languages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;

  &-flag {
    border-radius: 50%;
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    &-active {
      border: 1px solid $gray;
    }
  }
}

.nav{
  background-color: $white;
  height: calc(100vh - 145px);
  overflow: hidden;
  padding-bottom: 2rem;
  position: fixed;
  top: 145px;
  transform: translateX(-100%);
  transition: transform 0.3s;
  width: 100%;
  z-index: 100;

  &__opened {
    transform: translateX(0);
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 0 0.8rem 3rem;
    touch-action: pan-y;
    overflow-scrolling: touch;
  }

  &-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.3rem;
    text-align: start;
    width: 40%;
  }
  .link {
    font-family: "Din";
    font-size: 1rem;
  }
  .active-link {
    font-family: "Din";
    font-size: 1rem;
    color: rgb(65,114,187)!important;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>

<template>
  <div class="discussion-form" v-if="getTranslate?.forum">
    <breadcrumbs :itemList="breadcrumbs" wrapperClass="topic-list__breadcrumbs" />
    <div class="discussion-form__content">
      <form class="discussion-form__form" @submit.prevent="handleSubmit">
        <forum-input
          class="discussion-form__input"
          :placeholder="getTranslate.forum.topicName"
          @input="changeTitle"
          :value="title"
        />
        <forum-input
          class="discussion-form__input"
          :placeholder="getTranslate.forum.topicDescription"
          @input="changeDescription"
          :value="description"
        />
        <button :class="{'forum-button_loading': loading}" :disabled="!title || !description || loading || !permissions" class="forum-button discussion-form__button" type="submit">{{buttonText}}</button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ForumInput from '@/components/ForumInput'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  props: {
    breadcrumbs: {
      default: () => [],
      type: Array
    },
    title: {
      default: '',
      type: String
    },
    description: {
      default: '',
      type: String
    },
    buttonText: {
      default: 'Create discussion',
      type: String
    },
    loading: {
      default: false,
      type: Boolean
    },
    permissions: {
      default: false,
      type: Boolean
    }
  },
  name: 'discussion-form',
  components: { Breadcrumbs, ForumInput },
  computed: mapGetters(['getTranslate']),
  methods: {
    changeTitle (value) {
      this.$emit('change-title', value)
    },
    changeDescription (value) {
      this.$emit('change-description', value)
    },
    handleSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/style/media.scss';

.discussion-form {
  font-family: 'Open sans', sans-serif;
  height: 100%;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;

  @include mq-m {
    width: calc(846px + 16px + 16px);
  }

  @include mq-l {
    width: calc(970px + 16px + 16px);
  }

  @include mq-xl {
    width: calc(1280px + 16px + 16px);
  }

  @include mq-xxl {
    width: calc(1440px + 16px + 16px);
  }

  &__content {
    margin-top: 24px;
  }

  &__input {
    margin-top: 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__button {
    margin-top: 16px;
    width: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>

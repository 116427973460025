import plecho from './plecho.webp'
import additive from './additiveBones1.webp'
import balls from './balls.webp'
import fracture from './fracture.webp'
import glitter from './glitter.webp'
import head from './head.webp'
import headBlack from './headBlack.webp'
import hipBone from './hipBone.jpg'
import hipBoneBlack from './hipBoneBlack.webp'
import intraFixator from './intraFixator.jpg'
import knee from './knee.webp'
import kneeBlack from './kneeBlack.webp'
import mechanism from './mechanism.webp'
import mechanismBlack from './mechanismBlack.webp'
import scissors from './scissors.webp'
import scissorsBlack from './scissorsBlack.webp'
import tail from './tail.webp'
import whistle from './whistle.webp'
import whiteHead from './whiteHead.webp'
import wireCutters from './wireCutters.webp'
import wave from '../wave.svg'
import strip from '../stripLeft.jpg'

export const homeImages = {
  plecho,
  additive,
  balls,
  fracture,
  glitter,
  head,
  hipBone,
  hipBoneBlack,
  intraFixator,
  headBlack,
  kneeBlack,
  knee,
  mechanism,
  mechanismBlack,
  scissors,
  scissorsBlack,
  tail,
  whistle,
  whiteHead,
  wireCutters,
  wave,
  strip
}

import { render, staticRenderFns } from "./ProsthesisDetail.vue?vue&type=template&id=69a0add9&scoped=true&"
import script from "./ProsthesisDetail.vue?vue&type=script&lang=js&"
export * from "./ProsthesisDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProsthesisDetail.vue?vue&type=style&index=0&id=69a0add9&scoped=true&lang=scss&"
import style1 from "./ProsthesisDetail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a0add9",
  null
  
)

export default component.exports
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "instruments"
  }, [_vm.getPageTitle(_vm.$router.currentRoute.path) ? _c('div', {
    staticClass: "mobile-page-title"
  }, [_c('h2', {
    staticClass: "main-title main-title_left p-l_standard",
    attrs: {
      "id": _vm.getPageTitle(_vm.$router.currentRoute.path)
    }
  }, [_vm._v(_vm._s(_vm.getPageTitle(_vm.$router.currentRoute.path)))]), _c('broken-line')], 1) : _vm._e(), _c('div', {
    staticClass: "instruments__content"
  }, _vm._l(_vm.menuPages, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "instruments__preview"
    }, [_c('instrument', {
      attrs: {
        "instrument": item,
        "link": {
          name: _vm.RouterName.CurrentInstrument,
          params: {
            id: item.id
          }
        }
      }
    })], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }